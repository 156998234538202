import * as React from 'react';
import './pitScouting.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {Navigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';

dayjs.extend(utc);
const db = getDatabase();

export default class PitScouting extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      teamNumber: window.location.pathname.substring(13),
      event: sessionStorage.getItem('EVENTNAME'),
      seasonsDriven: '',
      currentSeasonEvents: '',
      robotDriveBase: '',
      driveMotorType: '',
      robotTopSpeed: '',
      robotLength: '',
      robotWidth: '',
      robotHeight: '',
      robotWeight: '',
      robotWebcam: '',
      autoLeavesStartZone: '',
      autoScorePreload: '',
      autoPickupPiecesTotal: '',
      autoPickupClosePieces: '',
      autoPickupPiecesFar: '',
      autoAmpScore: '',
      autoSpeakerScore: '',
      teleopScoreAmp: '',
      teleopScoreSpeaker: '',
      teleopSpeakerScoreDistance: '',
      teleopAutoScoreSpeaker: '',
      teleopFloorPickup: '',
      teleopHumanPlayerPickup: '',
      endgameClimb: '',
      endgameTrapScore: '',
      endgameClimbHarmony: '',
      endgameMultipleTrapScore: '',
      jankRatingEndgameClimb: '',
      jankRatingEndgameTrapScore: '',
      jankRatingSpeakerScoring: '',
      jankRatingAmpScoring: '',
      jankRatingIntakeFloor: '',
      jankRatingIntakeStation: '',
      jankRatingTakeAHit: '',
      jankRatingBatteryMount: '',
      scoutingPriority: '',
      finalNotes: '',
      returnToScouting: false,
      openFinalNotes: false,
      allowFinalSubmit: false
    }

    this.handleEventSelect = this.handleEventSelect.bind(this);
    this.handlePitScout = this.handlePitScout.bind(this);
    this.handleOpenHistoricalScout = this.handleOpenHistoricalScout.bind(this);

  }

  handleOpenHistoricalScout() {
    this.setState({openFinalNotes: !this.state.openFinalNotes, allowFinalSubmit: true});
  }

  handleEventSelect(event) {
      event.preventDefault();
      const { name, value } = event.target;
      this.setState({[name]: value});
      
  }

  handlePitScout(event) {
    event.preventDefault();
    const pitData = {
      seasonsDriven: this.state.seasonsDriven != '' ? this.state.seasonsDriven : '',
      currentSeasonEvents: this.state.currentSeasonEvents != '' ? this.state.currentSeasonEvents : '',
      robotDriveBase: this.state.robotDriveBase != '' ? this.state.robotDriveBase : '',
      driveMotorType: this.state.driveMotorType != '' ? this.state.driveMotorType : '',
      robotTopSpeed: this.state.robotTopSpeed != '' ? this.state.robotTopSpeed : '',
      robotLength: this.state.robotLength != '' ? this.state.robotLength : '',
      robotWidth: this.state.robotWidth != '' ? this.state.robotWidth : '',
      robotHeight: this.state.robotHeight != '' ? this.state.robotHeight : '',
      robotWeight: this.state.robotWeight != '' ? this.state.robotWeight : '',
      robotWebcam: this.state.robotWebcam != '' ? this.state.robotWebcam : '',
      autoLeavesStartZone: this.state.autoLeavesStartZone != '' ? this.state.autoLeavesStartZone : false,
      autoScorePreload: this.state.autoScorePreload != '' ? this.state.autoScorePreload : '',
      autoPickupPiecesTotal: this.state.autoPickupPiecesTotal != '' ? this.state.autoPickupPiecesTotal : '',
      autoPickupClosePieces: this.state.autoPickupClosePieces != '' ? this.state.autoPickupClosePieces : '',
      autoPickupPiecesFar: this.state.autoPickupPiecesFar != '' ? this.state.autoPickupPiecesFar : '',
      autoAmpScore: this.state.autoAmpScore != '' ? this.state.autoAmpScore : '',
      autoSpeakerScore: this.state.autoSpeakerScore != '' ? this.state.autoSpeakerScore : '',
      teleopScoreAmp: this.state.teleopScoreAmp != '' ? this.state.teleopScoreAmp : false,
      teleopScoreSpeaker: this.state.teleopScoreSpeaker != '' ? this.state.teleopScoreSpeaker : false,
      teleopSpeakerScoreDistance: this.state.teleopSpeakerScoreDistance != '' ? this.state.teleopSpeakerScoreDistance : '',
      teleopAutoScoreSpeaker: this.state.teleopAutoScoreSpeaker != '' ? this.state.teleopAutoScoreSpeaker : false,
      teleopFloorPickup: this.state.teleopFloorPickup != '' ? this.state.teleopFloorPickup : false,
      teleopHumanPlayerPickup: this.state.teleopHumanPlayerPickup != '' ? this.state.teleopHumanPlayerPickup : false,
      endgameClimb: this.state.endgameClimb != '' ? this.state.endgameClimb : false,
      endgameTrapScore: this.state.endgameTrapScore != '' ? this.state.endgameTrapScore : false,
      endgameClimbHarmony: this.state.endgameClimbHarmony != '' ? this.state.endgameClimbHarmony : false,
      endgameMultipleTrapScore: this.state.endgameMultipleTrapScore != '' ? this.state.endgameMultipleTrapScore : false,
      jankRatingEndgameClimb: this.state.jankRatingEndgameClimb != '' ? this.state.jankRatingEndgameClimb : '',
      jankRatingEndgameTrapScore: this.state.jankRatingEndgameTrapScore != '' ? this.state.jankRatingEndgameTrapScore : '',
      jankRatingSpeakerScoring: this.state.jankRatingSpeakerScoring != '' ? this.state.jankRatingSpeakerScoring : '',
      jankRatingAmpScoring: this.state.jankRatingAmpScoring != '' ? this.state.jankRatingAmpScoring : '',
      jankRatingIntakeFloor: this.state.jankRatingIntakeFloor != '' ? this.state.jankRatingIntakeFloor : '',
      jankRatingIntakeStation: this.state.jankRatingIntakeStation != '' ? this.state.jankRatingIntakeStation : '',
      jankRatingTakeAHit: this.state.jankRatingTakeAHit != '' ? this.state.jankRatingTakeAHit : '',
      jankRatingBatteryMount: this.state.jankRatingBatteryMount != '' ? this.state.jankRatingBatteryMount : '',
    }
    axios.post(getAPI() + `/scouting/scoutingUpdatePitScoutingData` , {notes: encodeURIComponent(this.state.finalNotes),teamNumber: encodeURIComponent(this.state.teamNumber), event: encodeURIComponent(this.state.event), scoutPriority: encodeURIComponent(this.state.scoutingPriority != '' ? this.state.scoutingPriority : 3), pitData: encodeURIComponent(JSON.stringify(pitData))})
    .then(res => {
      const data = res;
      if(data.status != 200) {
        //this.setState({ loginFailedToSubmit: true });
      }
      else {
        //this.setState({ submitted: false });
        console.log('Here');
        this.setState({returnToScouting: true});
      }
    });      
  }
  
componentDidMount() {
  onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME').replace(/[^a-zA-Z ]/g, "")}/teams/${this.state.teamNumber}`), (snapshot) => {
    if(snapshot.val().pitData != null && snapshot.val().pitData != 'undefined') {
      const data = JSON.parse(snapshot.val().pitData); 
      this.setState({scoutingPriority: snapshot.val().scoutPriority != null ? snapshot.val().scoutPriority : ''});
      this.setState({finalNotes: snapshot.val().pitScoutNotes != null ? snapshot.val().pitScoutNotes : ''});
      this.setState({seasonsDriven: data.seasonsDriven != null ? data.seasonsDriven : ''});
      this.setState({currentSeasonEvents: data.currentSeasonEvents != null ? data.currentSeasonEvents : ''});
      this.setState({robotDriveBase: data.robotDriveBase != null ? data.robotDriveBase : ''});
      this.setState({driveMotorType: data.driveMotorType != null ? data.driveMotorType : ''});
      this.setState({robotTopSpeed: data.robotTopSpeed != null ? data.robotTopSpeed : ''});
      this.setState({robotLength: data.robotLength != null ? data.robotLength : ''});
      this.setState({robotWidth: data.robotWidth != null ? data.robotWidth : ''});
      this.setState({robotHeight: data.robotHeight != null ? data.robotHeight : ''});
      this.setState({robotWeight: data.robotWeight != null ? data.robotWeight : ''});
      this.setState({robotWebcam: data.robotWebcam != null ? data.robotWebcam : ''});
      this.setState({autoLeavesStartZone: data.autoLeavesStartZone != null ? data.autoLeavesStartZone : false});
      this.setState({autoScorePreload: data.autoScorePreload != null ? data.autoScorePreload : ''});
      this.setState({autoPickupPiecesTotal: data.autoPickupPiecesTotal != null ? data.autoPickupPiecesTotal : ''});
      this.setState({autoPickupClosePieces: data.autoPickupClosePieces != null ? data.autoPickupClosePieces : ''});
      this.setState({autoPickupPiecesFar: data.autoPickupPiecesFar != null ? data.autoPickupPiecesFar : ''});
      this.setState({autoAmpScore: data.autoAmpScore != null ? data.autoAmpScore : ''});
      this.setState({autoSpeakerScore: data.autoSpeakerScore != null ? data.autoSpeakerScore : ''});
      this.setState({teleopScoreAmp: data.teleopScoreAmp != null ? data.teleopScoreAmp : false});
      this.setState({teleopScoreSpeaker: data.teleopScoreSpeaker != null ? data.teleopScoreSpeaker : false});
      this.setState({teleopSpeakerScoreDistance: data.teleopSpeakerScoreDistance != null ? data.teleopSpeakerScoreDistance : ''});
      this.setState({teleopAutoScoreSpeaker: data.teleopAutoScoreSpeaker != null ? data.teleopAutoScoreSpeaker : false});
      this.setState({teleopFloorPickup: data.teleopFloorPickup != null ? data.teleopFloorPickup : false});
      this.setState({teleopHumanPlayerPickup: data.teleopHumanPlayerPickup != null ? data.teleopHumanPlayerPickup : false});
      this.setState({endgameClimb: data.endgameClimb != null ? data.endgameClimb : false});
      this.setState({endgameTrapScore: data.endgameTrapScore != null ? data.endgameTrapScore : false});
      this.setState({endgameClimbHarmony: data.endgameClimbHarmony != null ? data.endgameClimbHarmony : false});
      this.setState({endgameMultipleTrapScore: data.endgameMultipleTrapScore != null ? data.endgameMultipleTrapScore : false});
      this.setState({jankRatingEndgameClimb: data.jankRatingEndgameClimb != null ? data.jankRatingEndgameClimb : ''});
      this.setState({jankRatingEndgameTrapScore: data.jankRatingEndgameTrapScore != null ? data.jankRatingEndgameTrapScore : ''});
      this.setState({jankRatingSpeakerScoring: data.jankRatingSpeakerScoring != null ? data.jankRatingSpeakerScoring : ''});
      this.setState({jankRatingAmpScoring: data.jankRatingAmpScoring != null ? data.jankRatingAmpScoring : ''});
      this.setState({jankRatingIntakeFloor: data.jankRatingIntakeFloor != null ? data.jankRatingIntakeFloor : ''});
      this.setState({jankRatingIntakeStation: data.jankRatingIntakeStation != null ? data.jankRatingIntakeStation : ''});
      this.setState({jankRatingTakeAHit: data.jankRatingTakeAHit != null ? data.jankRatingTakeAHit : ''});
      this.setState({jankRatingBatteryMount: data.jankRatingBatteryMount != null ? data.jankRatingBatteryMount : ''});

    }
  });
}

render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='pitScoutingPage'>
          <SideMenu/>
          { this.state.returnToScouting ? <Navigate to={`/ScoutingMain`} /> : null}
          <Modal className='modalScreen' open={(this.state.openFinalNotes)} onClose={this.handleOpenHistoricalScout}>
            <div className='matchScoutModalScreenAlign'>
              <div className='modalButtonsAlign'>
                <div className='modalScrollAllowment'>
                  <div>
                    Thank the team for the opportunity to scout their robot and for answering your questions, the rest of the form is up to your interpretation. Do NOT ask the team these questions.                  </div>
                  <div className='scoutingPageTextHeader2'>
                    JANK Rating
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Climb Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingEndgameClimb}
                        name="jankRatingEndgameClimb"
                        label="Climb Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Trap Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingEndgameTrapScore}
                        name="jankRatingEndgameTrapScore"
                        label="Trap Jank Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Speaker Score Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingSpeakerScoring}
                        name="jankRatingSpeakerScoring"
                        label="Speaker Score Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Amp Score Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingAmpScoring}
                        name="jankRatingAmpScoring"
                        label="Amp Score Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Intake Floor Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingIntakeFloor}
                        name="jankRatingIntakeFloor"
                        label="Intake Floor Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Intake Player Station Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingIntakeStation}
                        name="jankRatingIntakeStation"
                        label="Intake Player Station Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Battery Mount Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingBatteryMount}
                        name="jankRatingBatteryMount"
                        label="Battery Mount Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Can It Take A Hit Rating</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.jankRatingTakeAHit}
                        name="jankRatingTakeAHit"
                        label="Can It Take A Hit Rating"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - Doesn't Exist/Doesn't Work</MenuItem>
                        <MenuItem value={2}>2 - Bad</MenuItem>
                        <MenuItem value={3}>3 - Low Hopes</MenuItem>
                        <MenuItem value={4}>4 - Alright Design</MenuItem>
                        <MenuItem value={5}>5 - Reasonable Shot</MenuItem>
                        <MenuItem value={6}>6 - Strong Chance</MenuItem>
                        <MenuItem value={7}>7 - Good Build</MenuItem>
                        <MenuItem value={8}>8 - Excellent</MenuItem>
                        <MenuItem value={9}>9 - Amazing</MenuItem>
                        <MenuItem value={10}>10 - Perfect</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Final Thoughts
                  </div>
                  <div className='scoutingPageField'>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Scouting Priority</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.scoutingPriority}
                        name="scoutingPriority"
                        label="Scouting Priority"
                        onChange={(event) => this.handleEventSelect(event)}
                      >
                        <MenuItem value={1}>1 - High</MenuItem>
                        <MenuItem value={2}>2 - Medium </MenuItem>
                        <MenuItem value={3}>3 - Low</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                  <div className='scoutingPageField'>
                      <TextField
                      fullWidth
                      value={this.state.finalNotes}
                      label="Notes"
                      multiline
                      maxRows={4}
                      onChange={(e) => {
                          this.setState({finalNotes: e.target.value});
                      }}
                  />
                  </div>
                </div>
              </div>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleOpenHistoricalScout}>Close</Button>
              </div>
          </Modal>
          <div className='pitScoutingAlignHeader'>
            <div className='pitScoutingTeamNumberIncrease'>
              {this.state.teamNumber}
            </div>
            <div className='pitScoutingTeamNumber'>
              Pit Scout
            </div>
          </div>
          <div className='scoutingPageFormFields'>
            <div className='scoutingPageFields'>
              <div className='scoutingPageTextHeader1'>
                Generic Info
              </div>
              <div className='scoutingPageTextHeader2'>
                Driver Experience
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Seasons Driven</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.seasonsDriven}
                    name="seasonsDriven"
                    label="Seasons Driven"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Current Season Only</MenuItem>
                    <MenuItem value={2}>Current and 1 Previous</MenuItem>
                    <MenuItem value={3}>Current and 2 Previous</MenuItem>
                    <MenuItem value={4}>Current and 3 Previous (or more)</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Current Seasons Events Driven</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.currentSeasonEvents}
                    name="currentSeasonEvents"
                    label="Current Seasons Events Driven"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Current Event</MenuItem>
                    <MenuItem value={2}>Current and 1 Previous</MenuItem>
                    <MenuItem value={3}>Current and 2 Previous</MenuItem>
                    <MenuItem value={4}>Current and 3 Previous (or more)</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageTextHeader2'>
                Robot Information
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Robot Drive Base</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.robotDriveBase}
                    name="robotDriveBase"
                    label="Robot Drive Base"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Tank Drive</MenuItem>
                    <MenuItem value={2}>Swerve Drive</MenuItem>
                    <MenuItem value={3}>Mecanum Drive</MenuItem>
                    <MenuItem value={4}>H Drive</MenuItem>
                    <MenuItem value={5}>Omni Drive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Drive Motor Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.driveMotorType}
                    name="driveMotorType"
                    label="Drive Motor Type"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Neo</MenuItem>
                    <MenuItem value={2}>Falcon</MenuItem>
                    <MenuItem value={3}>Kracken</MenuItem>
                    <MenuItem value={4}>Neo 2.0</MenuItem>
                    <MenuItem value={5}>CIM</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Robot Top Speed</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.robotTopSpeed}
                    name="robotTopSpeed"
                    label="Robot Top Speed"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Up To 10m/s</MenuItem>
                    <MenuItem value={2}>10 To 15m/s</MenuItem>
                    <MenuItem value={3}>Over 15m/s</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.robotLength}
                  label="Robot Length In Inches"
                  onChange={(e) => {
                      this.setState({robotLength: e.target.value});
                  }}
              />
              </div>
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.robotWidth}
                  label="Robot Width In Inches"
                  onChange={(e) => {
                      this.setState({robotWidth: e.target.value});
                  }}
              />
              </div>
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.robotHeight}
                  label="Robot Height In Inches"
                  onChange={(e) => {
                      this.setState({robotHeight: e.target.value});
                  }}
              />
              </div>
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.robotWeight}
                  label="Robot Weight In Pounds"
                  onChange={(e) => {
                      this.setState({robotWeight: e.target.value});
                  }}
              />
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Robot Webcam</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.robotWebcam}
                    name="robotWebcam"
                    label="Robot Webcam"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Limelight</MenuItem>
                    <MenuItem value={2}>Webcam</MenuItem>
                    <MenuItem value={3}>None</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageTextHeader1'>
                Season Specific Info
              </div>
              <div className='scoutingPageTextHeader2'>
                Auto
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.autoLeavesStartZone}  onClick={() => this.setState({autoLeavesStartZone: !this.state.autoLeavesStartZone})} />} label="Robot Moves Out Of Starting Zone" />
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Score Preload</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoScorePreload}
                    name="autoScorePreload"
                    label="Score Preload"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={1}>Speaker</MenuItem>
                    <MenuItem value={2}>Amp</MenuItem>
                    <MenuItem value={3}>No Preload Score</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Pickup Additional Notes Total</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoPickupPiecesTotal}
                    name="autoPickupPiecesTotal"
                    label="Pickup Additional Notes Total"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Pickup Additional Notes Close</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoPickupClosePieces}
                    name="autoPickupClosePieces"
                    label="Pickup Additional Notes Close"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Pickup Additional Notes Far</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoPickupPiecesFar}
                    name="autoPickupPiecesFar"
                    label="Pickup Additional Notes Far"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Amp Scored In Auto</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoAmpScore}
                    name="autoAmpScore"
                    label="Amp Scored In Auto"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageField'>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Speaker Scored In Auto</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.autoSpeakerScore}
                    name="autoSpeakerScore"
                    label="Speaker Scored In Auto"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className='scoutingPageTextHeader2'>
                Teleop Scoring
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.teleopScoreAmp}  onClick={() => this.setState({teleopScoreAmp: !this.state.teleopScoreAmp})} />} label="Can Score In Amp" />
              <FormControlLabel control={<Checkbox checked={this.state.teleopScoreSpeaker}  onClick={() => this.setState({teleopScoreSpeaker: !this.state.teleopScoreSpeaker})} />} label="Can Score In Speaker" />
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.teleopSpeakerScoreDistance}
                  label="Speaker Score MAX Range In Feet"
                  onChange={(e) => {
                      this.setState({teleopSpeakerScoreDistance: e.target.value});
                  }}
              />
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.teleopAutoScoreSpeaker}  onClick={() => this.setState({teleopAutoScoreSpeaker: !this.state.teleopAutoScoreSpeaker})} />} label="Robot Uses Auto Score For Speaker (Limelight)" />
              <div className='scoutingPageTextHeader2'>
                Teleop Intake
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.teleopFloorPickup}  onClick={() => this.setState({teleopFloorPickup: !this.state.teleopFloorPickup})} />} label="Can Pickup From Floor" />
              <FormControlLabel control={<Checkbox checked={this.state.teleopHumanPlayerPickup}  onClick={() => this.setState({teleopHumanPlayerPickup: !this.state.teleopHumanPlayerPickup})} />} label="Can Pickup From Player Station" />
              <div className='scoutingPageTextHeader2'>
                Teleop Endgame
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.endgameClimb}  onClick={() => this.setState({endgameClimb: !this.state.endgameClimb})} />} label="Can Climb" />
              <FormControlLabel control={<Checkbox checked={this.state.endgameClimbHarmony}  onClick={() => this.setState({endgameClimbHarmony: !this.state.endgameClimbHarmony})} />} label="Can Achieve Climb Harmony" />
              <FormControlLabel control={<Checkbox checked={this.state.endgameTrapScore}  onClick={() => this.setState({endgameTrapScore: !this.state.endgameTrapScore})} />} label="Can Score Trap" />
              <FormControlLabel control={<Checkbox checked={this.state.endgameMultipleTrapScore}  onClick={() => this.setState({endgameMultipleTrapScore: !this.state.endgameMultipleTrapScore})} />} label="Can Score Multiple Traps" />

              <div className='buttonSpacing'>
                <Button className='scoutingPitSubmitButton' variant="contained" size='large' color='secondary' onClick={this.handleOpenHistoricalScout}>Open Final Notes</Button>
              </div>

              <Button disabled={!this.state.allowFinalSubmit} className='scoutingPitSubmitButton' variant="contained" size='large' color='secondary' onClick={this.handlePitScout}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}