import * as React from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import './scoutingMain.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {Navigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

dayjs.extend(utc);
const db = getDatabase();

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default class ScoutingMain extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      eventList: [],
      teamNumber: sessionStorage.getItem('TEAMNUMBER'),
      todayDate : dayjs.utc(),
      eventSelectedIndex: 0,
      selectedEventData: {},
      teamNaviate: false,
      teamNumberNavigate: 0,
      openMatchScout: false,
      openHistoricalScout: false,
      openFreeScout: false,
      openForcedScout : false,
      matchScoutTeamNumber: '',
      matchScoutMatchNumber: '',
      matchScoutPracticeMatch: false,
      matchScoutPlayoffMatch: false,
      matchScoutAllowSubmitFreeScout: false,
      matchScoutNavigateFreeScout: false,
      matchScoutAllowSubmitHistoricalScout: false,
      matchScoutHistoricalEventSelectedIndex: '',
      matchScoutHistoricalEvents: [],
      matchScoutHistoricalEventSelected: false,
      matchScoutNavigateHistoricalScout: false,
      checkBlueAllianceMatches: false,
      forcedScoutingMatchDropdown: [],
      matchScoutForcedMatchSelectedIndex: '',
      matchScoutNavigateForcedScout: false,
      matchScoutForcedColor: ''

    }
    this.eventSelectDropdown = this.eventSelectDropdown.bind(this);
    this.handleEventSelect = this.handleEventSelect.bind(this);
    this.handleRobotList = this.handleRobotList.bind(this);
    this.handleTeamSelect = this.handleTeamSelect.bind(this);
    this.handleOpenMatchScout = this.handleOpenMatchScout.bind(this);
    this.handleOpenHistoricalScout = this.handleOpenHistoricalScout.bind(this);
    this.handleOpenFreeScout = this.handleOpenFreeScout.bind(this);
    this.handleOpenForcedScout = this.handleOpenForcedScout.bind(this);
    this.handleFreeScoutStart = this.handleFreeScoutStart.bind(this);
    this.checkFreeScout = this.checkFreeScout.bind(this);
    this.checkHistoricalScout = this.checkHistoricalScout.bind(this);
    this.handleHistoricalScoutStart = this.handleHistoricalScoutStart.bind(this);
    this.getHistoricalScoutDropdown = this.getHistoricalScoutDropdown.bind(this);
    this.handleHistoricalScoutEventSelect = this.handleHistoricalScoutEventSelect.bind(this);
    this.checkBlueAllianceMatches = this.checkBlueAllianceMatches.bind(this);
    this.handleCheckBlueAllianceMatches = this.handleCheckBlueAllianceMatches.bind(this);
    this.getForcedScoutingMatchDropdown = this.getForcedScoutingMatchDropdown.bind(this);
    this.handleForcedScoutMatchSelect = this.handleForcedScoutMatchSelect.bind(this);
    this.handleForcedScoutMatchStart = this.handleForcedScoutMatchStart.bind(this);
    
  }

 handleForcedScoutMatchStart() {
    const matchNumber = this.state.forcedScoutingMatchDropdown[this.state.matchScoutForcedMatchSelectedIndex].matchNumber;
    let redAlliance, blueAlliance, allTeams, nonTakenTeams = [];
    let pickList = [];
    let teamNumber = '';

    onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME')}/eventMatches/${matchNumber}`), (snapshot) => {
      if(snapshot.val() != null) {
        redAlliance = snapshot.val().red;
        blueAlliance = snapshot.val().blue;
        allTeams = redAlliance.concat(blueAlliance);
      }

      for(let team in allTeams) {
        if(!allTeams[team].taken) {
          nonTakenTeams.push(allTeams[team].teamNumber);
        }
      }

      if(nonTakenTeams.length > 0) {
      for(let team in nonTakenTeams) {
        onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME')}/teams/${nonTakenTeams[team]}`), (snapshot) => {
          let matchScoutRank = Number(snapshot.val().scoutPriority? snapshot.val().scoutPriority : 3);

          for(let match in snapshot.val().matches) { // Add in previous scouted matches
            matchScoutRank++;
          }
          
          pickList.push({teamNumber: nonTakenTeams[team], matchScoutRank});
        });
      }

      pickList.sort((a, b) => (a.matchScoutRank > b.matchScoutRank) ? 1 : -1);

      teamNumber = pickList[0].teamNumber;
      } else {
        teamNumber = allTeams[randomIntFromInterval(0, allTeams.length - 1)].teamNumber;
      }

      let color = '';

      for(let team in redAlliance) {
        if(redAlliance[team].teamNumber == teamNumber) {
          color = 'red';
        }
      }

      for(let team in blueAlliance) {
        if(blueAlliance[team].teamNumber == teamNumber) {
          color = 'blue';
        }
      }

      this.setState({matchScoutForcedColor: color, matchScoutTeamNumber: teamNumber, matchScoutMatchNumber: matchNumber, matchScoutPracticeMatch: false, matchScoutPlayoffMatch: false, matchScoutNavigateForcedScout: true});

    });




  }

  handleForcedScoutMatchSelect(event) {
    this.setState({matchScoutForcedMatchSelectedIndex: event.target.value});
  }

  getForcedScoutingMatchDropdown() {
    return(
      <div className='eventSelectDropdown' style={{marginBottom: '0px'}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Match</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.matchScoutForcedMatchSelectedIndex}
            label="Match"
            onChange={(event) => this.handleForcedScoutMatchSelect(event)}
          >
            {this.state.forcedScoutingMatchDropdown.map((match, index) => (
              <MenuItem value={index}>{match.matchNumber}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }
  handleCheckBlueAllianceMatches() {
    axios.post(getAPI() + `/scouting/scoutingUpdateForcedMatches` , {event: encodeURIComponent(sessionStorage.getItem('EVENTNAME'))}).then((response) => this.checkBlueAllianceMatches());
  }

  handleHistoricalScoutEventSelect(event) {
    this.setState({matchScoutHistoricalEventSelectedIndex: event.target.value, matchScoutHistoricalEventSelected: true}, () => {this.checkHistoricalScout()});
  }

  checkBlueAllianceMatches() {
    onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME')}/BlueAllianceUpdatedForcedScouting`), (snapshot) => {
      if(snapshot.val() != null){
        if(snapshot.val() == true) {
          this.setState({checkBlueAllianceMatches: true});
          onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME')}/eventMatches`), (snapshot) => {
            if(snapshot.val() != null) {
              this.setState({forcedScoutingMatchDropdown: snapshot.val()});
            }
          });
        } else
        {
          this.setState({checkBlueAllianceMatches: false});
        }

      } else 
      {
        this.setState({checkBlueAllianceMatches: false});
      }
    });
  }

  getHistoricalScoutDropdown() {
    return(
      <div className='eventSelectDropdown' style={{marginBottom: '0px'}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Event</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.matchScoutHistoricalEventSelectedIndex}
            label="Event"
            onChange={(event) => this.handleHistoricalScoutEventSelect(event)}
          >
            {this.state.matchScoutHistoricalEvents.map((event, index) => (
              <MenuItem value={index}>{event}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  }

  checkFreeScout() {

    let foundTeamNumber = false;
    for(let team in this.state.selectedEventData.teams) {
      if(this.state.selectedEventData.teams[team].TeamNumber == this.state.matchScoutTeamNumber) {
        foundTeamNumber = true;
      }
    }

    if((this.state.matchScoutMatchNumber == '' || this.state.matchScoutTeamNumber == '') || !foundTeamNumber) {

      this.setState({matchScoutAllowSubmitFreeScout: false});
    }
    else this.setState({matchScoutAllowSubmitFreeScout: true});

  }

  handleFreeScoutStart() {
   this.setState({matchScoutNavigateFreeScout: true});
  }

  checkHistoricalScout() {
    onValue(ref(db, `scouting/2024/teams/${this.state.matchScoutTeamNumber}`), (snapshot) => {
      if(snapshot.val() != null) {
        if(snapshot.val().Events != undefined) {
          this.setState({matchScoutHistoricalEvents: snapshot.val().Events});}
      }
    }
    );
    if((this.state.matchScoutMatchNumber == '' || this.state.matchScoutTeamNumber == '' || !this.state.matchScoutHistoricalEventSelected) ) {
      this.setState({matchScoutAllowSubmitHistoricalScout: false});
    }
    else this.setState({matchScoutAllowSubmitHistoricalScout: true});

  }

  handleHistoricalScoutStart() {
    this.setState({matchScoutNavigateHistoricalScout: true});
  }
    
  handleOpenMatchScout() {
    this.setState({openMatchScout: !this.state.openMatchScout});
  }

  handleOpenHistoricalScout() {
    this.setState({openHistoricalScout: !this.state.openHistoricalScout});
  }

  handleOpenFreeScout() {
    this.setState({openFreeScout: !this.state.openFreeScout});
  }

  handleOpenForcedScout() {
    this.setState({openForcedScout: !this.state.openForcedScout});
  }

componentDidMount() {
  onValue(ref(db, `scouting/2024/teams/${this.state.teamNumber}`), (snapshot) => {
    if(snapshot.val() != null) {

      let closestEventIndex = 0;
      let closestEventDateDiff;
      let eventData = {};
      for(let event in snapshot.val().Events) {
        let eventStartDate;

        onValue(ref(db, `scouting/2024/events/${snapshot.val().Events[event]}`), (snapshot) => {
          eventStartDate = dayjs.utc(snapshot.val().StartDate);
          let eventDateDiff = eventStartDate.diff(this.state.todayDate, 'days');
          if(eventDateDiff < closestEventDateDiff || (closestEventDateDiff == undefined || closestEventDateDiff <= -6)) {
            closestEventDateDiff = eventDateDiff;
            closestEventIndex = event;
            eventData = snapshot.val();
          }
          this.setState({eventSelectedIndex: closestEventIndex, selectedEventData: eventData});
          sessionStorage.setItem('EVENTNAME', eventData.Name);
        });
      }
      this.setState({eventList: snapshot.val().Events});
    }
  });
  this.checkBlueAllianceMatches();
}

eventSelectDropdown() {
  return(
    <div className='eventSelectDropdown'>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Event</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={this.state.eventSelectedIndex}
          label="Event"
          onChange={(event) => this.handleEventSelect(event)}
        >
          {this.state.eventList.map((event, index) => (
            <MenuItem value={index}>{event}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )

}

handleRobotList() {
  let robotList = [];
  for(let team in this.state.selectedEventData.teams) {
    let scoutPriority = this.state.selectedEventData.teams[team].scoutPriority? this.state.selectedEventData.teams[team].scoutPriority : 0;
    robotList.push(
      <div className={scoutPriority == 0 ? 'robotList' : scoutPriority == 1 ? 'robotListRed' : scoutPriority == 2 ? 'robotListYellow' : 'robotListGreen'} onClick={(event) => this.handleTeamSelect(event, this.state.selectedEventData.teams[team].TeamNumber)} replace>
        <div className={scoutPriority == 0 ? 'robotListName' : scoutPriority == 1 ? 'robotListNameRed' : scoutPriority == 2 ? 'robotListNameYellow' : 'robotListNameGreen'}>{this.state.selectedEventData.teams[team].TeamName}</div>
        <div className={scoutPriority == 0 ? 'robotListNumber' : scoutPriority == 1 ? 'robotListNumberRed' : scoutPriority == 2 ? 'robotListNumberYellow' : 'robotListNumberGreen'}>{this.state.selectedEventData.teams[team].TeamNumber}</div>
      </div>
    )
  }
  return robotList;
}

handleEventSelect(event) {
  this.setState({eventSelectedIndex: event.target.value});

  onValue(ref(db, `scouting/2024/events/${this.state.eventList[event.target.value]}`), (snapshot) => {
    this.setState({selectedEventData: snapshot.val()});
    sessionStorage.setItem('EVENTNAME', snapshot.val().Name);
  });
}

handleTeamSelect(event, team) {
  event.preventDefault();
  this.setState({teamNumberNavigate: team, teamNaviate: true});
}

render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='scoutingMainPage'>
          <SideMenu/>
          {this.state.teamNaviate && (
            <Navigate to={`/TeamScout/${this.state.teamNumberNavigate}`} replace={true} />
          )
          }
          {this.state.matchScoutNavigateFreeScout && (
            <Navigate to={`/MatchScouting/?matchNumber=${this.state.matchScoutMatchNumber}&teamNumber=${this.state.matchScoutTeamNumber}&isPractice=${this.state.matchScoutPracticeMatch}&isPlayoff=${this.state.matchScoutPlayoffMatch}`} replace={true} />
          )
          }
          {this.state.matchScoutNavigateHistoricalScout && (
            <Navigate to={`/MatchScouting/?matchNumber=${this.state.matchScoutMatchNumber}&teamNumber=${this.state.matchScoutTeamNumber}&event=${this.state.matchScoutHistoricalEvents[this.state.matchScoutHistoricalEventSelectedIndex]}`} replace={true} />
          )
          }
          {this.state.matchScoutNavigateForcedScout && (
            <Navigate to={`/MatchScouting/?matchNumber=${this.state.matchScoutMatchNumber}&teamNumber=${this.state.matchScoutTeamNumber}&allianceColor=${this.state.matchScoutForcedColor}`} replace={true} />
          )
          }
          <Modal className='modalScreen' open={(this.state.openMatchScout)} onClose={this.handleOpenMatchScout}>
            <div className='matchScoutModalScreenAlign'>
              <div className='modalButtonsAlign'>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'secondary'} onClick={this.handleOpenHistoricalScout}>Historical Scout</Button>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'secondary'} onClick={this.handleOpenFreeScout}>Free Scout</Button>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'secondary'} onClick={this.handleOpenForcedScout}>Forced Scout</Button>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleOpenMatchScout}>Close</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openHistoricalScout)} onClose={this.handleOpenHistoricalScout}>
            <div className='matchScoutModalScreenAlign'>
              <div className='modalButtonsAlign'>
                <div className='scoutingPageField'>
                    <TextField
                    fullWidth
                    value={this.state.matchScoutTeamNumber}
                    label="Team Number"
                    onChange={(e) => {
                        this.setState({matchScoutTeamNumber: e.target.value},() => {this.checkHistoricalScout()});
                    }}
                />
                </div>
                {this.getHistoricalScoutDropdown()}
                <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.matchScoutMatchNumber}
                  label="Match Number"
                  onChange={(e) => {
                      this.setState({matchScoutMatchNumber: e.target.value},() => {this.checkHistoricalScout()});
                  }}
              />
              </div>
              <Button disabled={!this.state.matchScoutAllowSubmitHistoricalScout} className='matchScoutingButton' variant="contained" size='large' color={'signIn'} onClick={this.handleHistoricalScoutStart}>Start</Button>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleOpenHistoricalScout}>Close</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openFreeScout)} onClose={this.handleOpenFreeScout}>
            <div className='matchScoutModalScreenAlign'>
              <div className='modalButtonsAlign'>
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.matchScoutMatchNumber}
                  label="Match Number"
                  onChange={(e) => {
                      this.setState({matchScoutMatchNumber: e.target.value},() => {this.checkFreeScout()});
                  }}
              />
              </div>
              <FormControlLabel control={<Checkbox checked={this.state.matchScoutPlayoffMatch}  onClick={() => this.setState({matchScoutPlayoffMatch: !this.state.matchScoutPlayoffMatch, matchScoutPracticeMatch: false})} />} label="Playoff Match" />
              <FormControlLabel control={<Checkbox checked={this.state.matchScoutPracticeMatch}  onClick={() => this.setState({matchScoutPracticeMatch: !this.state.matchScoutPracticeMatch, matchScoutPlayoffMatch: false})} />} label="Practice Match" />
              <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.matchScoutTeamNumber}
                  label="Team Number"
                  onChange={(e) => {
                      this.setState({matchScoutTeamNumber: e.target.value},() => {this.checkFreeScout()});
                  }}
              />
              </div>
              <Button disabled={!this.state.matchScoutAllowSubmitFreeScout} className='matchScoutingButton' variant="contained" size='large' color={'signIn'} onClick={this.handleFreeScoutStart}>Start</Button>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleOpenFreeScout}>Close</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openForcedScout)} onClose={this.handleOpenForcedScout}>
            <div className='matchScoutModalScreenAlign'>
              <div className='modalButtonsAlign'>
                {!this.state.checkBlueAllianceMatches && 
                  <div className='modalButtonsAlign'>
                    <div className='modalTextInfo'>
                     Currently No Match Data Found, Please Check For Data
                     </div>
                    <Button className='matchScoutingButton' variant="contained" size='large' color={'signIn'} onClick={this.handleCheckBlueAllianceMatches}>Check Data</Button>
                  </div>
                }
                {this.state.checkBlueAllianceMatches && 
                  <div className='modalButtonsAlign'>
                    {this.getForcedScoutingMatchDropdown()}
                    <Button disabled={!this.state.matchScoutForcedMatchSelectedIndex != ''} className='matchScoutingButton' variant="contained" size='large' color={'signIn'} onClick={this.handleForcedScoutMatchStart}>Start</Button>
                  </div>
                }
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleOpenForcedScout}>Close</Button>
              </div>
            </div>
          </Modal>
          {this.eventSelectDropdown()}
          <div className='buttonSpacing'>
            <Button className='scoutingMainMatchScoutButton' variant="contained" size='large' color='secondary' onClick={this.handleOpenMatchScout}>Match Scouting</Button>
          </div>
          <div className='robotListContainer'>
            {this.handleRobotList()}
          </div>
        </div>
      </div>
    </div>
    )
  }
}