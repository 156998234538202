import * as React from 'react';
import './admin.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import EditSignIn from '../../components/editSignIn/editSignIn';
import SideMenu from '../../components/sideMenu/sideMenu';

dayjs.extend(utc);

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export default class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      user: {
        teamNumber: sessionStorage.getItem('TEAMNUMBER'),
        userName: '',
        userListID: 0
      },
      team: {
        userList: [
          {uid: 'yKPe1ZZDddPOSNtRKr5yfrXeLkK2', name: 'Test User', role: ['default', 'admin'], roboticsSignIns: [], volunteerSignIns: []},
          {uid: 'yKPe1ZZDddPOSNtRKr5yfrXeLkK2', name: 'Test User2', role: ['default']},
          {uid: 'yKPe1ZZDddPOSNtRKr5yfrXeLkK2', name: 'Test User3', role: ['default']}
        ]
      },
      submitted: false,
      lastAdmin: false,
      modalOpen: false,
      modalEditOpen: false,
      signType: false,
      signStart: '',
      signEnd: '',
      signID: ''
    }

    this.teamRolesList = this.teamRolesList.bind(this);
    this.handleCheckAdmin = this.handleCheckAdmin.bind(this);
    this.adminRoleCheck = this.adminRoleCheck.bind(this);
    this.refreshUserList = this.refreshUserList.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalEdit = this.handleModalEdit.bind(this);
    this.signList = this.signList.bind(this);
    this.getTime = this.getTime.bind(this);
    this.getSeconds = this.getSeconds.bind(this);
    this.handleModalEditUpdate = this.handleModalEditUpdate.bind(this);

  }; 

  componentDidMount() {

    this.refreshUserList();

  }

  handleModal(event,user) {
    event.preventDefault();
    let userListID = 0;
    let fullrun = false;
    if( user ) {
      this.state.team.userList.forEach((listUser, index) => {
        if (listUser.uid === user.uid) {
          userListID = index;
          fullrun = true;
        }
      });
    }
    if(fullrun) this.setState({modalOpen: !this.state.modalOpen, user: {...user, userName: user.name, userListID}});
    else this.setState({modalOpen: !this.state.modalOpen});
  }

  handleModalEdit(event, start, end, type) {
    if(event) event.preventDefault();
    let ID = 0;

    if(type == 'robotics') { 
      this.state.team.userList[this.state.user.userListID].roboticsSignIns.forEach((sign, index) => {
        if (sign.start === start && sign.end === end) {
          ID = index;
        }
      });
    } else {
      this.state.team.userList[this.state.user.userListID].volunteerSignIns.forEach((sign, index) => {
        if (sign.start === start && sign.end === end) {
          ID = index;
        }
      });
    }

    this.setState({modalEditOpen: !this.state.modalEditOpen, signType: type, signStart: start, signEnd: end, signID: ID});
  }
  handleModalEditUpdate(event, newStart, newEnd) {
    if(event) event.preventDefault();

    let userList = this.state.team.userList;

      if(this.state.signType == 'robotics') {
        userList[this.state.user.userListID].roboticsSignIns[this.state.signID].start = newStart;
        userList[this.state.user.userListID].roboticsSignIns[this.state.signID].end = newEnd;
      } else {
        userList[this.state.user.userListID].volunteerSignIns[this.state.signID].start = newStart;
        userList[this.state.user.userListID].volunteerSignIns[this.state.signID].end = newEnd;
      }

    this.setState({modalEditOpen: !this.state.modalEditOpen, team: {userList}});
  }


  getSeconds(start, end) {
      
      const nowTime = dayjs(end);
      const time = start;
      const timeDifference = nowTime.diff(time, 'seconds');
      
      return timeDifference;
  }
  getTime(start, end) {

    const nowTime = dayjs(end);
    const time = start;
    const timeDifference = nowTime.diff(time, 'seconds');
    
    const seconds = ((timeDifference % 60 == NaN ? '0' : timeDifference % 60) < 10 ? '0' + (timeDifference % 60 == NaN ? '00' : timeDifference % 60) :  (timeDifference % 60 == NaN ? '0' : timeDifference % 60));
    const minutes = ((Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) < 10 ? '0' + (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) : (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))));
    const hours = ((Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) < 10 ? '0' + (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) : (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))));

    return `${hours}:${minutes}:${seconds}`;
  
  }

  signList(user) {
    const roboticsSignIns = user.roboticsSignIns ? user.roboticsSignIns : [];
    const volunteerSignIns = user.volunteerSignIns ? user.volunteerSignIns : [];

    return (
      <div className='adminPageSignListAlign'>
       <div className='adminPageSignListFullSize'>
       {roboticsSignIns.map(sign => (
          <div className='adminPageSignListItem'>
            <div className='roboticsText'>
              Robotics
            </div>
            {this.getSeconds(sign.start, sign.end) >= 28800 &&
              <div style={{'color': '#fc5b82'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
              {(this.getSeconds(sign.start, sign.end) < 28800 && this.getSeconds(sign.start, sign.end) > 14400) &&
              <div style={{'color': '#f0ce71'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
              {this.getSeconds(sign.start, sign.end) <= 14400 &&
              <div style={{'color': '#5ec462'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
            <Button className='editSignButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handleModalEdit(event,sign.start,sign.end,'robotics')}>Edit</Button>
          </div>
          
        ))}
        {volunteerSignIns.map(sign => (
          <div className='adminPageSignListItem'>
             <div className='volunteerText'>
             Volunteer
            </div>
            {this.getSeconds(sign.start, sign.end) >= 28800 &&
              <div style={{'color': '#fc5b82'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
              {(this.getSeconds(sign.start, sign.end) < 28800 && this.getSeconds(sign.start, sign.end) > 14400) &&
              <div style={{'color': '#f0ce71'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
              {this.getSeconds(sign.start, sign.end) <= 14400 &&
              <div style={{'color': '#5ec462'}}>
                {dayjs(sign.start).format('DD/MM/YYYY')} - {this.getTime(sign.start, sign.end)}
              </div>
            }
            <Button className='editSignButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handleModalEdit(event,sign.start,sign.end,'volunteer')}>Edit</Button>
          </div>
        ))}
       </div>
      </div>
    )    

  }


  refreshUserList() { 
    const { user } = this.state;

    axios.post(getAPI() + `/admin/adminTeamList` , {teamNumber : encodeURIComponent(user.teamNumber)})
    .then(res => {
      const data = res;
      if(data.status != 200) {
        //this.setState({ loginFailedToSubmit: true });
      }
      else if(!data.data.success) {
        //this.setState({ loginRejected: true });
      } else {

        let lastAdmin = false;
        let count = 0;

        for (const value in data.data.userList) {
          if (data.data.userList[value].role.includes('admin')) {
            count++;
          }
        }

        if(count === 1) lastAdmin = true;
        this.setState({team:{userList: data.data.userList}, lastAdmin});
      }
    });
  }

  handleCheckAdmin = (event, uid) => {

    axios.post(getAPI() + `/admin/adminRoleUpdate` , {uid : encodeURIComponent(uid), allowAdmin: encodeURIComponent(event.target.checked)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          //this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
          //this.setState({ loginRejected: true });
        } else {
          this.setState({submitted: false}, () => {
            this.refreshUserList();
          });
        }
      });

  };

  adminRoleCheck (roleArray) {
    let check = false;

    roleArray.forEach(role => { 
      if (role === 'admin') {
        check = true;
      }
    });

    return check;
  }

  teamRolesList() {
    const userList = this.state.team.userList;
    return (
      <div className='userListData'>
        Team User List
        <div className='userListBox'>
          {userList.map(user => (
            <div className='userListAlign'>
              <div className='userName'>
                {user.name}
              </div>
              <div className='userRoleList'>
                <div className='userRole'>
                  Admin:
                  <Switch size="small" {...label} disabled={this.adminRoleCheck(user.role) && this.state.lastAdmin} checked={this.adminRoleCheck(user.role)} onChange={(e) => this.handleCheckAdmin(e, user.uid)} color="secondary"/>
                </div>
              </div>
              <Button className='editSignButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handleModal(event,user)}>Edit Time</Button>
            </div>
          ))}
      </div>
    </div>
    )
  }


render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='adminPage'>
          <SideMenu/>
          <Modal className='modalScreen' open={(this.state.modalOpen)} onClose={this.handleModal}>
            <div className='modalAlign'>
              <div className='displayName'>
                {this.state.user.userName}
              </div>
              {this.signList(this.state.team.userList[this.state.user.userListID])}
              <Button className='editSignButton' variant="contained" size='small' color='signOut' onClick={this.handleModal}>Close</Button>
            </div>
          </Modal>
          <Modal className='modalScreen' open={this.state.modalEditOpen} onClose={this.handleModalEdit}>
            <div>
              <EditSignIn closeModal={this.handleModalEditUpdate} session={{uid: this.state.team.userList[this.state.user.userListID].uid, timeStart: this.state.signStart, timeEnd: this.state.signEnd, signType: this.state.signType, signID: this.state.signID}}/>
            </div>
          </Modal>
          <div className='headerText'>
            Team {this.state.user.teamNumber} Admin
          </div>
          {this.teamRolesList()}
        </div>
      </div>
    </div>
    )
}

}