import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import './App.css';
import PrivateRoute from "./components/privateRoute/privateRoute";
import PrivateRouteAdmin from "./components/privateRouteAdmin/privateRouteAdmin";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Login from "./pages/login/login";
import PasswordReset from "./pages/passwordReset/passwordReset";
import UserRegister from "./pages/userRegister/userRegister";
import SignInOut from './pages/signInOut/signInOut';
import Admin from './pages/admin/admin';
import SignAllInOut from './pages/signAllInOut/signAllInOut';
import GlobalTeamData from './pages/globalTeamData/globalTeamData';
import ScoutingMain from "./pages/scoutingMain/scoutingMain";
import PitScouting from "./pages/pitScouting/pitScouting";
import TeamScout from "./pages/teamScout/teamScout";
import MatchScouting from "./pages/matchScouting/matchScouting";
import FullEventView from "./pages/fullEventView/fullEventView";
import RedirectPages from "./pages/redirectPages/redirectPages";

const theme = createTheme({
  palette: {
    secondary: {
      main: '#3D3988',
    },
    signIn: {
      main: '#34eb37',
    },
    signOut: {
      main: '#bf0612',
    },
    editSign: {
      main: '#d49e15'
    },
    icon: {
      main: '#ffffff'
    }
  }
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />}/>
            <Route path="/Login" element={<Login />}/>
            <Route path="/PasswordReset" element={<PasswordReset />}/>
            <Route path="/UserRegister" element={<UserRegister />}/>
            <Route path="/SignIn" element={<PrivateRoute > <SignInOut /> </PrivateRoute>} />
            <Route path="/SignAllInOut" element={<PrivateRouteAdmin > <SignAllInOut /> </PrivateRouteAdmin>} />
            <Route path="/Admin" element={<PrivateRouteAdmin > <Admin /> </PrivateRouteAdmin>} />
            <Route path="/GlobalTeamData" element={<PrivateRoute > <GlobalTeamData /> </PrivateRoute>} />
            <Route path="/ScoutingMain" element={<PrivateRoute > <ScoutingMain /> </PrivateRoute>} />
            <Route path="/PitScouting/:teamNumber" element={<PrivateRoute > <PitScouting /> </PrivateRoute>} />
            <Route path="/TeamScout/:teamNumber" element={<PrivateRoute > <TeamScout /> </PrivateRoute>} />
            <Route path="/MatchScouting" element={<PrivateRoute > <MatchScouting /> </PrivateRoute>} />
            <Route path="/FullEventView" element={<PrivateRoute > <FullEventView /> </PrivateRoute>} />
            <Route
                path="/RedirectPages"
                element={<RedirectPages />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
