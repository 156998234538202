function isDev() {
  return ( process.env.NODE_ENV === 'development' || !('%NODE_ENV%') || '%NODE_ENV%' === 'development' || String(window.location.hostname).toLowerCase() === 'localhost' );
}


function getAPI() {

  // TODO
  return ( isDev() ? 'http://localhost:8000' : process.env.REACT_APP_BACKEND_ROUTE );

}

export { getAPI };