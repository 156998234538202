import * as React from 'react';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import './editSignIn.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default class EditSignIn extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      uid: props.session.uid,
      origSession: props.session,
      newSession: props.session,
      displayTime: '',
      disableUpdate: false,
      submitted: false
    }
    this.closeModal = props.closeModal.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleTypeSwitch = this.handleTypeSwitch.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.handleTimeUpdateStart = this.handleTimeUpdateStart.bind(this);
    this.handleTimeUpdateEnd = this.handleTimeUpdateEnd.bind(this);
  }

  componentDidMount() {
    this.updateTime();
  }

  updateTime() {
      const nowTime = dayjs(this.state.newSession.timeEnd);
      const time = dayjs(this.state.newSession.timeStart);
      const timeDifference = nowTime.diff(time, 'seconds');
  
      const seconds =((timeDifference % 60 == NaN ? '0' : timeDifference % 60) < 10 ? '0' + (timeDifference % 60 == NaN ? '00' : timeDifference % 60) :  (timeDifference % 60 == NaN ? '0' : timeDifference % 60));
      const minutes = ((Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) < 10 ? '0' + (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) : (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))));
      const hours = ((Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) < 10 ? '0' + (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) : (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))));
  
      if(timeDifference < 0) this.setState({ displayTime: `Invalid`, disableUpdate: true});
      
      else this.setState({ displayTime: `${hours}:${minutes}:${seconds}`, disableUpdate: false});


  
    return true;
  }

  
  handleUpdate() {
    this.setState({ submitted: true });
    
    axios.post(getAPI() + `/user/userUpdateSession` , {uid : encodeURIComponent(this.state.uid), origType: encodeURIComponent(this.state.origSession.signType), newType: encodeURIComponent(this.state.newSession.signType), newStartTime: encodeURIComponent(this.state.newSession.timeStart), newEndTime: encodeURIComponent(this.state.newSession.timeEnd), origID: encodeURIComponent(this.state.origSession.signID)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          //this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
         // this.setState({ loginRejected: true });
        } else {
          this.setState({ submitted: false });
          this.closeModal(null, this.state.newSession.timeStart,this.state.newSession.timeEnd);
        }
      });
      this.setState({ submitted: false });
  }

  handleTimeUpdateStart(event) {
    const newSession = this.state.newSession;

    this.setState({newSession: {...newSession, timeStart: event}},() => this.updateTime());

  }

  handleTimeUpdateEnd(event) {
    const newSession = this.state.newSession;

    this.setState({newSession: {...newSession, timeEnd: event}},() => this.updateTime())

  }
  
  handleTypeSwitch() {
    const { newSession } = this.state;
    this.setState({newSession: {...newSession, signType: newSession.signType === 'robotics' ? 'volunteer' : 'robotics'}});
  }

  render(){
    return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='modalBox'>
        <div className='modalOutline'>
          <div className='currentTime'>
            <div className='headerText'>
              Current
            </div>
            <div className='subText' onClick={this.handleTypeSwitch}>
              Type: {this.state.newSession.signType}
            </div>
            <div className='subText' >
              Time: {this.state.displayTime}
            </div>
            <div className='startEndText'>
              <div className='smallText'>
                <div className='underline'>
                  Start
                </div>
                <DateTimePicker value={dayjs(this.state.newSession.timeStart)} onChange={this.handleTimeUpdateStart}/>
              </div>
              <div className='smallText'>
                <div className='underline'>
                  End
                </div>
                <DateTimePicker value={dayjs(this.state.newSession.timeEnd)} onChange={this.handleTimeUpdateEnd}/>
              </div>
            </div>
          </div>
          { !this.state.disableUpdate &&
            <Button className='signInButton' variant="contained" size='small' color='secondary' onClick={this.handleUpdate}>Update</Button>
          }
           { this.state.disableUpdate &&
            <Button className='signInButton' variant="contained" size='small' color='secondary' onClick={this.handleUpdate} disabled>Update</Button>
          }
        </div>
      </div>
    </LocalizationProvider>
    )
  }

}