import * as React from 'react';
import './fullEventView.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue, update} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {Navigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { DataGrid } from '@mui/x-data-grid';
import { LineChart } from '@mui/x-charts/LineChart';


const columns = [
  { field: 'teamNumber', headerName: 'Team Number', width: 130 },
  { field: 'soloAvg', headerName: 'Solo Avg', width: 130, type: 'number' },
  { field: 'teleopSpeakerShotPercentage', headerName: 'Teleop Speaker %', width: 190 },
  { field: 'teleopSpeakerMade', headerName: 'Teleop Speaker', width: 170 },
  { field: 'teleopAmpScored', headerName: 'Teleop Amp', width: 170 },
  { field: 'autoScoredInSpeaker', headerName: 'Auto Speaker', width: 170 },
  { field: 'autoScoredInAmp', headerName: 'Auto Amp', width: 170 },
  { field: 'autoLeavesStartingLine', headerName: 'Auto Line %', width: 190 },
  { field: 'endgameClimbed', headerName: 'Climb %', width: 170 },
  { field: 'endgameClimbTime', headerName: 'Climb Time', width: 170 },
  { field: 'endgameHarmony', headerName: 'Climb Harmony %', width: 190 },
  { field: 'endgameRobotParked', headerName: 'Parked %', width: 130 },
  { field: 'endgameScoredTrap', headerName: 'Trap %', width: 130 },
  { field: 'robotMVP', headerName: 'MVP %', width: 130 },
  { field: 'robotPlayedDefense', headerName: 'Played Defense %', width: 190 },
  { field: 'robotPlayedDefenseRating', headerName: 'Played Defense Rating', width: 220 },
  { field: 'robotUnderDefense', headerName: 'Under Defense %', width: 190 },
  { field: 'robotUnderDefenseRating', headerName: 'Under Defense Rating', width: 210 },
];

dayjs.extend(utc);
const db = getDatabase();

export default class FullEventView extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      eventSelectedIndex: 0,
      eventList: [],
      teamNumber: sessionStorage.getItem('TEAMNUMBER'),
      showMatches: true,
      showPlayoff: false,
      showPractice: false,
      selectedEventData: [],
      teamData: [],
      todayDate : dayjs.utc(),
      navigateToTeam: false,
      teamNumberToNavigate: '',
      openChartsModal: false,
      chartFieldsDisplay: {
        autoLeavesStartingLine: false,
        autoNotePickupArray: false,
        autoScoredInAmp: false,
        autoScoredInSpeaker: false,
        endgameClimbed: false,
        endgameClimbTime: false,
        endgameHarmony: false,
        endgameRobotParked: false,
        endgameScoredTrap: false,
        teleopAmpScored: false,
        teleopSpeakerShotPercentage: false,
        robotMVP: false,
        robotPlayedDefense: false,
        robotPlayedDefenseRating: false,
        robotUnderDefense: false,
        robotUnderDefenseRating: false,
        robotHadIssues: false,
        robotHadIssuesRating: false,
        soloAvg: false,
        teleopSpeakerMade: false,
      },
      teamListSelected: [],
      teamDataSelected: [],
      matchDataChartXAxis: [],
      matchDataChartDataSeries: []
    }

    this.eventSelectDropdown = this.eventSelectDropdown.bind(this);
    this.handleEventSelect = this.handleEventSelect.bind(this);
    this.checkMatchTypeSelected = this.checkMatchTypeSelected.bind(this);
    this.updateMatchData = this.updateMatchData.bind(this);
    this.handleDataExport = this.handleDataExport.bind(this);
    this.handleOnCellClick = this.handleOnCellClick.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleChartData = this.handleChartData.bind(this);
    this.displayTeamList = this.displayTeamList.bind(this);

  }

  displayTeamList() {
    let teamList = this.state.teamListSelected;
    return(
      <div className='teamScoutQuickMatchSelect3'>
        {teamList.map((team, index) => (
          <FormControlLabel control={<Checkbox checked={team.selected}  onClick={() => this.setState({teamListSelected: teamList.map((team, i) => i === index ? {teamNumber: team.teamNumber, selected: !team.selected} : team)}, () => this.handleChartData())} />} label={team.teamNumber} />
        ))}
      </div>
    )
  }

  handleChartData(field) {

    let chartFieldsDisplay = {};

    if(field) {

    chartFieldsDisplay = {
        autoLeavesStartingLine: false,
        autoNotePickupArray: false,
        autoScoredInAmp: false,
        autoScoredInSpeaker: false,
        endgameClimbed: false,
        endgameClimbTime: false,
        endgameHarmony: false,
        endgameRobotParked: false,
        endgameScoredTrap: false,
        teleopAmpScored: false,
        teleopSpeakerShotPercentage: false,
        robotMVP: false,
        robotPlayedDefense: false,
        robotPlayedDefenseRating: false,
        robotUnderDefense: false,
        robotUnderDefenseRating: false,
        robotHadIssues: false,
        robotHadIssuesRating: false,
        soloAvg: false,
        teleopSpeakerMade: false,
    }
    
    chartFieldsDisplay[field] = true;

  } else {
    chartFieldsDisplay = this.state.chartFieldsDisplay;
  }

    let matchDataChartXAxis = [];
    let matchDataChartDataSeries = [];
    let teamDataSelected = this.state.teamDataSelected;
    let teamListSelected = this.state.teamListSelected;
    let highestMatchCount = 0;
    let chartDataPoints = [];


    for(let fields in chartFieldsDisplay) {
      if(chartFieldsDisplay[fields]) {
        chartDataPoints.push(fields);
      }
    }
  
    for(let team in teamListSelected) {
      if(teamListSelected[team].selected) {
       let tempObject = {data: [], label: ''};
       if(teamDataSelected[team].data.length > highestMatchCount) {
         highestMatchCount = teamDataSelected[team].data.length;
       }

        let matchData = teamDataSelected[team].data;

        for(let i = 0; i < matchData.length; i++) {
          for(let j = 0; j < chartDataPoints.length; j++) {
           switch(chartDataPoints[j]) {
            case 'autoLeavesStartingLine':
              tempObject.data.push(matchData[i].autoLeavesStartingLine);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'autoNotePickupArray':
              tempObject.data.push(matchData[i].autoNotePickup);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'autoScoredInAmp':
              tempObject.data.push(matchData[i].autoScoredInAmp);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'autoScoredInSpeaker':
              tempObject.data.push(matchData[i].autoScoredInSpeaker);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'endgameClimbed':
              tempObject.data.push(matchData[i].endgameClimbed);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'endgameClimbTime':
              tempObject.data.push(matchData[i].endgameClimbTime);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'endgameHarmony':
              tempObject.data.push(matchData[i].endgameHarmony);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'endgameRobotParked':
              tempObject.data.push(matchData[i].endgameRobotParked);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'endgameScoredTrap':
              tempObject.data.push(matchData[i].endgameScoredTrap);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'teleopAmpScored':
              tempObject.data.push(matchData[i].teleopAmpScored);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'teleopSpeakerShotPercentage':
              if(matchData[i].teleopSpeakerMade > 0) {
                tempObject.data.push((matchData[i].teleopSpeakerMade / matchData[i].teleopSpeakerTotal) * 100);
              } else {
                tempObject.data.push(0);
              }
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotMVP':
              tempObject.data.push(matchData[i].robotMVP);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotPlayedDefense':
              tempObject.data.push(matchData[i].robotPlayedDefense);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotPlayedDefenseRating':
              tempObject.data.push(matchData[i].robotPlayedDefenseRating);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotUnderDefense':
              tempObject.data.push(matchData[i].robotUnderDefense);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotUnderDefenseRating':
              tempObject.data.push(matchData[i].robotUnderDefenseRating);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotHadIssues':
              tempObject.data.push(matchData[i].robotHadIssues);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'robotHadIssuesRating':
              tempObject.data.push(matchData[i].robotHadIssuesRating);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'soloAvg':
              tempObject.data.push((3 * matchData[i].endgameClimbed) + (matchData[i].autoScoredInAmp * 2) + (matchData[i].autoScoredInSpeaker * 5) + (matchData[i].teleopAmpScored * 1) + (matchData[i].teleopSpeakerMade * 2));
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
            case 'teleopSpeakerMade':
              tempObject.data.push(matchData[i].teleopSpeakerMade);
              tempObject.label = teamDataSelected[team].teamNumber;
              break;
           }

          }
        }

        matchDataChartDataSeries.push(tempObject);
    }
  }

  for(let series in matchDataChartDataSeries) { 
    if(matchDataChartDataSeries[series].data.length < highestMatchCount) {
      for(let i = matchDataChartDataSeries[series].data.length; i < highestMatchCount; i++) {
        matchDataChartDataSeries[series].data.push(null);
      }
    }
  }

  for(let i = 0; i < highestMatchCount; i++) {
    matchDataChartXAxis.push(i + 1);
  }

  console.log(matchDataChartXAxis, matchDataChartDataSeries);

  this.setState({matchDataChartXAxis: matchDataChartXAxis, chartFieldsDisplay: chartFieldsDisplay, matchDataChartDataSeries: matchDataChartDataSeries});

}

  handleModal() {
    this.setState({openChartsModal: !this.state.openChartsModal});
  }

  handleOnCellClick(params) {
    if(params.field == "teamNumber") {
      this.setState({teamNumberToNavigate: params.row.teamNumber, navigateToTeam: true});
    }
  };


  handleDataExport() {
    axios.post(getAPI() + '/scouting/exportDataToSheet', {
      teamData: encodeURIComponent(JSON.stringify(this.state.teamData)),
      event: encodeURIComponent(this.state.eventList[this.state.eventSelectedIndex]),
    })
    .then((response) => {
      window.open(response.data.data);
    })
    .catch((error) => {
      console.log(error);
    });

  }

  eventSelectDropdown() {
    return(
      <div className='eventSelectDropdown'>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Event</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={this.state.eventSelectedIndex}
            label="Event"
            onChange={(event) => this.handleEventSelect(event)}
          >
            {this.state.eventList.map((event, index) => (
              <MenuItem value={index}>{event}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    )
  
  }

  handleEventSelect(event) {
    this.setState({eventSelectedIndex: event.target.value});
  
    onValue(ref(db, `scouting/2024/events/${this.state.eventList[event.target.value]}`), (snapshot) => {
      this.setState({selectedEventData: snapshot.val()}, () => this.updateMatchData());
      sessionStorage.setItem('EVENTNAME', snapshot.val().Name);
    });
  }

  checkMatchTypeSelected() {
    if(!this.state.showMatches && !this.state.showPractice && !this.state.showPlayoff) {
      this.setState({showMatches: true}, () => this.updateMatchData());
    } else {
      this.updateMatchData();
    }

  }

  updateMatchData() {

    let teamData = [];
    let teamCount = 0;
    let teamListSelected = [];
    let teamDataSelected = [];


    for(let team in this.state.selectedEventData.teams) {
      teamCount++;
      let matchList = [];
      if(this.state.showMatches) {
        for(let match in this.state.selectedEventData.teams[team].matches) {
          matchList.push(this.state.selectedEventData.teams[team].matches[match]);
        }
      }
      if(this.state.showPlayoff) {
       for(let match in this.state.selectedEventData.teams[team].playoffMatches) {
          matchList.push(this.state.selectedEventData.teams[team].playoffMatches[match]);
        }
      }
      if(this.state.showPractice) {
        for(let match in this.state.selectedEventData.teams[team].practiceMatches) {
          matchList.push(this.state.selectedEventData.teams[team].practiceMatches[match]);
        }
      }

    let matchCounter = 0;
    let endgameCounter = 0;
    let endgameSubmatchCounter = 0;
    let autoLeavesStartingLine = 0;
    let autoNotePickupArray = [0,0,0,0,0,0,0,0];
    let autoScoredInAmp = 0;
    let autoScoredInSpeaker = 0;
    let endgameClimbTime = 0;
    let endgameClimbed = 0;
    let endgameHarmony = 0;
    let endgameRobotParked = 0;
    let endgameScoredTrap = 0;
    let teleopAmpScored = 0;
    let teleopSpeakerMade = 0;
    let teleopSpeakerTotal= 0;
    let robotMVP = 0;
    let teleopSpeakerShotPercentage = 0;
    let robotMatchNotes = [];
    let robotPlayedDefense = 0;
    let robotUnderDefense = 0;
    let robotUnderDefenseRating = 0;
    let robotPlayedDefenseRating = 0;
    let shotsMade = [];
    let shotsMissed = [];
    let pickupList = [];
    let robotHadIssues = 0;
    let robotHadIssuesRating = 0;

    let matchAutoLeavesStartingLine = 0;
    let matchAutoNoteArray = [0,0,0,0,0,0,0,0];
    let matchAutoScoredInAmp = 0;
    let matchAutoScoredInSpeaker = 0;
    let matchEndgameClimbed = 0;
    let matchEndgameClimbTime = 0;
    let matchEndgameRobotParked = 0;
    let matchEndgameScoredTrap = 0;
    let matchEndgameHarmony = 0;
    let matchTeleopAmpScored = 0;
    let matchTeleopSpeakerMade = 0;
    let matchTeleopSpeakerTotal = 0;
    let matchRobotMVP = false;
    let matchRobotPlayedDefense = 0;
    let matchRobotUnderDefense = 0;
    let matchRobotHadIssues = 0;
    let matchRobotPlayedDefenseRating = 0;
    let matchRobotUnderDefenseRating = 0;
    let matchRobotHadIssuesRating = 0;

    let matchDataArray = [];


    for(let match in matchList) {
      let submatchCounter = 0;
      let robotMVPFound = false;
      let robotPlayedDefenseCounter = 0;
      let robotUnderDefenseCounter = 0;
      let robotHadIssuesCounter = 0;
      matchCounter++;

      matchAutoLeavesStartingLine = 0;
      matchAutoNoteArray = [0,0,0,0,0,0,0,0];
      matchAutoScoredInAmp = 0;
      matchAutoScoredInSpeaker = 0;
      matchEndgameClimbed = 0;
      matchEndgameClimbTime = 0;
      matchEndgameRobotParked = 0;
      matchEndgameScoredTrap = 0;
      matchEndgameHarmony = 0;
      matchTeleopAmpScored = 0;
      matchTeleopSpeakerMade = 0;
      matchTeleopSpeakerTotal = 0;
      matchRobotMVP = false;
      matchRobotPlayedDefense = 0;
      matchRobotUnderDefense = 0;
      matchRobotHadIssues = 0;
      matchRobotPlayedDefenseRating = 0;
      matchRobotUnderDefenseRating = 0;
      matchRobotHadIssuesRating = 0;

      let matchData = {
        autoLeavesStartingLine: 0,
        autoNotePickup: 0,
        autoScoredInAmp: 0,
        autoScoredInSpeaker: 0,
        endgameClimbed: 0,
        endgameClimbTime: 0,
        endgameRobotParked: 0,
        endgameScoredTrap: 0,
        endgameHarmony: 0,
        teleopAmpScored: 0,
        teleopSpeakerMade: 0,
        teleopSpeakerTotal: 0,
        robotMVP: false,
        robotPlayedDefense: 0,
        robotUnderDefense: 0,
        robotHadIssues: 0,
        robotPlayedDefenseRating: 0,
        robotUnderDefenseRating: 0,
        robotHadIssuesRating: 0,
      }


      for(let submatch in matchList[match]) {
        submatchCounter++;
        let data = matchList[match][submatch];
        matchAutoLeavesStartingLine += data.autoLeavesStartingLine ? 1 : 0;

        for(let i = 0; i < data.autoNotePickupArray.length; i++) {
          if(data.autoNotePickupArray[i] == true) {
            matchAutoNoteArray[i]++;
          }
        }

        matchAutoScoredInAmp += data.autoScoredInAmp;

        matchAutoScoredInSpeaker += data.autoScoredInSpeaker;

        matchEndgameClimbed += data.endgameClimbed ? 1 : 0;

        if(data.endgameClimbTime) {
          matchEndgameClimbTime += Number(data.endgameClimbTime);
          endgameSubmatchCounter++;
        }

        matchEndgameHarmony += data.endgameHarmony ? 1 : 0;

        matchEndgameRobotParked += data.endgameRobotParked ? 1 : 0;
        
        matchEndgameScoredTrap += data.endgameScoredTrap ? 1 : 0;

        matchTeleopAmpScored += data.teleopAmpScored;

        if(data.shotsMade) {
          matchTeleopSpeakerMade += data.shotsMade.length;
          matchTeleopSpeakerTotal += data.shotsMade.length;
        }
        if(data.shotsMissed) {
          matchTeleopSpeakerTotal += data.shotsMissed.length;
        }
        if(data.robotMVP) {
          matchRobotMVP = true;
        }

        matchRobotPlayedDefense += data.robotPlayedDefense ? 1 : 0;
        matchRobotUnderDefense += data.robotUnderDefense ? 1 : 0;
        matchRobotHadIssues += data.robotHadIssues ? 1 : 0;

        if(data.robotPlayedDefense) { 
          robotPlayedDefenseCounter++;
          matchRobotPlayedDefenseRating += data.robotDefenseRating;
        }
        if(data.robotUnderDefense) {
          robotUnderDefenseCounter++;
          matchRobotUnderDefenseRating += data.robotUnderDefenseRating;
        }
        if(data.robotHadIssues) {
          robotHadIssuesCounter++;
          matchRobotHadIssuesRating += data.robotHadIssuesRating;
        }
        if(data.shotsMade) {
          shotsMade = shotsMade.concat(data.shotsMade);
        }
        if(data.shotsMissed) {
          shotsMissed = shotsMissed.concat(data.shotsMissed);
        }
        if(data.pickupList) {
          pickupList = pickupList.concat(data.pickupList);
        }

        if(data.matchFinalNotes != '') {
          if(data.personScouting) {
            robotMatchNotes.push({ title: `Match_${data.matchNumber}_Submatch_${submatch}_By_${data.personScouting}_Team_${data.teamScouting}`, text: data.matchFinalNotes});
          } else {
            robotMatchNotes.push({ title: `Match_${data.matchNumber}_Submatch_${submatch}`, text: data.matchFinalNotes});
          }
        }

      }
      autoLeavesStartingLine += (matchAutoLeavesStartingLine / submatchCounter);
      matchData.autoLeavesStartingLine = (matchAutoLeavesStartingLine / submatchCounter);

      for(let i = 0; i < matchAutoNoteArray.length; i++) {
        autoNotePickupArray[i] += (matchAutoNoteArray[i] / submatchCounter);
        if(matchAutoNoteArray[i] > 0) {
          matchData.autoNotePickup += 1;
        }
      }
      matchData.autoNotePickup = (matchData.autoNotePickup / submatchCounter);

      autoScoredInAmp += (matchAutoScoredInAmp / submatchCounter);
      matchData.autoScoredInAmp = (matchAutoScoredInAmp / submatchCounter);

      autoScoredInSpeaker += (matchAutoScoredInSpeaker / submatchCounter);
      matchData.autoScoredInSpeaker = (matchAutoScoredInSpeaker / submatchCounter);

      endgameClimbed += (matchEndgameClimbed / submatchCounter);
      matchData.endgameClimbed = (matchEndgameClimbed / submatchCounter);

      if(endgameSubmatchCounter > 0) {
        endgameClimbTime += (matchEndgameClimbTime / endgameSubmatchCounter);
        matchData.endgameClimbTime = (matchEndgameClimbTime / endgameSubmatchCounter);
        endgameHarmony += (matchEndgameHarmony / endgameSubmatchCounter);
        matchData.endgameHarmony = (matchEndgameHarmony / endgameSubmatchCounter);
        endgameCounter++;
      }

      endgameRobotParked += (matchEndgameRobotParked / submatchCounter);
      matchData.endgameRobotParked = (matchEndgameRobotParked / submatchCounter);

      endgameScoredTrap += (matchEndgameScoredTrap / submatchCounter);
      matchData.endgameScoredTrap = (matchEndgameScoredTrap / submatchCounter);

      teleopAmpScored += (matchTeleopAmpScored / submatchCounter);
      matchData.teleopAmpScored = (matchTeleopAmpScored / submatchCounter);

      teleopSpeakerMade += (matchTeleopSpeakerMade / submatchCounter);
      matchData.teleopSpeakerMade = (matchTeleopSpeakerMade / submatchCounter);

      teleopSpeakerTotal += (matchTeleopSpeakerTotal / submatchCounter);
      matchData.teleopSpeakerTotal = (matchTeleopSpeakerTotal / submatchCounter);

      robotMVP += matchRobotMVP ? 1 : 0;
      matchData.robotMVP = matchRobotMVP;

      robotPlayedDefense += (matchRobotPlayedDefense / submatchCounter);
      matchData.robotPlayedDefense = (matchRobotPlayedDefense / submatchCounter);
      robotUnderDefense += (matchRobotUnderDefense / submatchCounter);
      matchData.robotUnderDefense = (matchRobotUnderDefense / submatchCounter);
      robotHadIssues += (matchRobotHadIssues / submatchCounter);
      matchData.robotHadIssues = (matchRobotHadIssues / submatchCounter);

      if(robotPlayedDefenseCounter > 0) {
        robotPlayedDefenseRating += (matchRobotPlayedDefenseRating / robotPlayedDefenseCounter);
        matchData.robotPlayedDefenseRating = (matchRobotPlayedDefenseRating / robotPlayedDefenseCounter);
      }
      if(robotUnderDefenseCounter > 0) {
        robotUnderDefenseRating += (matchRobotUnderDefenseRating / robotUnderDefenseCounter);
        matchData.robotUnderDefenseRating = (matchRobotUnderDefenseRating / robotUnderDefenseCounter);
      }
      if(robotHadIssuesCounter > 0) {
        robotHadIssuesRating += (matchRobotHadIssuesRating / robotHadIssuesCounter);
        matchData.robotHadIssuesRating = (matchRobotHadIssuesRating / robotHadIssuesCounter);
      }

      matchDataArray.push(matchData);

    }

    autoLeavesStartingLine = (autoLeavesStartingLine / matchCounter);

    for(let i = 0; i < autoNotePickupArray.length; i++) {
      autoNotePickupArray[i] = (autoNotePickupArray[i] / matchCounter) * 100;
    }

    autoScoredInAmp = (autoScoredInAmp / matchCounter);

    autoScoredInSpeaker = (autoScoredInSpeaker / matchCounter);
    endgameClimbed = (endgameClimbed / matchCounter);
    if(endgameCounter > 0) {
      endgameClimbTime = (endgameClimbTime / endgameCounter);
      endgameHarmony = (endgameHarmony / endgameCounter);
    }
    endgameRobotParked = (endgameRobotParked / matchCounter);
    endgameScoredTrap = (endgameScoredTrap / matchCounter);
    teleopAmpScored = (teleopAmpScored / matchCounter);
    teleopSpeakerMade = (teleopSpeakerMade / matchCounter);
    teleopSpeakerTotal = (teleopSpeakerTotal / matchCounter);
    robotMVP = (robotMVP / matchCounter) * 100;
    teleopSpeakerShotPercentage = (teleopSpeakerMade / teleopSpeakerTotal) >= 0 ? (teleopSpeakerMade / teleopSpeakerTotal) : 0;
    if(robotPlayedDefense > 0) {
      robotPlayedDefenseRating = robotPlayedDefenseRating / robotPlayedDefense;
      robotPlayedDefense = (robotPlayedDefense / matchCounter) * 100;
    }
    if(robotUnderDefense > 0) {
      robotUnderDefenseRating = robotUnderDefenseRating / robotUnderDefense;
      robotUnderDefense = (robotUnderDefense / matchCounter) * 100;
    }
    if(robotHadIssues > 0) {
      console.log(robotHadIssuesRating, robotHadIssues);
      robotHadIssuesRating = robotHadIssuesRating / robotHadIssues;
      robotHadIssues = (robotHadIssues / matchCounter) * 100;
      console.log(robotHadIssuesRating, robotHadIssues);
    }
    const soloAvg = ((3 * endgameClimbed) + (autoScoredInAmp * 2) + (autoScoredInSpeaker * 5) + (teleopAmpScored * 1) + (teleopSpeakerMade * 2)).toFixed(2);

    teleopSpeakerShotPercentage = (teleopSpeakerShotPercentage * 100);

      teamData.push({
        id: teamCount,
        teamNumber: team,
        autoLeavesStartingLine: (autoLeavesStartingLine * 100).toFixed(0) == "NaN" ? 0 : (autoLeavesStartingLine * 100).toFixed(0),
        autoScoredInAmp: autoScoredInAmp.toFixed(2) == "NaN" ? 0 : autoScoredInAmp.toFixed(2),
        autoScoredInSpeaker: autoScoredInSpeaker.toFixed(2) == "NaN" ? 0 : autoScoredInSpeaker.toFixed(2),
        endgameClimbed: (endgameClimbed * 100).toFixed(0)  == "NaN" ? 0 : (endgameClimbed * 100).toFixed(0),
        endgameClimbTime: endgameClimbTime.toFixed(2),
        endgameHarmony: (endgameHarmony * 100).toFixed(0),
        endgameRobotParked: (endgameRobotParked * 100).toFixed(0) == "NaN" ? 0 : (endgameRobotParked * 100).toFixed(0),
        endgameScoredTrap: (endgameScoredTrap * 100).toFixed(0) == "NaN" ? 0 : (endgameScoredTrap * 100).toFixed(0),
        teleopAmpScored: teleopAmpScored.toFixed(2) == "NaN" ? 0 : teleopAmpScored.toFixed(2),
        teleopSpeakerShotPercentage: teleopSpeakerShotPercentage.toFixed(0),
        robotMVP: robotMVP.toFixed(0) == "NaN" ? 0 : robotMVP.toFixed(0),
        robotPlayedDefense: robotPlayedDefense.toFixed(0),
        robotPlayedDefenseRating: robotPlayedDefenseRating.toFixed(2),
        robotUnderDefense: robotUnderDefense.toFixed(0),
        robotUnderDefenseRating: robotUnderDefenseRating.toFixed(2),
        soloAvg: soloAvg == "NaN" ? 0 : soloAvg,
        teleopSpeakerMade: teleopSpeakerMade.toFixed(2) == "NaN" ? 0 : teleopSpeakerMade.toFixed(2),
      });

      teamListSelected.push({teamNumber: team, selected: false});
      teamDataSelected.push({teamNumber: team, data: matchDataArray});

    }

    this.setState({teamData: teamData, teamListSelected: teamListSelected, teamDataSelected: teamDataSelected});

  }

  componentDidMount(){
    onValue(ref(db, `scouting/2024/teams/${this.state.teamNumber}`), (snapshot) => {
      if(snapshot.val() != null) {
  
        let closestEventIndex = 0;
        let closestEventDateDiff;
        let eventData = {};
        for(let event in snapshot.val().Events) {
          let eventStartDate;
  
          onValue(ref(db, `scouting/2024/events/${snapshot.val().Events[event]}`), (snapshot) => {
            eventStartDate = dayjs.utc(snapshot.val().StartDate);
            let eventDateDiff = eventStartDate.diff(this.state.todayDate, 'days');
            if(eventDateDiff < closestEventDateDiff || (closestEventDateDiff == undefined || closestEventDateDiff <= -6)) {
              closestEventDateDiff = eventDateDiff;
              closestEventIndex = event;
              eventData = snapshot.val();
            }
            this.setState({eventSelectedIndex: closestEventIndex, selectedEventData: eventData}, () => this.updateMatchData());
            sessionStorage.setItem('EVENTNAME', eventData.Name);
          });
        }
        this.setState({eventList: snapshot.val().Events});
      }
    });
  }


render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='fullEventViewPage'>
          <SideMenu/>
          {this.state.navigateToTeam && (
            <Navigate to={`/TeamScout/${this.state.teamNumberToNavigate}`} replace={true} />
          )
          }
          <Modal className='modalScreen' open={(this.state.openChartsModal)} onClose={this.handleModal}>
            <div className='modalScreenAlignCharts'>
              <div className='MatchScoutingPageFormFields2'>
                <div className='scoutingPageFields'>
                  <div className='teamScoutAtAGlanceTitle1'>
                    Match Type Display
                  </div>
                  <div className='teamScoutQuickMatchSelect'>
                    <div className='teamScoutCheckboxAlign2'>
                      <FormControlLabel control={<Checkbox checked={this.state.showMatches}  onClick={() => this.setState({showMatches: !this.state.showMatches}, () => this.checkMatchTypeSelected())} />} label="Qualification" />
                      <FormControlLabel control={<Checkbox checked={this.state.showPlayoff}  onClick={() => this.setState({showPlayoff: !this.state.showPlayoff}, () => this.checkMatchTypeSelected())} />} label="Playoff" />
                      <FormControlLabel control={<Checkbox checked={this.state.showPractice}  onClick={() => this.setState({showPractice: !this.state.showPractice}, () => this.checkMatchTypeSelected())} />} label="Practice" />
                    </div>
                  </div>
                  <div className='teamScoutQuickCheckboxes'>
                    <div className='teamScoutAtAGlanceTitle1'>
                      Display Fields
                    </div>
                    <div className='teamScoutQuickMatchSelect1'>
                      <div className='teamScoutCheckboxAlign5'>
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.soloAvg}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, soloAvg: !this.state.chartFieldsDisplay.soloAvg}}, () => this.handleChartData('soloAvg'))} />} label="Solo Average" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoLeavesStartingLine}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoLeavesStartingLine: !this.state.chartFieldsDisplay.autoLeavesStartingLine}}, () => this.handleChartData('autoLeavesStartingLine'))} />} label="Auto Line" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoNotePickupArray}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoNotePickupArray: !this.state.chartFieldsDisplay.autoNotePickupArray}}, () => this.handleChartData('autoNotePickupArray'))} />} label="Auto Pickup Count" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoScoredInAmp}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoScoredInAmp: !this.state.chartFieldsDisplay.autoScoredInAmp}}, () => this.handleChartData('autoScoredInAmp'))} />} label="Auto Amp" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoScoredInSpeaker}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoScoredInSpeaker: !this.state.chartFieldsDisplay.autoScoredInSpeaker}}, () => this.handleChartData('autoScoredInSpeaker'))} />} label="Auto Speaker" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameClimbed}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameClimbed: !this.state.chartFieldsDisplay.endgameClimbed}}, () => this.handleChartData('endgameClimbed'))} />} label="Climbed" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameClimbTime}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameClimbTime: !this.state.chartFieldsDisplay.endgameClimbTime}}, () => this.handleChartData('endgameClimbTime'))} />} label="Climb Time" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameHarmony}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameHarmony: !this.state.chartFieldsDisplay.endgameHarmony}}, () => this.handleChartData('endgameHarmony'))} />} label="Had Harmony" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameRobotParked}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameRobotParked: !this.state.chartFieldsDisplay.endgameRobotParked}}, () => this.handleChartData('endgameRobotParked'))} />} label="Robot Parked" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameScoredTrap}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameScoredTrap: !this.state.chartFieldsDisplay.endgameScoredTrap}}, () => this.handleChartData('endgameScoredTrap'))} />} label="Scored Trap" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopAmpScored}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopAmpScored: !this.state.chartFieldsDisplay.teleopAmpScored}}, () => this.handleChartData('teleopAmpScored'))} />} label="Teleop Amp" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopSpeakerMade}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopSpeakerMade: !this.state.chartFieldsDisplay.teleopSpeakerMade}}, () => this.handleChartData('teleopSpeakerMade'))} />} label="Teleop Speaker" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopSpeakerShotPercentage}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopSpeakerShotPercentage: !this.state.chartFieldsDisplay.teleopSpeakerShotPercentage}}, () => this.handleChartData('teleopSpeakerShotPercentage'))} />} label="Speaker Shot %" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotMVP}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotMVP: !this.state.chartFieldsDisplay.robotMVP}}, () => this.handleChartData('robotMVP'))} />} label="Robot MVP" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotPlayedDefense}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotPlayedDefense: !this.state.chartFieldsDisplay.robotPlayedDefense}}, () => this.handleChartData('robotPlayedDefense'))} />} label="Robot Played Defense" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotPlayedDefenseRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotPlayedDefenseRating: !this.state.chartFieldsDisplay.robotPlayedDefenseRating}}, () => this.handleChartData('robotPlayedDefenseRating'))} />} label="Robot Played Defense Rating" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotUnderDefense}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotUnderDefense: !this.state.chartFieldsDisplay.robotUnderDefense}}, () => this.handleChartData('robotUnderDefense'))} />} label="Robot Under Defense" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotUnderDefenseRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotUnderDefenseRating: !this.state.chartFieldsDisplay.robotUnderDefenseRating}}, () => this.handleChartData('robotUnderDefenseRating'))} />} label="Robot Under Defense Rating" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotHadIssues}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotHadIssues: !this.state.chartFieldsDisplay.robotHadIssues}}, () => this.handleChartData('robotHadIssues'))} />} label="Robot Had Issues" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotHadIssuesRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotHadIssuesRating: !this.state.chartFieldsDisplay.robotHadIssuesRating}}, () => this.handleChartData('robotHadIssuesRating'))} />} label="Robot Had Issues Rating" />
                      </div>
                    </div>
                  </div>
                  <div className='teamScoutQuickCheckboxes'>
                    <div className='teamScoutAtAGlanceTitle1'>
                      Team Select
                    </div>
                    {this.displayTeamList()}
                  </div>
                  <div className='teamScoutMatchChartDisplay'>
                    <LineChart
                      xAxis={[{ scaleType: 'point', data: this.state.matchDataChartXAxis }]}
                      series={this.state.matchDataChartDataSeries}
                      width={window.innerWidth < 400 ? 400 : window.innerWidth > 1000 ? 1000 : window.innerWidth}
                      height={300}
                    />
                  </div>
                </div>
              </div>
              <div className='modalScreenButtons'>
                <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={this.handleModal}>Close</Button>
              </div>
            </div>
          </Modal>
          {this.eventSelectDropdown()}
          <div className='teamScoutQuickCheckboxes'>
          <div className='teamScoutAtAGlanceTitle1'>
            Match Type Display
          </div>
          <div className='teamScoutQuickMatchSelect'>
            <div className='teamScoutCheckboxAlign2'>
              <FormControlLabel control={<Checkbox checked={this.state.showMatches}  onClick={() => this.setState({showMatches: !this.state.showMatches}, () => this.checkMatchTypeSelected())} />} label="Qualification" />
              <FormControlLabel control={<Checkbox checked={this.state.showPlayoff}  onClick={() => this.setState({showPlayoff: !this.state.showPlayoff}, () => this.checkMatchTypeSelected())} />} label="Playoff" />
              <FormControlLabel control={<Checkbox checked={this.state.showPractice}  onClick={() => this.setState({showPractice: !this.state.showPractice}, () => this.checkMatchTypeSelected())} />} label="Practice" />
            </div>
          </div>
          <div className='teamScoutAtAGlanceDataGridBox'>
            <DataGrid
              rows={this.state.teamData}
              columns={columns}
              onCellClick={this.handleOnCellClick}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
            />
          </div>
          <div className='dataViewButtonExport'>
            <Button className='dataViewButtonExport' variant="contained" size='small' color='editSign' onClick={this.handleDataExport}>Export</Button>
          </div>
          <div className='dataViewButtonExport'>
            <Button className='dataViewButtonExport' variant="contained" size='small' color='secondary' onClick={this.handleModal}>Charts</Button>
          </div>
        </div>
        </div>
      </div>
    </div>
    )
  }
}