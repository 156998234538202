import * as React from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import './signAllInOut.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';

dayjs.extend(utc);
const db = getDatabase();

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export default class SignAllInOut extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      userList: [],
      teamNumber: sessionStorage.getItem('TEAMNUMBER'),
      typeSwitch: false
    }

    this.handleCheck = this.handleCheck.bind(this);
    this.updateTime  = this.updateTime.bind(this);
    this.teamUserList = this.teamUserList.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.teamUpdate = this.teamUpdate.bind(this);
    setInterval(this.updateTime, 1000);

  }

  handleSignIn(event,userArrayID) {
    event.preventDefault();
    const userList = this.state.userList;
    let arrayID = 0;
    for(let user in userList) {
      if(userList[user].uid == userArrayID.uid) {
        arrayID = user;
        break;
      }
    }
    const user = userList[arrayID];
    console.log(userArrayID);

    if(!user.signedIn) {
      axios.post(getAPI() + `/user/userSignIn` , {uid: encodeURIComponent(user.uid), type: encodeURIComponent(this.state.typeSwitch ? 'Volunteer': 'Robotics')})
        .then(res => {
          const data = res;
          if(data.status != 200) {
            //this.setState({ loginFailedToSubmit: true });
          }
          else if(!data.data.success) {
            //this.setState({ loginRejected: true });
          } else {
            //this.setState({ submitted: false });
            userList[arrayID].signedIn = !userList[arrayID].signedIn;
            this.setState({
             userList
            });
          }
        });
      } else {
        axios.post(getAPI() + `/user/userSignOut` , {uid: encodeURIComponent(user.uid)})
        .then(res => {
          const data = res;
          if(data.status != 200) {
            //this.setState({ loginFailedToSubmit: true });
          }
          else if(!data.data.success) {
            //this.setState({ loginRejected: true });
          } else {
            //this.setState({ submitted: false });
            
            userList[arrayID].signedIn = !userList[arrayID].signedIn;
            this.setState({
             userList
            });
          }
        });
      }
      this.teamUpdate();
  }

  handleCheck = (event) => {
    this.setState({typeSwitch: event.target.checked});
  };  

  updateTime() {
    let userList = this.state.userList;

    for(let user in userList) {
      const nowTime = dayjs();
      const time = userList[user].recentSignIn;
      const timeDifference = nowTime.diff(time, 'seconds');
      if(!timeDifference > 0) {
        userList[user].currentTime = '00:00:00';
        continue;
      }
  
      const seconds =((timeDifference % 60 == NaN ? '0' : timeDifference % 60) < 10 ? '0' + (timeDifference % 60 == NaN ? '00' : timeDifference % 60) :  (timeDifference % 60 == NaN ? '0' : timeDifference % 60));
      const minutes = ((Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) < 10 ? '0' + (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) : (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))));
      const hours = ((Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) < 10 ? '0' + (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) : (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))));
  
      userList[user].currentTime = `${hours}:${minutes}:${seconds}`;

    }

    this.setState({userList});
  
    return true;
  }

  teamUserList() {
    const userList = this.state.userList;
    return (
      <div className='allUserSignBox'>
        {userList.map(user => (
          <div className={user.signedIn ? 'userSignBoxSignedIn' : 'userSignBox'}>
            <div className='userNameText'>
              {user.name}
            </div>
            {(!user.signedIn && !this.state.typeSwitch) &&
              <Button className='signInButton' variant="contained" size='small' color='signIn' onClick={(event) => this.handleSignIn(event,user)}>Sign In Robotics</Button>
            }
            {((user.signedIn && !this.state.typeSwitch) && user.signType == 'robotics') &&
              <div className='alignSessionButton'>
                <div className='sessionText'>
                    Current Time: 
                  <div className='sessionHourText'>
                    {user.currentTime}
                  </div>
                </div>
                <Button className='signInButton' variant="contained" size='small' color='signOut' onClick={(event) => this.handleSignIn(event,user)}>Sign Out Robotics</Button>
              </div>
            }
            {((user.signedIn && this.state.typeSwitch) && user.signType == 'robotics') &&
              <div className='alignSessionButton'>
                <div className='sessionText'>
                    Current Time: 
                  <div className='sessionHourText'>
                    {user.currentTime}
                  </div>
                </div>
                <Button className='signInButton' variant="contained" size='small' color='signOut' disabled onClick={(event) => this.handleSignIn(event,user)}>WRONG SIGN TYPE</Button>
              </div>
            }
            {(!user.signedIn && this.state.typeSwitch) &&
              <Button className='signInButton' variant="contained" size='small' color='signIn' onClick={(event) => this.handleSignIn(event,user)}>Sign In Volunteer</Button>
            }
            {((user.signedIn && this.state.typeSwitch) && user.signType == 'Volunteer') &&
              <div className='alignSessionButton'>
                <div className='sessionText'>
                    Current Time:
                  <div className='sessionHourText'>
                    {user.currentTime}
                  </div>
                </div>
                <Button className='signInButton' variant="contained" size='small' color='signOut' onClick={(event) => this.handleSignIn(event,user)}>Sign Out Volunteer</Button>
              </div>
            }
            {((user.signedIn && !this.state.typeSwitch) && user.signType == 'Volunteer') &&
              <div className='alignSessionButton'>
                <div className='sessionText'>
                    Current Time:
                  <div className='sessionHourText'>
                    {user.currentTime}
                  </div>
                </div>
                <Button className='signInButton' variant="contained" size='small' color='signOut'  disabled onClick={(event) => this.handleSignIn(event,user)}>WRONG SIGN TYPE</Button>
              </div>
            }
          </div>
        ))}
    </div>
    )
  }

  teamUpdate() {
    axios.post(getAPI() + `/admin/adminSignStatusTeam` , {teamNumber : encodeURIComponent(this.state.teamNumber)})
    .then(res => {
      const data = res;
      if(data.status != 200) {

      }
      else if(!data.data.success) {

      } else {
        let userList = data.data.userList;
        this.setState({userList}, ()=>{this.updateTime()});
      }
    });
  }
  

  componentDidMount() {
    this.teamUpdate();
  }

render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='signAllInOutPage'>
          <SideMenu/>
          <div className='switchBox'>
            <div className='labelText'>
              Sign In Type
            </div>
            <Switch size="medium" {...label} checked={this.state.typeSwitch} onChange={this.handleCheck} color="secondary" />
            {this.state.typeSwitch &&
              <div className='labelText'>
                Sign In As - Volunteer
              </div>
            }
            {!this.state.typeSwitch &&
              <div className='labelText'>
                Sign In As - Robotics
              </div>
            }
          </div>
          {this.teamUserList()}
        </div>
      </div>
    </div>
    )
  }
}