const env = require('env-var');
const { initializeApp } = require('firebase/app');

console.log('Loading Firebase...');

let firebaseConfig = false;

try {
  firebaseConfig = env.get('REACT_APP_FIREBASE_CONFIG').required().asJsonObject();
} catch(error) {
  console.log('Error was had at firebaseConfig env' , error);
}

if(firebaseConfig) {
  console.log('Connecting to Firebase...');
  // Initialize Firebase
  try {
    const firebase = initializeApp(firebaseConfig);

    console.log('Connected to Firebase');
    module.exports = {firebase};

  } catch(error) {
    console.log('Failed to connect to firebase, Auth Reject:' , error);
  }


} else {
  console.log('Failed to connect to firebase, No Config found');
}
