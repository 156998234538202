import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './passwordReset.css';
import '../../index.css';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getAPI } from "../../common";

export default class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      email: '',
      returnToLogin:false
    }

    this.handleReturn = this.handleReturn.bind(this);
    this.handleEmail= this.handleEmail.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    };

  handleTextFieldChange(event) {
    console.log(event);
    const {id, value} = event.target;
     this.setState({
        [id]:value
      });
  }

  handleReturn() {
    this.setState({returnToLogin: true});
  }

  handleEmail(){
    axios.post(getAPI() +`/user/userForgotPassword`,{email:encodeURIComponent(this.state.email)})
    this.handleReturn()
  }

render(){
    return(
    <div className='fullScreen'>
      {this.state.returnToLogin && (
          <Navigate to="/Login" replace={true} />
        )}
      <div className="globalBackground">
        <div className='passwordResetPage'>
        <img className="teamLogoImage" src="resources/Obsidian_Loader.png" alt="Login Page Logo" />
          <div className='globalForm'>
           <div className='formBorderBufferReset'>
                <TextField
                  required
                  id="email"
                  label="Email"
                  variant="standard"
                  color="secondary"
                  value={this.state.email}
                  onChange={this.handleTextFieldChange} 
                />
             <div className='buffer'/>  
             <div className='buttons'>
             <Button className='resetButton' variant="contained" size='small' color='secondary' onClick={this.handleEmail}>Reset</Button>
             <Button className='resetButton' variant="contained" size='small' color='secondary' onClick={this.handleReturn}>Back</Button> 
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}