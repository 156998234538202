import * as React from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import './signInOut.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import EditSignIn from '../../components/editSignIn/editSignIn';
import SideMenu from '../../components/sideMenu/sideMenu';

dayjs.extend(utc);
const db = getDatabase();

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

export default class SignInOut extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      user: {
        name: sessionStorage.getItem('NAME'),
        currentHourTotalRobotics: '',
        currentHourTotalVolunteer: '',
        lastSignIn: '',
        lastSignTime: '',
        currentSignTime: '',
        currentSignedIn: false,
        uid: sessionStorage.getItem('FIREBASE_UID'),
        recentSignIn: '',
        lastSignStart: '',
        lastSignEnd: '',
        lastSignType: '',
        lastSignID: ''
      },
      typeSwitch: false,
      modalOpen: false,
      submitted: false
    }

    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.updateTime  = this.updateTime.bind(this);
    setInterval(this.updateTime, 1000);
  }
  

componentDidMount() {

  const { user } = this.state;

  const firebaseUserLastSign = ref(db, "/users/" + sessionStorage.getItem('FIREBASE_UID')); // Get Recent Sign Out
  onValue(firebaseUserLastSign, (snapshot) => {
    let currentRTime = '';
    let currentVTime = '';
    let currentTTime = '';
    let typeSwitchValue = false;
    let lastSignStart = '';
    let lastSignEnd = '';
    let lastSignType = '';
    let lastSignID = '';

    axios.post(getAPI() + `/user/userGetTimeDetails` , {uid : encodeURIComponent(user.uid)})
    .then(res => {
      const data = res;
      if(data.status != 200) {
        //this.setState({ loginFailedToSubmit: true });
      }
      else if(!data.data.success) {
        //this.setState({ loginRejected: true });
      } else {
        
        currentRTime = data.data.roboticsTime;
        currentVTime = data.data.volunteerTime;
        currentTTime = data.data.recentTime;
        typeSwitchValue = data.data.vType;
        lastSignStart = data.data.lastSignStart;
        lastSignEnd = data.data.lastSignEnd;
        lastSignType = data.data.lastSignType;
        lastSignID = data.data.lastSignID;

        this.setState({typeSwitch: typeSwitchValue, user: {  ...user, lastSignID, lastSignType, lastSignEnd, lastSignStart, currentHourTotalRobotics: currentRTime, currentHourTotalVolunteer: currentVTime, lastSignTime: currentTTime,currentSignedIn: snapshot.val()['signedIn'], lastSignIn: dayjs(snapshot.val()['recentSignOut']).format('MM/DD/YYYY'), recentSignIn: dayjs(snapshot.val()['recentSignIn'])}}, ()=>{this.updateTime()});

      }
    });

  });

}

updateTime() {
  if(this.state.user.currentSignedIn) {
    const nowTime = dayjs();
    const time = this.state.user.recentSignIn;
    const timeDifference = nowTime.diff(time, 'seconds');
    const { user } = this.state;

    const seconds =((timeDifference % 60 == NaN ? '0' : timeDifference % 60) < 10 ? '0' + (timeDifference % 60 == NaN ? '00' : timeDifference % 60) :  (timeDifference % 60 == NaN ? '0' : timeDifference % 60));
    const minutes = ((Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) < 10 ? '0' + (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) : (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))));
    const hours = ((Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) < 10 ? '0' + (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) : (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))));

    this.setState({ user: {  ...user, currentSignTime: `${hours}:${minutes}:${seconds}`}});
  }

  return true;
}


handleSignIn() {
  const { user } = this.state;

  if(!user.currentSignedIn) {
    axios.post(getAPI() + `/user/userSignIn` , {uid: encodeURIComponent(user.uid), type: encodeURIComponent(this.state.typeSwitch ? 'Volunteer': 'Robotics')})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          //this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
          //this.setState({ loginRejected: true });
        } else {
          //this.setState({ submitted: false });
          
          this.setState({
            user: {
                ...user,
                currentSignedIn: !this.state.user.currentSignedIn
            }
        });
        }
      });
    } else {
      axios.post(getAPI() + `/user/userSignOut` , {uid: encodeURIComponent(user.uid)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          //this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
          //this.setState({ loginRejected: true });
        } else {
          //this.setState({ submitted: false });
          
          this.setState({
            user: {
                ...user,
                currentSignedIn: !this.state.user.currentSignedIn
            }
        });
        }
      });
    }
}

handleModal() {
  this.setState({modalOpen: !this.state.modalOpen});
}

handleCheck = (event) => {
  this.setState({typeSwitch: event.target.checked});
};



render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='signInOutPage'>
          <SideMenu/>
          <Modal className='modalScreen' open={this.state.modalOpen} onClose={this.handleModal}>
            <div>
              <EditSignIn closeModal={this.handleModal} session={{uid: this.state.user.uid, timeStart: this.state.user.lastSignStart, timeEnd: this.state.user.lastSignEnd, signType: this.state.user.lastSignType, signID: this.state.user.lastSignID}}/>
            </div>
          </Modal>
          <div className='welcomeText'>
            Welcome Back {this.state.user.name}
          </div>
          <div className='lastSignText'>
            <div className='dataContainer'>
              <div className='textUnderline'>
                Total Robotics Hours
              </div>
              <div className='hourText'>
                {this.state.user.currentHourTotalRobotics}
              </div>
            </div>
            <div className='dataContainer'>
              <div className='textUnderline'>
                Total Volunteer Hours
              </div>
              <div className='hourText'>
                {this.state.user.currentHourTotalVolunteer}
              </div>
            </div>
            <div className='dataContainer'>
              <div className='textUnderline'>
                Last Sign In
              </div>
              <div className='hourText'>
                {this.state.user.lastSignIn}
              </div>
            </div>
            <div className='dataContainer'>
              <div className='textUnderline'>
                Last Session Time
              </div>
              <div className='hourText'>
                {this.state.user.lastSignTime}
              </div>
            </div>
          </div>
          <Button className='signInButton' variant="contained" size='small' color='editSign' disabled={this.state.user.lastSignID === '' ? true : false} onClick={this.handleModal}>Edit Last Session</Button>
          <div className='switchBox'>
            <div className='labelText'>
              Sign In Type
            </div>
            <Switch size="medium" {...label} checked={this.state.typeSwitch} onChange={this.handleCheck} color="secondary" disabled={this.state.user.currentSignedIn}/>
            {this.state.typeSwitch &&
              <div className='labelText'>
                Sign In As - Volunteer
              </div>
            }
            {!this.state.typeSwitch &&
              <div className='labelText'>
                Sign In As - Robotics
              </div>
            }
          </div>
          {(!this.state.user.currentSignedIn && !this.state.typeSwitch) &&
            <Button className='signInButton' variant="contained" size='small' color='signIn' onClick={this.handleSignIn}>Sign In Robotics</Button>
          }
          {(this.state.user.currentSignedIn && !this.state.typeSwitch) &&
            <div className='alignSessionButton'>
              <div className='sessionText'>
                  Current Time: 
                <div className='sessionHourText'>
                  {this.state.user.currentSignTime}
                </div>
              </div>
              <Button className='signInButton' variant="contained" size='small' color='signOut' onClick={this.handleSignIn}>Sign Out Robotics</Button>
            </div>
          }
          {(!this.state.user.currentSignedIn && this.state.typeSwitch) &&
            <Button className='signInButton' variant="contained" size='small' color='signIn' onClick={this.handleSignIn}>Sign In Volunteer</Button>
          }
          {(this.state.user.currentSignedIn && this.state.typeSwitch) &&
            <div className='alignSessionButton'>
              <div className='sessionText'>
                  Current Time:
                <div className='sessionHourText'>
                  {this.state.user.currentSignTime}
                </div>
              </div>
              <Button className='signInButton' variant="contained" size='small' color='signOut' onClick={this.handleSignIn}>Sign Out Volunteer</Button>
            </div>
          }
        </div>
      </div>
    </div>
    )
  }
}