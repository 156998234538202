import * as React from 'react';
import './redirectPages.css';
import '../../index.css';



export default class RedirectPages extends React.Component {
  constructor(props) {
    super(props);
    const queryParameters = new URLSearchParams(window.location.search)
    const page = queryParameters.get("page");
    this.state={
     page: page,
     showFacebook: page === 'facebook' ? true : false,
    showInstagram: page === 'instagram' ? true : false,
    }
   
    };

    


render(){
    return(
      <div>
        { this.state.showFacebook ? window.location.replace('https://www.facebook.com/people/NWA-Collectable-Treasures/61567184888842/') : null }
        {this.state.showInstagram ? window.location.replace('https://instagram.com') : null }
      </div>
    )
}

}