import React from 'react';
import {Navigate } from 'react-router-dom';

const PrivateRouteAdmin = ({children }) => {
  if(getAuth()) return children // Check to see if user is logged in

  return <Navigate to="/login" replace />; // Reject and return user to login page if login check fails
};

const getAuth = () => {
  if (sessionStorage.getItem('LOGGED_IN')) {
    const found = sessionStorage.getItem('ROLES').includes("admin");
    return found;
  }

  return false;
};

export default PrivateRouteAdmin;