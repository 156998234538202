import * as React from 'react';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './teamScout.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue, get} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';
import {Navigate } from 'react-router-dom';
import e from 'cors';
import { LineChart } from '@mui/x-charts/LineChart';

dayjs.extend(utc);
const db = getDatabase();

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

const driverDataFields = ['', 'Current Season Only', 'Current and 1 Previous', 'Current and 2 Previous', 'Current and 3 Previous (or more)'];
const robotDriveBase = ['', 'Tank Drive', 'Swerve Drive', 'Mecanum Drive', 'H Drive', 'Omni Drive'];
const driveMotorType = ['', 'Neo', 'Falcon', 'Kracken', 'Neo 2.0', 'CIM'];
const robotTopSpeed = ['', 'Up To 10m/s', '10 To 15m/s', 'Over 15m/s'];
const robotCamera = ['', 'Limelight', 'Webcam', 'None'];
const autoScorePreload = ['', 'Speaker', 'Amp', 'No Preload Score'];
const jankRating = ['', '1 - Doesn\'t Exist/Doesn\'t Work', '2 - Bad', '3 - Low Hopes', '4 - Alright Design', '5 - Reasonable Shot', '6 - Strong Chance', '7 - Good Build', '8 - Excellent', '9 - Amazing', '10 - Perfect'];
const scoutingPriority = ['', 'High', 'Medium', 'Low'];

export default class TeamScout extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      teamNumber: window.location.pathname.substring(11),
      pitScoutNavigate: false,
      pitScouted: false,
      event: sessionStorage.getItem('EVENTNAME'),
      triggerModalPitScoutCheck: false,
      pitDataView: false,
      pitData: {},
      scoutingPriority: 0,
      pitScoutNotes: '',
      hasMatchData: false,
      showMatches: true,
      showPractice: false,
      showPlayoff: false,
      showAll: true,
      showMostRecent: false,
      qualificationMatches: [],
      practiceMatches: [],
      playoffMatches: [],
      dataMatches: [],
      matchListDisplay: [],
      matchData: {
        avgAmpScoredTelop: 0,
        avgSpeakerScoredTelop: 1,
        avgAmpScoredAuto: 2,
        avgSpeakerScoredAuto: 3,
        climbPercentage: 4,
        avgScoredSolo: 5,
      },
      matchDataDeepDive: {
        autoNotePickupArray: [0,0,0,0,0,0,0,0],
        shotsMade: [],
        shotsMissed: [],
        robotMatchNotes: [],
        pickupList: []
      },
      closedButtonPressed: false,
      openMatchDataDeepDive: false,
      showBestMatch: false,
      imageX: 0,
      imageY: 0,
      openFilterModal: false,
      filterEveryone: true,
      filterTeamOnly: false,
      filterCustom: false,
      openChartsModal: false,
      chartFieldsDisplay: {
        autoLeavesStartingLine: false,
        autoNotePickupArray: false,
        autoScoredInAmp: false,
        autoScoredInSpeaker: false,
        endgameClimbed: false,
        endgameClimbTime: false,
        endgameHarmony: false,
        endgameRobotParked: false,
        endgameScoredTrap: false,
        teleopAmpScored: false,
        teleopSpeakerShotPercentage: false,
        robotMVP: false,
        robotPlayedDefense: false,
        robotPlayedDefenseRating: false,
        robotUnderDefense: false,
        robotUnderDefenseRating: false,
        robotHadIssues: false,
        robotHadIssuesRating: false,
        soloAvg: false,
        teleopSpeakerMade: false,
      },
      matchDataChartXAxis: [],
      matchDataChartData: [],
      matchDataChartDataSeries: [],
    }

    this.handlePitScout = this.handlePitScout.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleModalContinue = this.handleModalContinue.bind(this);
    this.handlePitData = this.handlePitData.bind(this);
    this.updateMatchData = this.updateMatchData.bind(this);
    this.checkMatchTypeSelected = this.checkMatchTypeSelected.bind(this);
    this.handleMatchDataDeepDive = this.handleMatchDataDeepDive.bind(this);
    this.renderMatchList = this.renderMatchList.bind(this);
    this.updateDisplayMatch = this.updateDisplayMatch.bind(this);
    this.updateDeepDiveData = this.updateDeepDiveData.bind(this);
    this.renderNotePickupArray = this.renderNotePickupArray.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.getShotMissedList = this.getShotMissedList.bind(this);
    this.getShotMadeList = this.getShotMadeList.bind(this);
    this.getPickupList = this.getPickupList.bind(this);
    this.getMatchNotesText = this.getMatchNotesText.bind(this);
    this.getFilterList = this.getFilterList.bind(this);
    this.handleFilterModal = this.handleFilterModal.bind(this);
    this.checkFilterSelected = this.checkFilterSelected.bind(this);
    this.handleOpenChartsModal = this.handleOpenChartsModal.bind(this);
    this.handleChartData = this.handleChartData.bind(this);
  }

  handleChartData() {
    let matchList = [];
    let matchData = [];
    let matchNumberArray = [];
    let tempSeries = [];

    if(this.state.showPractice) {
      let tempMatchList = this.state.practiceMatches;
      for(let match in tempMatchList) {
        tempMatchList[match][0].matchType = 'Practice';
        matchList.push(tempMatchList[match]);
      }
    }

    if(this.state.showMatches) {
      let tempMatchList = this.state.qualificationMatches;
      for(let match in tempMatchList) {
        tempMatchList[match][0].matchType = 'Qual';
        matchList.push(tempMatchList[match]);
      }
    }
    if(this.state.showPlayoff) {
      let tempMatchList = this.state.playoffMatches;
      for(let match in tempMatchList) {
        tempMatchList[match][0].matchType = 'Playoff';
        matchList.push(tempMatchList[match]);
      }
    }

    for(let match in matchList) {
      let submatchCounter = 0;
      let matchNumber = matchList[match][0].matchNumber;
      let matchType = matchList[match][0].matchType;
      let autoLeavesStartingLine = false;
      let autoNotePickupCount = 0;
      let autoScoredInAmp = 0;
      let autoScoredInSpeaker = 0;
      let endgameClimbTime = 0;
      let endgameClimbed = false;
      let endgameHarmony = false;
      let endgameRobotScoredTrap = false;
      let endgameRobotParked = false;
      let robotMVP = false;
      let robotPlayedDefense = false;
      let robotUnderDefense = false;
      let robotHadIssues = false;
      let robotPlayedDefenseRating = 0;
      let robotUnderDefenseRating = 0;
      let robotHadIssuesRating = 0;
      let teleopAmpScored = 0;
      let teleopSpeakerMade = 0;
      let teleopSpeakerTotal = 0;
      let teleopSpeakerShotPercentage = 0;
      let soloAvg = 0;
      let robotPlayedDefenseCounter = 0;
      let robotUnderDefenseCounter = 0;
      let robotHadIssuesCounter = 0;

      for(let submatch in matchList[match]) {
        submatchCounter++;
        if(matchList[match][submatch].autoLeavesStartingLine) autoLeavesStartingLine = true;
        autoNotePickupCount += matchList[match][submatch].autoNotePickupArray.filter(Boolean).length;
        autoScoredInAmp += matchList[match][submatch].autoScoredInAmp;
        autoScoredInSpeaker += matchList[match][submatch].autoScoredInSpeaker;
        if(matchList[match][submatch].endgameClimbTime) {
          endgameClimbTime += Number(matchList[match][submatch].endgameClimbTime);
        }
        if(matchList[match][submatch].endgameClimbed) endgameClimbed = true;
        if(matchList[match][submatch].endgameHarmony) endgameHarmony = true;
        if(matchList[match][submatch].endgameRobotParked) endgameRobotParked = true;
        if(matchList[match][submatch].endgameScoredTrap) endgameRobotScoredTrap = true;
        if(matchList[match][submatch].robotMVP) robotMVP = true;
        teleopAmpScored += matchList[match][submatch].teleopAmpScored;
        if(matchList[match][submatch].shotsMade) {
          teleopSpeakerMade += matchList[match][submatch].shotsMade.length;
          teleopSpeakerTotal += matchList[match][submatch].shotsMade.length;
        }
        if(matchList[match][submatch].shotsMissed) {
          teleopSpeakerTotal += matchList[match][submatch].shotsMissed.length;
        }
        if(matchList[match][submatch].robotPlayedDefense) {
          robotPlayedDefenseCounter++;
          robotPlayedDefenseRating += matchList[match][submatch].robotDefenseRating;
          robotPlayedDefense = true;
        }
        if(matchList[match][submatch].robotUnderDefense) {
          robotUnderDefenseCounter++;
          robotUnderDefenseRating += matchList[match][submatch].robotUnderDefenseRating;
          robotUnderDefense = true;
        }
        if(matchList[match][submatch].robotHadIssues) {
          robotHadIssuesCounter++;
          robotHadIssuesRating += matchList[match][submatch].robotHadIssuesRating;
          robotHadIssues = true;
        }


      }
      autoNotePickupCount = (autoNotePickupCount / submatchCounter);
      autoScoredInAmp = (autoScoredInAmp / submatchCounter);
      autoScoredInSpeaker = (autoScoredInSpeaker / submatchCounter);
      endgameClimbTime = (endgameClimbTime / submatchCounter);
      teleopAmpScored = (teleopAmpScored / submatchCounter);
      teleopSpeakerMade = (teleopSpeakerMade / submatchCounter);
      teleopSpeakerTotal = (teleopSpeakerTotal / submatchCounter);
      teleopSpeakerShotPercentage = (teleopSpeakerMade / teleopSpeakerTotal) >= 0 ? (teleopSpeakerMade / teleopSpeakerTotal) * 100 : 0;
      soloAvg = ((3 * endgameClimbed) + (autoScoredInAmp * 2) + (autoScoredInSpeaker * 5) + (teleopAmpScored * 1) + (teleopSpeakerMade * 2)).toFixed(2);
      if(robotPlayedDefenseCounter > 0) {
        robotPlayedDefenseRating = robotPlayedDefenseRating / robotPlayedDefenseCounter;
      }
      if(robotUnderDefenseCounter > 0) {
        robotUnderDefenseRating = robotUnderDefenseRating / robotUnderDefenseCounter;
      }
      if(robotHadIssuesCounter > 0) {
        robotHadIssuesRating = robotHadIssuesRating / robotHadIssuesCounter;
      }

      matchData.push({
        matchNumber: matchNumber,
        matchType: matchType,
        autoLeavesStartingLine: autoLeavesStartingLine,
        autoNotePickupCount: autoNotePickupCount,
        autoScoredInAmp: autoScoredInAmp,
        autoScoredInSpeaker: autoScoredInSpeaker,
        endgameClimbTime: endgameClimbTime,
        endgameClimbed: endgameClimbed,
        endgameHarmony: endgameHarmony,
        endgameRobotParked: endgameRobotParked,
        endgameRobotScoredTrap: endgameRobotScoredTrap,
        robotMVP: robotMVP,
        teleopAmpScored: teleopAmpScored,
        teleopSpeakerMade: teleopSpeakerMade,
        teleopSpeakerShotPercentage: teleopSpeakerShotPercentage,
        soloAvg: soloAvg,
        robotPlayedDefense: robotPlayedDefense,
        robotPlayedDefenseRating: robotPlayedDefenseRating,
        robotUnderDefense: robotUnderDefense,
        robotUnderDefenseRating: robotUnderDefenseRating,
        robotHadIssues: robotHadIssues,
        robotHadIssuesRating: robotHadIssuesRating
      });
    }

    for(let match in matchData) {
      matchNumberArray.push(matchData[match].matchNumber + ' ' + matchData[match].matchType);
    }

    if(this.state.chartFieldsDisplay.autoLeavesStartingLine) {
      let tempObject = {data: [], label: 'Auto Line'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].autoLeavesStartingLine));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.autoNotePickupArray) {
      let tempObject = {data: [], label: 'Auto Pickup'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].autoNotePickupCount));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.autoScoredInAmp) {
      let tempObject = {data: [], label: 'Auto Amp'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].autoScoredInAmp));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.autoScoredInSpeaker) {
      let tempObject = {data: [], label: 'Auto Speaker'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].autoScoredInSpeaker));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.endgameClimbed) {
      let tempObject = {data: [], label: 'Endgame Climb'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].endgameClimbed));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.endgameClimbTime) {
      let tempObject = {data: [], label: 'Endgame Climb Time'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].endgameClimbTime));
      }

      tempObject.data = tempDataArray;
      
      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.endgameHarmony) {
      let tempObject = {data: [], label: 'Endgame Harmony'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].endgameHarmony));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.endgameRobotParked) {

      let tempObject = {data: [], label: 'Endgame Park'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].endgameRobotParked));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.endgameRobotScoredTrap) {
      let tempObject = {data: [], label: 'Endgame Trap'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].endgameRobotScoredTrap));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.teleopAmpScored) {
      let tempObject = {data: [], label: 'Teleop Amp'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].teleopAmpScored));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);

    }

    if(this.state.chartFieldsDisplay.teleopSpeakerShotPercentage) {
      let tempObject = {data: [], label: 'Teleop Speaker %'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].teleopSpeakerShotPercentage));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotMVP) {
      let tempObject = {data: [], label: 'Robot MVP'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotMVP));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotPlayedDefense) {
      let tempObject = {data: [], label: 'Robot Defense'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotPlayedDefense));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotPlayedDefenseRating) {
      let tempObject = {data: [], label: 'Robot Defense Rating'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotPlayedDefenseRating));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotUnderDefense) {
      let tempObject = {data: [], label: 'Robot Under Defense'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotUnderDefense));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotUnderDefenseRating) {

      let tempObject = {data: [], label: 'Robot Under Defense Rating'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotUnderDefenseRating));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotHadIssues) {
      let tempObject = {data: [], label: 'Robot Issues'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotHadIssues));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.robotHadIssuesRating) {

      let tempObject = {data: [], label: 'Robot Issues Rating'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].robotHadIssuesRating));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);
    }

    if(this.state.chartFieldsDisplay.soloAvg) {
      let tempObject = {data: [], label: 'Solo Avg'};
      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].soloAvg));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);

    }

    if(this.state.chartFieldsDisplay.teleopSpeakerMade) {

      let tempObject = {data: [], label: 'Teleop Speaker'};

      let tempDataArray = [];

      for(let match in matchData) {
        tempDataArray.push(Number(matchData[match].teleopSpeakerMade));
      }

      tempObject.data = tempDataArray;

      tempSeries.push(tempObject);

    }





    this.setState({matchDataChartXAxis: matchNumberArray, matchDataChartData: matchData, matchDataChartDataSeries: tempSeries});

  }

  handleOpenChartsModal(event) {
    event.preventDefault();
    this.setState({openChartsModal: !this.state.openChartsModal});
  }

  checkFilterSelected() {
    if(!this.state.filterEveryone && !this.state.filterTeamOnly && !this.state.filterCustom) {
      this.setState({filterEveryone: true});
    }
  }

  handleFilterModal(event) {
    event.preventDefault();
    this.setState({openFilterModal: !this.state.openFilterModal});
  }

  getFilterList() {
    console.log('Hit Filter List');
  }

  getMatchNotesText() {
    return this.state.matchDataDeepDive.robotMatchNotes.map((note, index) => {
      return (
        <div className='teamScoutGridAlignItems'>
          <div className='matchScoutGridItemNotesTitle' key={index}>
            {note.title}
          </div>
          <div className='matchScoutGridItemNotes' key={index}>
            {note.text}
          </div>
        </div>
      )
    });
  }

  getShotMadeList(shotMadeList) {
    return (
        <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
            {shotMadeList.map(shot => (
              <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/Made_Shot_Marker.png" alt="Shot Made Image" />
            ))}
        </div>
    )
  }

  getShotMissedList(shotMissedList) {
    return (
      <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
          {shotMissedList.map(shot => (
            <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/Missed_Shot_Marker.png" alt="Shot Missed Image" />
          ))}
      </div>
    )
  }

  getPickupList(pickupList) {
    return (
      <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
          {pickupList.map(shot => (
            <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/PickupMarker.png" alt="Pickup Image" />
          ))}
      </div>
    )
  }

  onImgLoad({target:img}) {
    this.setState({imageY:img.offsetHeight,imageX:img.offsetWidth});
  }

  renderNotePickupArray() {

  return(
    <div className='matchScoutGridList'>
      <div className='matchScoutGridRow'>
        <div className='matchScoutGridItemHide'>
          Source
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[0]}%, white 0%)`}}>
          {(this.state.matchDataDeepDive.autoNotePickupArray[0]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[1]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[1]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[2]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[2]).toFixed(0)}%
        </div>
        <div className='matchScoutGridItemHide'>
          Amp
        </div>
      </div>
      <div className='matchScoutGridRow'>
      <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[3]}%, white 0%)`}}>
      {(this.state.matchDataDeepDive.autoNotePickupArray[3]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[4]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[4]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[5]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[5]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[6]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[6]).toFixed(0)}%
        </div>
        <div  className='matchScoutGridItem' style={{'background': `linear-gradient(to top, green ${this.state.matchDataDeepDive.autoNotePickupArray[7]}%, white 0%)`}}>
        {(this.state.matchDataDeepDive.autoNotePickupArray[7]).toFixed(0)}%
        </div>
      </div>
    </div>
  )
}

  updateDeepDiveData() {
    let matchList = [];

    for(let match in this.state.matchListDisplay) {
      if(this.state.matchListDisplay[match].display) {
        matchList.push(this.state.matchListDisplay[match].matchArray);
      }
    }

    let matchCounter = 0;
    let endgameCounter = 0;
    let endgameSubmatchCounter = 0;
    let autoLeavesStartingLine = 0;
    let autoNotePickupArray = [0,0,0,0,0,0,0,0];
    let autoScoredInAmp = 0;
    let autoScoredInSpeaker = 0;
    let endgameClimbTime = 0;
    let endgameClimbed = 0;
    let endgameHarmony = 0;
    let endgameRobotParked = 0;
    let endgameScoredTrap = 0;
    let teleopAmpScored = 0;
    let teleopSpeakerMade = 0;
    let teleopSpeakerTotal= 0;
    let robotMVP = 0;
    let teleopSpeakerShotPercentage = 0;
    let robotMatchNotes = [];
    let robotPlayedDefense = 0;
    let robotUnderDefense = 0;
    let robotUnderDefenseRating = 0;
    let robotPlayedDefenseRating = 0;
    let shotsMade = [];
    let shotsMissed = [];
    let pickupList = [];
    let robotHadIssues = 0;
    let robotHadIssuesRating = 0;

    let matchAutoLeavesStartingLine = 0;
    let matchAutoNoteArray = [0,0,0,0,0,0,0,0];
    let matchAutoScoredInAmp = 0;
    let matchAutoScoredInSpeaker = 0;
    let matchEndgameClimbed = 0;
    let matchEndgameClimbTime = 0;
    let matchEndgameRobotParked = 0;
    let matchEndgameScoredTrap = 0;
    let matchEndgameHarmony = 0;
    let matchTeleopAmpScored = 0;
    let matchTeleopSpeakerMade = 0;
    let matchTeleopSpeakerTotal = 0;
    let matchRobotMVP = false;
    let matchRobotPlayedDefense = 0;
    let matchRobotUnderDefense = 0;
    let matchRobotHadIssues = 0;
    let matchRobotPlayedDefenseRating = 0;
    let matchRobotUnderDefenseRating = 0;
    let matchRobotHadIssuesRating = 0;


    for(let match in matchList) {
      let submatchCounter = 0;
      let robotMVPFound = false;
      let robotPlayedDefenseCounter = 0;
      let robotUnderDefenseCounter = 0;
      let robotHadIssuesCounter = 0;
      matchCounter++;

      matchAutoLeavesStartingLine = 0;
      matchAutoNoteArray = [0,0,0,0,0,0,0,0];
      matchAutoScoredInAmp = 0;
      matchAutoScoredInSpeaker = 0;
      matchEndgameClimbed = 0;
      matchEndgameClimbTime = 0;
      matchEndgameRobotParked = 0;
      matchEndgameScoredTrap = 0;
      matchEndgameHarmony = 0;
      matchTeleopAmpScored = 0;
      matchTeleopSpeakerMade = 0;
      matchTeleopSpeakerTotal = 0;
      matchRobotMVP = false;
      matchRobotPlayedDefense = 0;
      matchRobotUnderDefense = 0;
      matchRobotHadIssues = 0;
      matchRobotPlayedDefenseRating = 0;
      matchRobotUnderDefenseRating = 0;
      matchRobotHadIssuesRating = 0;


      for(let submatch in matchList[match]) {
        submatchCounter++;
        let data = matchList[match][submatch];
        matchAutoLeavesStartingLine += data.autoLeavesStartingLine ? 1 : 0;

        for(let i = 0; i < data.autoNotePickupArray.length; i++) {
          if(data.autoNotePickupArray[i] == true) {
            matchAutoNoteArray[i]++;
          }
        }

        matchAutoScoredInAmp += data.autoScoredInAmp;

        matchAutoScoredInSpeaker += data.autoScoredInSpeaker;

        matchEndgameClimbed += data.endgameClimbed ? 1 : 0;

        if(data.endgameClimbTime) {
          matchEndgameClimbTime += Number(data.endgameClimbTime);
          endgameSubmatchCounter++;
        }

        matchEndgameHarmony += data.endgameHarmony ? 1 : 0;

        matchEndgameRobotParked += data.endgameRobotParked ? 1 : 0;
        
        matchEndgameScoredTrap += data.endgameScoredTrap ? 1 : 0;

        matchTeleopAmpScored += data.teleopAmpScored;

        if(data.shotsMade) {
          matchTeleopSpeakerMade += data.shotsMade.length;
          matchTeleopSpeakerTotal += data.shotsMade.length;
        }
        if(data.shotsMissed) {
          matchTeleopSpeakerTotal += data.shotsMissed.length;
        }
        if(data.robotMVP) {
          matchRobotMVP = true;
        }

        matchRobotPlayedDefense += data.robotPlayedDefense ? 1 : 0;
        matchRobotUnderDefense += data.robotUnderDefense ? 1 : 0;
        matchRobotHadIssues += data.robotHadIssues ? 1 : 0;

        if(data.robotPlayedDefense) { 
          robotPlayedDefenseCounter++;
          matchRobotPlayedDefenseRating += data.robotDefenseRating;
        }
        if(data.robotUnderDefense) {
          robotUnderDefenseCounter++;
          matchRobotUnderDefenseRating += data.robotUnderDefenseRating;
        }
        if(data.robotHadIssues) {
          robotHadIssuesCounter++;
          matchRobotHadIssuesRating += data.robotHadIssuesRating;
        }
        if(data.shotsMade) {
          shotsMade = shotsMade.concat(data.shotsMade);
        }
        if(data.shotsMissed) {
          shotsMissed = shotsMissed.concat(data.shotsMissed);
        }
        if(data.pickupList) {
          pickupList = pickupList.concat(data.pickupList);
        }

        if(data.matchFinalNotes != '') {
          if(data.personScouting) {
            robotMatchNotes.push({ title: `Match_${data.matchNumber}_Submatch_${submatch}_By_${data.personScouting}_Team_${data.teamScouting}`, text: data.matchFinalNotes});
          } else {
            robotMatchNotes.push({ title: `Match_${data.matchNumber}_Submatch_${submatch}`, text: data.matchFinalNotes});
          }
        }

      }
      autoLeavesStartingLine += (matchAutoLeavesStartingLine / submatchCounter);

      for(let i = 0; i < matchAutoNoteArray.length; i++) {
        autoNotePickupArray[i] += (matchAutoNoteArray[i] / submatchCounter);
      }

      autoScoredInAmp += (matchAutoScoredInAmp / submatchCounter);

      autoScoredInSpeaker += (matchAutoScoredInSpeaker / submatchCounter);

      endgameClimbed += (matchEndgameClimbed / submatchCounter);

      if(endgameSubmatchCounter > 0) {
        endgameClimbTime += (matchEndgameClimbTime / endgameSubmatchCounter);
        endgameHarmony += (matchEndgameHarmony / endgameSubmatchCounter);
        endgameCounter++;
      }

      endgameRobotParked += (matchEndgameRobotParked / submatchCounter);

      endgameScoredTrap += (matchEndgameScoredTrap / submatchCounter);

      teleopAmpScored += (matchTeleopAmpScored / submatchCounter);

      teleopSpeakerMade += (matchTeleopSpeakerMade / submatchCounter);

      teleopSpeakerTotal += (matchTeleopSpeakerTotal / submatchCounter);

      robotMVP += matchRobotMVP ? 1 : 0;

      robotPlayedDefense += (matchRobotPlayedDefense / submatchCounter);
      robotUnderDefense += (matchRobotUnderDefense / submatchCounter);
      robotHadIssues += (matchRobotHadIssues / submatchCounter);

      if(robotPlayedDefenseCounter > 0) {
        robotPlayedDefenseRating += (matchRobotPlayedDefenseRating / robotPlayedDefenseCounter);
      }
      if(robotUnderDefenseCounter > 0) {
        robotUnderDefenseRating += (matchRobotUnderDefenseRating / robotUnderDefenseCounter);
      }
      if(robotHadIssuesCounter > 0) {
        robotHadIssuesRating += (matchRobotHadIssuesRating / robotHadIssuesCounter);
      }
    }

    autoLeavesStartingLine = (autoLeavesStartingLine / matchCounter);

    for(let i = 0; i < autoNotePickupArray.length; i++) {
      autoNotePickupArray[i] = (autoNotePickupArray[i] / matchCounter) * 100;
    }

    autoScoredInAmp = (autoScoredInAmp / matchCounter);

    autoScoredInSpeaker = (autoScoredInSpeaker / matchCounter);
    endgameClimbed = (endgameClimbed / matchCounter);
    if(endgameCounter > 0) {
      endgameClimbTime = (endgameClimbTime / endgameCounter);
      endgameHarmony = (endgameHarmony / endgameCounter);
    }
    endgameRobotParked = (endgameRobotParked / matchCounter);
    endgameScoredTrap = (endgameScoredTrap / matchCounter);
    teleopAmpScored = (teleopAmpScored / matchCounter);
    teleopSpeakerMade = (teleopSpeakerMade / matchCounter);
    teleopSpeakerTotal = (teleopSpeakerTotal / matchCounter);
    robotMVP = (robotMVP / matchCounter) * 100;
    teleopSpeakerShotPercentage = (teleopSpeakerMade / teleopSpeakerTotal) >= 0 ? (teleopSpeakerMade / teleopSpeakerTotal) : 0;
    if(robotPlayedDefense > 0) {
      robotPlayedDefenseRating = robotPlayedDefenseRating / robotPlayedDefense;
      robotPlayedDefense = (robotPlayedDefense / matchCounter) * 100;
    }
    if(robotUnderDefense > 0) {
      robotUnderDefenseRating = robotUnderDefenseRating / robotUnderDefense;
      robotUnderDefense = (robotUnderDefense / matchCounter) * 100;
    }
    if(robotHadIssues > 0) {
      console.log(robotHadIssuesRating, robotHadIssues);
      robotHadIssuesRating = robotHadIssuesRating / robotHadIssues;
      robotHadIssues = (robotHadIssues / matchCounter) * 100;
      console.log(robotHadIssuesRating, robotHadIssues);
    }
    const soloAvg = ((3 * endgameClimbed) + (autoScoredInAmp * 2) + (autoScoredInSpeaker * 5) + (teleopAmpScored * 1) + (teleopSpeakerMade * 2)).toFixed(2);

    teleopSpeakerShotPercentage = (teleopSpeakerShotPercentage * 100);

    const matchDataDeepDive = {
      autoLeavesStartingLine: (autoLeavesStartingLine * 100).toFixed(0),
      autoNotePickupArray: autoNotePickupArray,
      autoScoredInAmp: autoScoredInAmp.toFixed(2),
      autoScoredInSpeaker: autoScoredInSpeaker.toFixed(2),
      endgameClimbed: (endgameClimbed * 100).toFixed(0),
      endgameClimbTime: endgameClimbTime.toFixed(2),
      endgameHarmony: (endgameHarmony * 100).toFixed(0),
      endgameRobotParked: (endgameRobotParked * 100).toFixed(0),
      endgameScoredTrap: (endgameScoredTrap * 100).toFixed(0),
      teleopAmpScored: teleopAmpScored.toFixed(2),
      teleopSpeakerShotPercentage: teleopSpeakerShotPercentage.toFixed(0),
      robotMVP: robotMVP.toFixed(0),
      robotPlayedDefense: robotPlayedDefense.toFixed(0),
      robotPlayedDefenseRating: robotPlayedDefenseRating.toFixed(2),
      robotUnderDefense: robotUnderDefense.toFixed(0),
      robotUnderDefenseRating: robotUnderDefenseRating.toFixed(2),
      robotMatchNotes: robotMatchNotes,
      soloAvg: soloAvg,
      teleopSpeakerMade: teleopSpeakerMade.toFixed(2),
      shotsMade: shotsMade,
      shotsMissed: shotsMissed,
      pickupList: pickupList,
      robotHadIssues: robotHadIssues.toFixed(0),
      robotHadIssuesRating: robotHadIssuesRating.toFixed(2)
    }

    this.setState({matchDataDeepDive: matchDataDeepDive});

  }

  updateDisplayMatch(matchNumber) {
    let matches = this.state.matchListDisplay;

    matches[matchNumber].display = !matches[matchNumber].display;
    
    this.setState({matchListDisplay: matches}, () => this.updateDeepDiveData());
  }

  renderMatchList() {
    return this.state.matchListDisplay.map((match, index) => {
      return (
        <div className='teamScoutMatchListAlign' key={index}>
          <FormControlLabel control={<Checkbox checked={match.display}  onClick={() => this.updateDisplayMatch(index)} />} label={match.matchNumber} />
        </div>
      )
    });
  }

  handleMatchDataDeepDive(event) {
    event.preventDefault();
    this.setState({openMatchDataDeepDive: !this.state.openMatchDataDeepDive});
  }

  checkMatchTypeSelected() {
    if(!this.state.showMatches && !this.state.showPractice && !this.state.showPlayoff) {
      this.setState({showMatches: true}, () => this.updateMatchData());
    } else {
      this.updateMatchData();
    }

  }

  updateMatchData() {
    
    let matches = [];
    let matchesDisplay = [];
    let index = 0;

    if(this.state.showMatches) {
      if(this.state.showAll) {
        matches = matches.concat(this.state.qualificationMatches);
      } 
      else if(this.state.showBestMatch) {
        let bestMatch = 0;
        let bestMatchScore = 0;
        let submatchCounter = 0;
        for(let match in this.state.qualificationMatches) {
          let matchScore = 0;
          submatchCounter = 0;
          for(let submatch in this.state.qualificationMatches[match]) {
            matchScore += ((this.state.qualificationMatches[match][submatch].autoScoredInAmp * 2) + (this.state.qualificationMatches[match][submatch].autoScoredInSpeaker * 5) + (this.state.qualificationMatches[match][submatch].teleopAmpScored * 1) + (this.state.qualificationMatches[match][submatch].endgameClimbed * 3));
            if(this.state.qualificationMatches[match][submatch].shotsMade) {
              matchScore += this.state.qualificationMatches[match][submatch].shotsMade.length * 2;
            }
            submatchCounter++;
          }
          matchScore = matchScore / submatchCounter;
          if(matchScore > bestMatchScore) {
            bestMatchScore = matchScore;
            bestMatch = match;
          }
        }
        matches = matches.concat([this.state.qualificationMatches[bestMatch]]);
      }
      else {
        index = 0;
        for(let match in this.state.qualificationMatches) {
          index++;
        }
        if(this.state.qualificationMatches.slice(index).length == 0) {
          matches = matches.concat(this.state.qualificationMatches);
        } else {
          matches = matches.concat(this.state.qualificationMatches.slice(index));
        }
      }

      for(let match in this.state.qualificationMatches) {
        matchesDisplay.push({matchNumber: 'Qualification ' + this.state.qualificationMatches[match][0].matchNumber, matchArray: this.state.qualificationMatches[match], display: false});
      }

    }

    if(this.state.showPlayoff) {
      if(this.state.showAll) {
        matches = matches.concat(this.state.playoffMatches);
      } 
      else if(this.state.showBestMatch) {
        let bestMatch = 0;
        let bestMatchScore = 0;

        for(let match in this.state.playoffMatches) {
          let matchScore = 0;
          let submatchCounter = 0;
          for(let submatch in this.state.playoffMatches[match]) {
            matchScore += ((this.state.playoffMatches[match][submatch].autoScoredInAmp * 2) + (this.state.playoffMatches[match][submatch].autoScoredInSpeaker * 5) + (this.state.playoffMatches[match][submatch].teleopAmpScored * 1) + (this.state.playoffMatches[match][submatch].endgameClimbed * 3));
            if(this.state.playoffMatches[match][submatch].shotsMade) {
              matchScore += this.state.playoffMatches[match][submatch].shotsMade.length * 2;
            }
            submatchCounter++;
          }
          matchScore = matchScore / submatchCounter;
          if(matchScore > bestMatchScore) {
            bestMatchScore = matchScore;
            bestMatch = match;
          }
        }
        matches = matches.concat([this.state.playoffMatches[bestMatch]]);
      }
      else {
        index = 0;
        for(let match in this.state.playoffMatches) {
          index++;
        }
        if(this.state.playoffMatches.slice(index).length == 0) {
          matches = matches.concat(this.state.playoffMatches);
        } else {
          matches = matches.concat(this.state.playoffMatches.slice(index));
        }
      }

      for(let match in this.state.playoffMatches) {
        matchesDisplay.push({matchNumber: 'Playoff ' +  this.state.playoffMatches[match][0].matchNumber, matchArray: this.state.playoffMatches[match], display: false});
      }


    }

    if(this.state.showPractice) {
      if(this.state.showAll) {
        matches = matches.concat(this.state.practiceMatches);
      } 
      else if(this.state.showBestMatch) {
        let bestMatch = 0;
        let bestMatchScore = 0;
        for(let match in this.state.practiceMatches) {
          let matchScore = 0;
          let submatchCounter = 0;
          for(let submatch in this.state.practiceMatches[match]) {
            matchScore += ((this.state.practiceMatches[match][submatch].autoScoredInAmp * 2) + (this.state.practiceMatches[match][submatch].autoScoredInSpeaker * 5) + (this.state.practiceMatches[match][submatch].teleopAmpScored * 1) + (this.state.practiceMatches[match][submatch].endgameClimbed * 3));
            if(this.state.practiceMatches[match][submatch].shotsMade) {
              matchScore += this.state.practiceMatches[match][submatch].shotsMade.length * 2;
            }
            submatchCounter++;
          }
          matchScore = matchScore / submatchCounter;
          if(matchScore > bestMatchScore) {
            bestMatchScore = matchScore;
            bestMatch = match;
          }
        }
        matches = matches.concat([this.state.practiceMatches[bestMatch]]);
      }
      else {
        index = 0;
        for(let match in this.state.practiceMatches) {
          index++;
        }
        if(this.state.practiceMatches.slice(index).length == 0) {
          matches = matches.concat(this.state.practiceMatches);
        } else {
          matches = matches.concat(this.state.practiceMatches.slice(index));
        }
      }

      for(let match in this.state.practiceMatches) {
        matchesDisplay.push({matchNumber: 'Practice ' + this.state.practiceMatches[match][0].matchNumber, matchArray: this.state.practiceMatches[match], display: false});
      }

    }

    if(this.state.showBestMatch) {
      matches = [...matches];
    }
      
    this.setState({dataMatches: matches, matchListDisplay: matchesDisplay}, () => this.getFilterList());

    let avgAmpScoredTelop = 0;
    let avgSpeakerScoredTelop = 0;
    let avgAmpScoredAuto = 0;
    let avgSpeakerScoredAuto = 0;
    let climbPercentage = 0;
    let avgScoredSolo = 0;
    let matchCount = 0;

    for(let match in matches) {
      matchCount++;
      let subMatchAutoAmp = 0;
      let subMatchAutoSpeaker = 0;
      let subMatchTelopAmp = 0;
      let subMatchTelopSpeaker = 0;
      let subMatchClimb = 0;
      let subMatchSolo = 0;
      let subMatchCouter = 0;

      for(let submatch in matches[match]) {
        subMatchCouter++;
        subMatchAutoAmp += matches[match][submatch].autoScoredInAmp;
        subMatchAutoSpeaker += matches[match][submatch].autoScoredInSpeaker;
        subMatchTelopAmp += matches[match][submatch].teleopAmpScored;
        if(matches[match][submatch].shotsMade) {
          subMatchTelopSpeaker += matches[match][submatch].shotsMade.length;
        }
        subMatchClimb += matches[match][submatch].endgameClimbed ? 1 : 0;
        subMatchSolo += ((subMatchAutoAmp * 2) + (subMatchAutoSpeaker * 5) + (subMatchTelopAmp * 1) + (subMatchTelopSpeaker * 2) + (subMatchClimb * 3));
      }
      avgAmpScoredTelop += subMatchTelopAmp / subMatchCouter;
      avgSpeakerScoredTelop += subMatchTelopSpeaker / subMatchCouter;
      avgAmpScoredAuto += subMatchAutoAmp / subMatchCouter;
      avgSpeakerScoredAuto += subMatchAutoSpeaker / subMatchCouter;
      climbPercentage += subMatchClimb / subMatchCouter;
      avgScoredSolo += subMatchSolo / subMatchCouter;

    }

    
    avgAmpScoredTelop = Number(avgAmpScoredTelop / matchCount).toFixed(2);
    avgSpeakerScoredTelop = Number(avgSpeakerScoredTelop / matchCount).toFixed(2);
    avgAmpScoredAuto = Number(avgAmpScoredAuto / matchCount).toFixed(2);
    avgSpeakerScoredAuto = Number(avgSpeakerScoredAuto / matchCount).toFixed(2);
    climbPercentage = Number(climbPercentage / matchCount).toFixed(2);
    avgScoredSolo = Number(avgScoredSolo / matchCount).toFixed(2);

    climbPercentage = 'NaN' ? 0 :  (climbPercentage * 100);
    
    this.setState({matchData: {
      avgAmpScoredTelop: avgAmpScoredTelop === 'NaN' ? 0 : avgAmpScoredTelop,
      avgSpeakerScoredTelop: avgSpeakerScoredTelop === 'NaN' ? 0 : avgSpeakerScoredTelop,
      avgAmpScoredAuto: avgAmpScoredAuto === 'NaN' ? 0 : avgAmpScoredAuto,
      avgSpeakerScoredAuto: avgSpeakerScoredAuto === 'NaN' ? 0 : avgSpeakerScoredAuto,
      climbPercentage: climbPercentage === 'NaN' ? 0 : climbPercentage + '%',
      avgScoredSolo: avgScoredSolo === 'NaN' ? 0 : avgScoredSolo,
    }}, () => this.handleChartData());

  }
  

componentDidMount() {

  get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/matches`)).then((snapshot) => {
    if (snapshot.exists()) {
      if(!Array.isArray(snapshot.val())) {
        var result = [];
        for(let match in snapshot.val()) {
          result.push(snapshot.val()[match]);
        }
        var filtered = result.filter(function (el) {
          return el != null;
        });

        this.setState({hasMatchData: true, qualificationMatches: filtered}, () => this.updateMatchData());

      } else {
        this.setState({hasMatchData: true, qualificationMatches: snapshot.val()}, () => this.updateMatchData());

      }
    }
  });

  get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/playoffMatches`)).then((snapshot) => {
    if (snapshot.exists()) {
      if(!Array.isArray(snapshot.val())) {
        var result = [];
        for(let match in snapshot.val()) {
          result.push(snapshot.val()[match]);
        }
        var filtered = result.filter(function (el) {
          return el != null;
        });
        this.setState({hasMatchData: true, playoffMatches: filtered},() => this.updateMatchData());
      } else {
        this.setState({hasMatchData: true, playoffMatches: snapshot.val()},() => this.updateMatchData());
      }
    }
  });

  get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/practiceMatches`)).then((snapshot) => {
    if (snapshot.exists()) {
      if(!Array.isArray(snapshot.val())) {
        var result = [];
        for(let match in snapshot.val()) {
          result.push(snapshot.val()[match]);
        }
        var filtered = result.filter(function (el) {
          return el != null;
        });
        this.setState({hasMatchData: true, practiceMatches: filtered},() => this.updateMatchData());
      } else {
        this.setState({hasMatchData: true, practiceMatches: snapshot.val()},() => this.updateMatchData());
      }
    }
  });

  get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/pitScouted`)).then((snapshot) => {
    if (snapshot.exists() && snapshot.val() == true) {
      this.setState({pitScouted: true});

      get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/scoutPriority`)).then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({scoutingPriority: snapshot.val()});
        } else {
          console.log("No data available");
        }
      });

      get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/pitScoutNotes`)).then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({pitScoutNotes: snapshot.val()});
        } else {
          console.log("No data available");
        }
      });

      get(ref(db, `scouting/2024/events/${this.state.event}/teams/${this.state.teamNumber}/pitData`)).then((snapshot) => {
        if (snapshot.exists()) {
          this.setState({pitData: JSON.parse(snapshot.val())});
        } else {
          console.log("No data available");
        }
      });
    } else {
      this.setState({pitScouted: false});
    }
  }).catch((error) => {
    console.error(error);
  });
}

handlePitScout(event) {
  event.preventDefault();
  if( this.state.pitScouted == true){
    this.setState({triggerModalPitScoutCheck: true});
  } else {
    this.setState({pitScoutNavigate: true});

  }
}

handleModal(event) {
  event.preventDefault();
  this.setState({triggerModalPitScoutCheck: !this.state.triggerModalPitScoutCheck});
}

handleModalContinue(event) { 
  event.preventDefault(); 
  this.setState({pitScoutNavigate: true});
  this.setState({triggerModalPitScoutCheck: !this.state.triggerModalPitScoutCheck});
}

handlePitData(event) {  
  event.preventDefault(); 
  this.setState({pitDataView: !this.state.pitDataView});
}

render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='teamScoutPage'>
          <SideMenu/>
          <div className='pitScoutingAlignHeader'>
            <div className='pitScoutingTeamNumberIncrease'>
              {this.state.teamNumber}
            </div>
            <div className='pitScoutingTeamNumber'>
              {this.state.event}
            </div>
          </div>
          {this.state.pitScoutNavigate ? <Navigate to={`/PitScouting/${this.state.teamNumber}`} /> : null}
          {this.state.closedButtonPressed ? <Navigate to={`/ScoutingMain`} /> : null}
          <Modal className='modalScreen' open={(this.state.triggerModalPitScoutCheck)} onClose={this.handleModal}>
            <div className='modalScreenAlign'>
              <div className='warningText'>
              Warning!
              </div>
              <div className='modalTextAlign'>
               This team has already been pit scouted. Are you sure you want to continue?
              </div>
              <div className='modalScreenButtons'>
                <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={this.handleModal}>No</Button>
                <Button className='modalSignButton' variant="contained" size='small' color='signIn' onClick={this.handleModalContinue}>Yes</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.pitDataView)} onClose={(event) => this.handlePitData(event)}>
            <div className='modalScreenAlign'>
               <div className='modalScreenPitData'>
                <div className='scoutingPageAllowScroll'>
                  <div className='scoutingPageTextHeader1'>
                    Generic Info
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Driver Experience
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Seasons Driven
                    </div>
                    <div className='textChange2'>
                      {driverDataFields[this.state.pitData.seasonsDriven]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Current Seasons Events Driven
                    </div>
                    <div className='textChange2'>
                      {driverDataFields[this.state.pitData.currentSeasonEvents]}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Robot Information
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Drive Base
                    </div>
                    <div className='textChange2'>
                      {robotDriveBase[this.state.pitData.robotDriveBase]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Drive Motor Type
                    </div>
                    <div className='textChange2'>
                      {driveMotorType[this.state.pitData.robotDriveBase]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Top Speed
                    </div>
                    <div className='textChange2'>
                      {robotTopSpeed[this.state.pitData.robotDriveBase]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Length In Inches
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.robotLength}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Width In Inches
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.robotWidth}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Height In Inches
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.robotHeight}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Weight In Pounds
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.robotWeight}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Webcam
                    </div>
                    <div className='textChange2'>
                      {robotCamera[this.state.pitData.robotWebcam]}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader1'>
                    Season Specific Info
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Auto
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Moves Out Of Starting Zone
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoLeavesStartZone && 'Yes'}
                      {!this.state.pitData.autoLeavesStartZone && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Score Preload
                    </div>
                    <div className='textChange2'>
                      {autoScorePreload[this.state.pitData.autoScorePreload]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Pickup Additional Notes Total
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoPickupPiecesTotal}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Pickup Additional Notes Close
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoPickupClosePieces}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Pickup Additional Notes Far
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoPickupPiecesFar}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Amp Scored In Auto
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoAmpScore}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Speaker Scored In Auto
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.autoSpeakerScore}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Teleop Scoring
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Score In Amp
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopScoreAmp && 'Yes'}
                      {!this.state.pitData.teleopScoreAmp && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Score In Speaker
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopScoreSpeaker && 'Yes'}
                      {!this.state.pitData.teleopScoreSpeaker && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Speaker Score MAX Range In Feet
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopSpeakerScoreDistance}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Robot Uses Auto Score For Speaker (Limelight)
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopAutoScoreSpeaker && 'Yes'}
                      {!this.state.pitData.teleopAutoScoreSpeaker && 'No'}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Teleop Intake
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Pickup From Floor
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopFloorPickup && 'Yes'}
                      {!this.state.pitData.teleopFloorPickup && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Pickup From Player Station
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.teleopHumanPlayerPickup && 'Yes'}
                      {!this.state.pitData.teleopHumanPlayerPickup && 'No'}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Teleop Endgame
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Climb
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.endgameClimb && 'Yes'}
                      {!this.state.pitData.endgameClimb && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Achieve Climb Harmony
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.endgameClimbHarmony && 'Yes'}
                      {!this.state.pitData.endgameClimbHarmony && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Score Trap
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.endgameTrapScore && 'Yes'}
                      {!this.state.pitData.endgameTrapScore && 'No'}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can Score Multiple Traps
                    </div>
                    <div className='textChange2'>
                      {this.state.pitData.endgameMultipleTrapScore && 'Yes'}
                      {!this.state.pitData.endgameMultipleTrapScore && 'No'}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    JANK Rating
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Climb Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingEndgameClimb]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Trap Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingEndgameTrapScore]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Speaker Score Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingSpeakerScoring]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Amp Score Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingAmpScoring]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Intake Floor Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingIntakeFloor]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Intake Player Station Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingIntakeStation]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Battery Mount Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingBatteryMount]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Can It Take A Hit Rating
                    </div>
                    <div className='textChange2'>
                      {jankRating[this.state.pitData.jankRatingTakeAHit]}
                    </div>
                  </div>
                  <div className='scoutingPageTextHeader2'>
                    Final Thoughts
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Scouting Priority
                    </div>
                    <div className='textChange2'>
                      {scoutingPriority[this.state.scoutingPriority]}
                    </div>
                  </div>
                  <div className='dataDisplayAlign'>
                    <div className='textChange1'>
                      Pit Scout Notes
                    </div>
                    <div className='textChange2'>
                      {this.state.pitScoutNotes}
                    </div>
                  </div>
                </div>
               </div>
              <div className='modalScreenButtons'>
                <Button className='modalSignButton' variant="contained" size='small' color='editSign' onClick={(event) => this.handlePitScout(event)}>Edit</Button>
                <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={(event) => this.handlePitData(event)}>Close</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openMatchDataDeepDive)} onClose={this.handleMatchDataDeepDive}>
            <div className='modalScreenAlign'>
              <div className='MatchScoutingPageFormFields1'>
                <div className='scoutingPageFields'>
                  <div className='teamScoutQuickCheckboxes'>
                      <div className='teamScoutAtAGlanceTitle1'>
                        Match Type Display
                      </div>
                      <div className='teamScoutQuickMatchSelect'>
                        <div className='teamScoutCheckboxAlign2'>
                          <FormControlLabel control={<Checkbox checked={this.state.showMatches}  onClick={() => this.setState({showMatches: !this.state.showMatches}, () => this.checkMatchTypeSelected())} />} label="Qualification" />
                          <FormControlLabel control={<Checkbox checked={this.state.showPlayoff}  onClick={() => this.setState({showPlayoff: !this.state.showPlayoff}, () => this.checkMatchTypeSelected())} />} label="Playoff" />
                          <FormControlLabel control={<Checkbox checked={this.state.showPractice}  onClick={() => this.setState({showPractice: !this.state.showPractice}, () => this.checkMatchTypeSelected())} />} label="Practice" />
                        </div>
                      </div>
                    </div>
                    <div className='teamScoutMatchListDisplay'>
                      <div className='teamScoutAtAGlanceTitle1'>
                          Match List
                      </div>
                      <div className='teamScoutMatchListAdjust'>
                        {this.renderMatchList()}
                      </div>
                    </div>
                    <div className='teamScoutAtAGlanceTitle1'>
                        Match Data Summary
                      </div>
                        <div className='teamScoutAtAGlance'>
                        <div className='teamScoutAtAGlanceAlign2'>
                          <div className='teamScoutAtAGlanceText'>
                            <div className='teamScoutAtAGlanceText1'>
                              Solo Avg
                            </div>
                            <div className='teamScoutAtAGlanceText2'>
                              {this.state.matchDataDeepDive.soloAvg}
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceText'>
                            <div className='teamScoutAtAGlanceText1'>
                              Robot MVP
                            </div>
                            <div className='teamScoutAtAGlanceText2'>
                              {this.state.matchDataDeepDive.robotMVP}%
                            </div>
                          </div>
                        </div>
                        <div className='teamScoutAtAGlanceAlign'>
                            <div className='teamScoutAtAGlanceTitle1'>
                              Auto
                            </div>
                          <div className='teamScoutAtAGlanceAlign3'>
                            <div className='teamScoutAtAGlanceAlign4'>
                              <div className='teamScoutAtAGlanceText'>
                                <div className='teamScoutAtAGlanceText1'>
                                  Left Zone
                                </div>
                                <div className='teamScoutAtAGlanceText2'>
                                  {this.state.matchDataDeepDive.autoLeavesStartingLine}%
                                </div>
                              </div>
                              <div className='teamScoutAtAGlanceText'>
                                <div className='teamScoutAtAGlanceText1'>
                                  Speaker 
                                </div>
                                <div className='teamScoutAtAGlanceText2'>
                                  {this.state.matchDataDeepDive.autoScoredInSpeaker}
                                </div>
                              </div>
                              <div className='teamScoutAtAGlanceText'>
                                <div className='teamScoutAtAGlanceText1'>
                                  Amp 
                                </div>
                                <div className='teamScoutAtAGlanceText2'>
                                  {this.state.matchDataDeepDive.autoScoredInAmp}
                                </div>
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText1'>
                              Auto Note Pickup
                            </div>
                            {this.renderNotePickupArray()}
                          </div>
                        </div>
                        <div className='teamScoutAtAGlanceAlign'>
                          <div className='teamScoutAtAGlanceTitle1'>
                            Teleop
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Speaker
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.teleopSpeakerMade}
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Shot %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.teleopSpeakerShotPercentage}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Amp
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.teleopAmpScored}
                              </div>
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Climbed %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.endgameClimbed}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Climb Time
                              </div>
                              <div className='teamScoutAtAGlanceText6'>
                                {this.state.matchDataDeepDive.endgameClimbTime} Seconds
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Harmony %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.endgameHarmony}%
                              </div>
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                          <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Parked %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.endgameRobotParked}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Trap %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.endgameScoredTrap}%
                              </div>
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText5'>
                                Under Def %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotUnderDefense}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Rating Avg
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotUnderDefenseRating}
                              </div>
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText5'>
                                Played Def %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotPlayedDefense}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Rating Avg
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotPlayedDefenseRating}
                              </div>
                            </div>
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText5'>
                                Had Issues %
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotHadIssues}%
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Rating Avg
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchDataDeepDive.robotHadIssuesRating}
                              </div>
                            </div>
                          </div>
                          <div className='matchScoutingShootingBoxes1'>
                            <img onLoad={this.onImgLoad} className="matchScoutFieldImg1" src="../resources/2024_field.png" alt="Field Map"/>
                            {this.getShotMadeList(this.state.matchDataDeepDive.shotsMade)}
                            {this.getShotMissedList(this.state.matchDataDeepDive.shotsMissed)}
                            {this.getPickupList(this.state.matchDataDeepDive.pickupList)}
                          </div>
                          <div className='teamScoutAtAGlanceTitle1'>
                            Match Notes
                          </div>
                          {this.getMatchNotesText()}
                        </div>
                      </div>
                  <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={this.handleMatchDataDeepDive}>Close</Button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openFilterModal)} onClose={this.handleFilterModal}>
            <div className='modalScreenAlign'>
              <div className='teamScoutQuickMatchSelect'>
                <div className='teamScoutCheckboxAlign4'>
                  <FormControlLabel control={<Checkbox checked={this.state.filterEveryone}  onClick={() => this.setState({filterEveryone: !this.state.filterEveryone, filterTeamOnly: false, filterCustom: false}, () => this.checkFilterSelected())} />} label="Everyone" />
                  <FormControlLabel control={<Checkbox checked={this.state.filterTeamOnly}  onClick={() => this.setState({filterTeamOnly: !this.state.filterTeamOnly, filterEveryone: false, filterCustom: false}, () => this.checkFilterSelected())} />} label="Your Team Only" />
                  <FormControlLabel control={<Checkbox checked={this.state.filterCustom}  onClick={() => this.setState({filterCustom: !this.state.filterCustom, filterEveryone: false, filterTeamOnly: false}, () => this.checkFilterSelected())} />} label="Custom" />
                </div>
              </div>
              <div className='modalScreenButtons'>
                <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={this.handleFilterModal}>Close</Button>
              </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.openChartsModal)} onClose={this.handleOpenChartsModal}>
            <div className='modalScreenAlign'>
              <div className='MatchScoutingPageFormFields2'>
                <div className='scoutingPageFields'>
                  <div className='teamScoutQuickCheckboxes'>
                    <div className='teamScoutAtAGlanceTitle1'>
                      Match Type Display
                    </div>
                    <div className='teamScoutQuickMatchSelect'>
                      <div className='teamScoutCheckboxAlign2'>
                        <FormControlLabel control={<Checkbox checked={this.state.showMatches}  onClick={() => this.setState({showMatches: !this.state.showMatches}, () => this.checkMatchTypeSelected())} />} label="Qualification" />
                        <FormControlLabel control={<Checkbox checked={this.state.showPlayoff}  onClick={() => this.setState({showPlayoff: !this.state.showPlayoff}, () => this.checkMatchTypeSelected())} />} label="Playoff" />
                        <FormControlLabel control={<Checkbox checked={this.state.showPractice}  onClick={() => this.setState({showPractice: !this.state.showPractice}, () => this.checkMatchTypeSelected())} />} label="Practice" />
                      </div>
                    </div>
                  </div>
                  <div className='teamScoutQuickCheckboxes'>
                    <div className='teamScoutAtAGlanceTitle1'>
                      Display Fields
                    </div>
                    <div className='teamScoutQuickMatchSelect1'>
                      <div className='teamScoutCheckboxAlign5'>
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.soloAvg}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, soloAvg: !this.state.chartFieldsDisplay.soloAvg}}, () => this.handleChartData())} />} label="Solo Average" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoLeavesStartingLine}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoLeavesStartingLine: !this.state.chartFieldsDisplay.autoLeavesStartingLine}}, () => this.handleChartData())} />} label="Auto Line" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoNotePickupArray}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoNotePickupArray: !this.state.chartFieldsDisplay.autoNotePickupArray}}, () => this.handleChartData())} />} label="Auto Pickup Count" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoScoredInAmp}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoScoredInAmp: !this.state.chartFieldsDisplay.autoScoredInAmp}}, () => this.handleChartData())} />} label="Auto Amp" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.autoScoredInSpeaker}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, autoScoredInSpeaker: !this.state.chartFieldsDisplay.autoScoredInSpeaker}}, () => this.handleChartData())} />} label="Auto Speaker" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameClimbed}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameClimbed: !this.state.chartFieldsDisplay.endgameClimbed}}, () => this.handleChartData())} />} label="Climbed" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameClimbTime}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameClimbTime: !this.state.chartFieldsDisplay.endgameClimbTime}}, () => this.handleChartData())} />} label="Climb Time" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameHarmony}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameHarmony: !this.state.chartFieldsDisplay.endgameHarmony}}, () => this.handleChartData())} />} label="Had Harmony" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameRobotParked}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameRobotParked: !this.state.chartFieldsDisplay.endgameRobotParked}}, () => this.handleChartData())} />} label="Robot Parked" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.endgameScoredTrap}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, endgameScoredTrap: !this.state.chartFieldsDisplay.endgameScoredTrap}}, () => this.handleChartData())} />} label="Scored Trap" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopAmpScored}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopAmpScored: !this.state.chartFieldsDisplay.teleopAmpScored}}, () => this.handleChartData())} />} label="Teleop Amp" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopSpeakerMade}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopSpeakerMade: !this.state.chartFieldsDisplay.teleopSpeakerMade}}, () => this.handleChartData())} />} label="Teleop Speaker" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.teleopSpeakerShotPercentage}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, teleopSpeakerShotPercentage: !this.state.chartFieldsDisplay.teleopSpeakerShotPercentage}}, () => this.handleChartData())} />} label="Speaker Shot %" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotMVP}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotMVP: !this.state.chartFieldsDisplay.robotMVP}}, () => this.handleChartData())} />} label="Robot MVP" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotPlayedDefense}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotPlayedDefense: !this.state.chartFieldsDisplay.robotPlayedDefense}}, () => this.handleChartData())} />} label="Robot Played Defense" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotPlayedDefenseRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotPlayedDefenseRating: !this.state.chartFieldsDisplay.robotPlayedDefenseRating}}, () => this.handleChartData())} />} label="Robot Played Defense Rating" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotUnderDefense}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotUnderDefense: !this.state.chartFieldsDisplay.robotUnderDefense}}, () => this.handleChartData())} />} label="Robot Under Defense" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotUnderDefenseRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotUnderDefenseRating: !this.state.chartFieldsDisplay.robotUnderDefenseRating}}, () => this.handleChartData())} />} label="Robot Under Defense Rating" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotHadIssues}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotHadIssues: !this.state.chartFieldsDisplay.robotHadIssues}}, () => this.handleChartData())} />} label="Robot Had Issues" />
                          <FormControlLabel control={<Checkbox checked={this.state.chartFieldsDisplay.robotHadIssuesRating}  onClick={() => this.setState({chartFieldsDisplay:{ ...this.state.chartFieldsDisplay, robotHadIssuesRating: !this.state.chartFieldsDisplay.robotHadIssuesRating}}, () => this.handleChartData())} />} label="Robot Had Issues Rating" />
                      </div>
                    </div>
                  </div>
                  <div className='teamScoutMatchChartDisplay'>
                  <LineChart
                    xAxis={[{ scaleType: 'point', data: this.state.matchDataChartXAxis }]}
                    series={this.state.matchDataChartDataSeries}
                    width={window.innerWidth < 400 ? 400 : window.innerWidth > 1000 ? 1000 : window.innerWidth}
                    height={300}
                  />
                  </div>
                </div>
                <div className='modalScreenButtons'>
                  <Button className='modalSignButton' variant="contained" size='small' color='signOut' onClick={this.handleOpenChartsModal}>Close</Button>
                </div>
              </div>
            </div>
          </Modal>
          <div className='MatchScoutingPageFormFields'>
            <div className='scoutingPageFields'>
              <div className='teamScoutButtonSpacing'>
              { !this.state.pitScouted &&
                <Button className='teamScoutPitScoutButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handlePitScout(event)}>Pit Scout</Button>
              }
              { this.state.pitScouted &&
                <Button className='teamScoutPitScoutButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handlePitData(event)}>Pit Data</Button>
              }
              </div>
              {!this.state.hasMatchData &&
                <div className='teamScoutMatchText'>
                  There is NO match Data to Display
                </div>
              } 
              {this.state.hasMatchData &&
                  <div className='teamScoutAtAGlanceBox'>
                    <div className='teamScoutAtAGlanceFullWidth'>
                      <div className='teamScoutAtAGlanceTitle'>
                        Match Data Summary
                      </div>
                      <div className='teamScoutAtAGlance'>
                        <div className='teamScoutAtAGlanceText'>
                          <div className='teamScoutAtAGlanceText1'>
                            Solo Avg
                          </div>
                          <div className='teamScoutAtAGlanceText2'>
                            {this.state.matchData.avgScoredSolo}
                          </div>
                        </div>
                        <div className='teamScoutAtAGlanceAlign'>
                            <div className='teamScoutAtAGlanceTitle1'>
                              Auto
                            </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Speaker 
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchData.avgSpeakerScoredAuto}
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Amp 
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchData.avgAmpScoredAuto}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='teamScoutAtAGlanceAlign'>
                          <div className='teamScoutAtAGlanceTitle1'>
                            Teleop
                          </div>
                          <div className='teamScoutAtAGlanceAlign2'>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Speaker
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchData.avgSpeakerScoredTelop}
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Amp
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchData.avgAmpScoredTelop}
                              </div>
                            </div>
                            <div className='teamScoutAtAGlanceText'>
                              <div className='teamScoutAtAGlanceText1'>
                                Climbed
                              </div>
                              <div className='teamScoutAtAGlanceText2'>
                                {this.state.matchData.climbPercentage}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              {this.state.hasMatchData && 
                <div className='teamScoutQuickCheckboxes'>
                  <div className='teamScoutAtAGlanceTitle1'>
                    Match Display
                  </div>
                  <div className='teamScoutQuickMatchSelect'>
                    <div className='teamScoutCheckboxAlign'>
                      <FormControlLabel control={<Checkbox checked={this.state.showAll}  onClick={() => this.setState({showAll: !this.state.showAll, showMostRecent: false, showBestMatch: false}, () => this.updateMatchData())} />} label="All" />
                      <FormControlLabel control={<Checkbox checked={this.state.showMostRecent}  onClick={() => this.setState({showMostRecent: !this.state.showMostRecent, showAll: false, showBestMatch: false}, () => this.updateMatchData())} />} label="Most Recent" />
                      <FormControlLabel control={<Checkbox checked={this.state.showBestMatch}  onClick={() => this.setState({showBestMatch: !this.state.showBestMatch, showAll: false, showMostRecent: false}, () => this.updateMatchData())} />} label="Best Match" />
                    </div>
                    <div className='teamScoutCheckboxAlign'>
                      <FormControlLabel control={<Checkbox checked={this.state.showMatches}  onClick={() => this.setState({showMatches: !this.state.showMatches}, () => this.checkMatchTypeSelected())} />} label="Qualification" />
                      <FormControlLabel control={<Checkbox checked={this.state.showPlayoff}  onClick={() => this.setState({showPlayoff: !this.state.showPlayoff}, () => this.checkMatchTypeSelected())} />} label="Playoff" />
                      <FormControlLabel control={<Checkbox checked={this.state.showPractice}  onClick={() => this.setState({showPractice: !this.state.showPractice}, () => this.checkMatchTypeSelected())} />} label="Practice" />
                    </div>
                  </div>
                </div>
              }
              {this.state.hasMatchData &&
                 <div className='teamScoutButtonAlignment'>
                 <Button className='teamScoutPitScoutButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handleMatchDataDeepDive(event)}>Match Data</Button>
                 <Button className='teamScoutPitScoutButton' variant="contained" size='small' color='secondary' onClick={(event) => this.handleOpenChartsModal(event)}>Charts</Button>
               </div>
              }
              <div className='teamScoutButtonSpacing'>
                <Button className='teamScoutPitScoutButton' variant="contained" size='small' color='signOut' onClick={(event) => this.setState({closedButtonPressed: true})}>Back</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}