import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './userRegister.css';
import '../../index.css';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getAPI } from "../../common";

export default class UserRegister extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      user: {
        email: '',
        password: '',
        pin: '',
        teamNumber: '',
        name: '',
        password2: ''
      },
      pinTaken: false,
      emailTaken: false,
      errorWithSignUp: false,
      PasswordInvalid: false,
      PasswordMisMatch: false,
      EmailMisMatch: false,
      PinToShort: false,
      cancelButton: false,
      submitted: false,
      disableButton: true
    }
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.disableButton = this.disableButton.bind(this);

    this.validEmail = new RegExp(
      '^.*@.*.\..*'
   );
   
    this.hasUpperCase = new RegExp('(?=.*[A-Z])');
    this.hasLowerCase = new RegExp('(?=.*[a-z])');
    this.hasNumbers = new RegExp('(?=.*[0-9])');
    this.hasNonalphas = new RegExp('(?=.*[!@#$%^&*])');
    };

  
handleSubmit() {
  this.setState({ submitted: true, emailTaken: false, errorWithSignUp: false, pinTaken: false });
    const { user } = this.state;

    axios.post(getAPI() + `/user/userSignUp` , {teamNumber : encodeURIComponent(user.teamNumber), pin : encodeURIComponent(user.pin), password : encodeURIComponent(user.password), email : encodeURIComponent(user.email),name : encodeURIComponent(user.name)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          this.setState({ errorWithSignUp: true });
        }
        else if(!data.data.success && data.data.error === 'Signup Issue' ) {
          this.setState({ errorWithSignUp: true });
        }
        else if(!data.data.success && data.data.error === 'Pin Taken' ) {
          this.setState({ pinTaken: true });
        } 
        else if(!data.data.success && data.data.error === 'Email Taken' ) {
          this.setState({ emailTaken: true });
        } 
        else {
          this.setState({cancelButton: true}); // Sign up went well, return to login
        }
      });
      this.setState({ submitted: false });
}


handleCancel() {
  this.setState({cancelButton: true});
}

disableButton() {

  if((this.state.PasswordInvalid || this.state.PasswordMisMatch) || (this.state.EmailMisMatch || this.state.PinToShort)) {
    this.setState({disableButton: true});
    return true;
  }

  if(((this.state.user.pin.length === 0 || this.state.user.email.length === 0) || (this.state.user.password.length === 0 || this.state.user.teamNumber.length === 0) || (this.state.user.pin.length === 0 || this.state.user.name.length === 0))) {
    this.setState({disableButton: true});
    return true;
  }





  this.setState({disableButton: false});
  return true;
}

handleTextFieldChange(event) {
  const { id, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
          ...user,
          [id]: value
      }}, () =>{
        if(id === "pin" && value.length <= 3) {
          this.setState({PinToShort: true}, () =>{
            this.disableButton();
          });
        } else this.setState({PinToShort: false}, () =>{
          this.disableButton();
        });

        if((id === "password")){
          let FailedLength = false;
          if (this.state.user.password.length >= 8){
            FailedLength = true;
          }
          let FailedUpperCase = false;
          if(this.hasUpperCase.test(this.state.user.password)){
            FailedUpperCase = true;
          }
          let FailedLowerCase = false;
          if(this.hasLowerCase.test(this.state.user.password)){
            FailedLowerCase = true;
          }
          let FailedNumbers = false;
          if(this.hasNumbers.test(this.state.user.password)){
            FailedNumbers = true;
          }
          let FailedNonalphas = false;
          if(this.hasNonalphas.test(this.state.user.password)){
            FailedNonalphas = true;
          }
          
          if (FailedLength == false){
            this.setState({PasswordInvalid: true}, () =>{
              this.disableButton();
            })
          }
          else if (FailedLowerCase + FailedUpperCase + FailedNumbers + FailedNonalphas < 3){
            this.setState({PasswordInvalid: true}, () =>{
              this.disableButton();
            })
          }
          else {
            this.setState({PasswordInvalid: false}, () =>{
              this.disableButton();
            })
          }
          
        }
        
          if((id === "password"||id === "password2")&& (this.state.user.password2 != "" && this.state.user.password != this.state.user.password2)){
             this.setState({ PasswordMisMatch: true }, () =>{
              this.disableButton();
            });
        }
      
        else{
          this.setState({ PasswordMisMatch: false });          
          
          if ((id === "email") && !this.validEmail.test(this.state.user.email)) {
            this.setState({ EmailMisMatch: true}, () =>{
              this.disableButton();
            });
         }
         else if((id === "email") && this.validEmail.test(this.state.user.email)){
          this.setState({ EmailMisMatch: false}, () =>{
            this.disableButton();
          });
          
         }
        }
  });
}

    
render(){
    return(
    <div className='fullScreen'>
         {this.state.cancelButton && (
          <Navigate to="/Login" replace={true} />
        )}
      <div className="globalBackground">
        <div className='userRegisterPage'>
        <div className='titleText'>
          User Registration
        </div>
        { this.state.pinTaken &&
          <div className='errorText'>
            Pin is already in use
          </div>
        }
        { this.state.emailTaken &&
          <div className='errorText'>
            Email is already in use
          </div>
        }
        { this.state.errorWithSignUp &&
          <div className='errorText'>
            Issue with signup, Please try again.
          </div>
        }
        { this.state.PasswordInvalid &&
          <div className='errorText'>
            Password must have 3 of 4 (uppercase, lowercase, special, numbers, length of 8+)
          </div>
        }
        { this.state.PasswordMisMatch &&
          <div className='errorText'>
            Passwords do not match
          </div>
        }
         { this.state.EmailMisMatch &&
          <div className='errorText'>
            Must be a valid email
          </div>
        }
        { this.state.PinToShort &&
          <div className='errorText'>
           Pin must be 4 or more characters
          </div>
        }
          <div className='globalForm'>
            <div className='formBorderBuffer'>
              <TextField
                required
                id="name"
                label="Name"
                variant="standard"
                color="secondary"
                value={this.state.user.name}
                onChange={this.handleTextFieldChange} 
              />
            <div className='spacer' />
            <TextField
              required
              id="teamNumber"
              label="Team Number"
              variant="standard"
              color="secondary"
              type='number'
              value={this.state.user.teamNumber}
              onChange={this.handleTextFieldChange} 
            />
            <div className='spacer' />
            <TextField
                  required
                  id="email"
                  label="Email"
                  variant="standard"
                  color="secondary"
                  value={this.state.user.email}
                  onChange={this.handleTextFieldChange} 
                />
              <div className='spacer' />
              <TextField
                  required
                  id="password"
                  label="Password"
                  variant="standard"
                  color="secondary"
                  type='password'
                  value={this.state.user.password}
                  onChange={this.handleTextFieldChange} 
                />
              <div className='spacer' />
              <TextField
                  required
                  id="password2"
                  label="Confirm Password"
                  variant="standard"
                  color="secondary"
                  type='password'
                  value={this.state.user.passwordConfirm}
                  onChange={this.handleTextFieldChange} 
                />
              <div className='spacer' />
              <TextField
                  required
                  id="pin"
                  label="Pin Number"
                  variant="standard"
                  color="secondary"
                  type='number'
                  value={this.state.user.pin}
                  onChange={this.handleTextFieldChange} 
                />
                <div className='bottomButtons'>
                  {this.state.disableButton && <Button variant="contained" size='small' color='secondary' onClick={this.handleSubmit} disabled>Submit</Button>}
                  {!this.state.disableButton && <Button variant="contained" size='small' color='secondary' onClick={this.handleSubmit}>Submit</Button>}
                  <div className='spacer' />
                  <Button variant="contained" size='small' color='secondary' onClick={this.handleCancel}>Cancel</Button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}