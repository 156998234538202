import * as React from 'react';
import './sideMenu.css';
import '../../index.css';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Navigate } from 'react-router-dom';

export default class SideMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      menuOpen: false,
      logout: false,
      admin: false,
      signIn: false,
      signAllInOut: false,
      isAdmin: false,
      globalTeamData: false,
      scoutingMain: false,
      fullDataView: false
    }

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleLogout = this.toggleLogout.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.toggleSignIn = this.toggleSignIn.bind(this);
    this.toggleSignAllInOut = this.toggleSignAllInOut.bind(this);
    this.toggleGlobalTeamData = this.toggleGlobalTeamData.bind(this);
    this.toggleScoutingMain = this.toggleScoutingMain.bind(this);
    this.toggleFullDataView = this.toggleFullDataView.bind(this);

  }

  toggleFullDataView() {
    this.setState({ fullDataView: true });
  }

  toggleScoutingMain() {
    this.setState({ scoutingMain: true });
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }
  
  toggleSignAllInOut() {
    this.setState({ signAllInOut: true });
  }

  toggleGlobalTeamData() {
    this.setState({ globalTeamData: true });
  }

  toggleLogout() { 
    sessionStorage.removeItem('LOGGED_IN');
    sessionStorage.removeItem('FIREBASE_UID');
    sessionStorage.removeItem('ROLES');
    sessionStorage.removeItem('NAME');
    sessionStorage.removeItem('TEAMNUMBER');

    this.setState({ logout: true });
  }

  toggleAdmin() { 
    this.setState({ admin: true });
  }

  toggleSignIn() { 
    this.setState({ signIn: true });
  }

  componentDidMount() {
    if(sessionStorage.getItem('ROLES').includes("admin")) {
      this.setState({ isAdmin: true });
    }
  }

  render(){
    return(
      <div className='SideMenuBar'>
        <div className='SideMenuBarButton'>
        <IconButton color="icon" aria-label="Open Menu" onClick={this.toggleMenu}>
          <MenuIcon />
        </IconButton>
        </div>
        <Menu
          id="basic-menu"
          open={this.state.menuOpen}
          onClose={this.toggleMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          >
          <MenuItem onClick={this.toggleSignIn}>Sign In</MenuItem>
          <MenuItem onClick={this.toggleGlobalTeamData}>Global Team Data</MenuItem>
          <MenuItem onClick={this.toggleScoutingMain}>Scouting</MenuItem>
          <MenuItem onClick={this.toggleFullDataView}>Data View</MenuItem>
          {this.state.isAdmin && <MenuItem onClick={this.toggleSignAllInOut}>Sign All - Admin</MenuItem>}
          {this.state.isAdmin && <MenuItem onClick={this.toggleAdmin}>Admin</MenuItem>}
          <MenuItem onClick={this.toggleLogout}>Logout</MenuItem>
        </Menu>
        {this.state.signIn ? <Navigate to="/SignIn" replace /> : null}
        {this.state.logout ? <Navigate to="/login" replace /> : null}
        {this.state.admin ? <Navigate to="/Admin" replace /> : null}
        {this.state.signAllInOut ? <Navigate to="/SignAllInOut" replace /> : null}
        {this.state.globalTeamData ? <Navigate to="/GlobalTeamData" replace /> : null}
        {this.state.scoutingMain ? <Navigate to="/ScoutingMain" replace /> : null}
        {this.state.fullDataView ? <Navigate to="/FullEventView" replace /> : null}
      </div>
    )
  }

}