import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './login.css';
import '../../index.css';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getAPI } from "../../common";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      viewSwitch: false,
      passwordReset: false,
      userRegister: false,
      allowLogin: false,
      submitted: false,
      loginFailedToSubmit: false,
      loginRejected: false,
      user: {
        pin: '',
        password: '',
        username: ''
      }
    }
    this.handleSwitchView = this.handleSwitchView.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginPin = this.handleLoginPin.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    };

handleSwitchView() {
  this.setState({viewSwitch: !this.state.viewSwitch});
}    

handleLogin() {

  this.setState({ submitted: true });
    const { user } = this.state;

    axios.post(getAPI() + `/user/userLoginPassword` , {email : encodeURIComponent(user.username), password: encodeURIComponent(user.password)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
          this.setState({ loginRejected: true });
        } else {
          this.setState({ submitted: false });
          sessionStorage.setItem('LOGGED_IN', true);
          sessionStorage.setItem('FIREBASE_UID', data.data.uid);
          sessionStorage.setItem('ROLES', data.data.roles);
          sessionStorage.setItem('NAME', data.data.name);
          sessionStorage.setItem('TEAMNUMBER', data.data.teamNumber);
          this.setState({allowLogin: true});
        }
      });
      this.setState({ submitted: false });
}

handleLoginPin() {
  this.setState({ submitted: true, loginFailedToSubmit: false, loginRejected: false });
    const { user } = this.state;

    axios.post(getAPI() + `/user/userLoginPin` , {pinNumber : encodeURIComponent(user.pin)})
      .then(res => {
        const data = res;
        if(data.status != 200) {
          this.setState({ loginFailedToSubmit: true });
        }
        else if(!data.data.success) {
          this.setState({ loginRejected: true });
        } else {
          this.setState({ submitted: false });
          sessionStorage.setItem('LOGGED_IN', true);
          sessionStorage.setItem('FIREBASE_UID', data.data.uid);
          sessionStorage.setItem('ROLES', data.data.roles);
          sessionStorage.setItem('NAME', data.data.name);
          sessionStorage.setItem('TEAMNUMBER', data.data.teamNumber);
          this.setState({allowLogin: true});
        }
      });
      this.setState({ submitted: false });
}

handleRegister() {
  this.setState({userRegister: true});
}

handleReset() {
  this.setState({passwordReset: true});
}

handleTextFieldChange(event) {
  const { id, value } = event.target;
    const { user } = this.state;
    this.setState({
        user: {
            ...user,
            [id]: value
        }
    });
}


render(){
    return(
    <div className='fullScreen'>
       {this.state.passwordReset && (
          <Navigate to="/PasswordReset" replace={true} />
        )}
        {this.state.userRegister && (
          <Navigate to="/UserRegister" replace={true} />
        )}
        {this.state.allowLogin && (
          <Navigate to="/SignIn" replace={true} />
        )}
      <div className="globalBackground">
        <div className='loginPage'>
        { this.state.loginFailedToSubmit &&
          <div className='errorText'>
            Login Failed To Submit, Please Try Again.
          </div>
        }
         { this.state.loginRejected &&
          <div className='errorText'>
            Login Rejected.
          </div>
        }
        <img className="teamLogoImage" src="resources/Obsidian_Loader.png" alt="Login Page Logo" />
        {!this.state.viewSwitch &&
          <div className='globalForm'>
          <Button className='switchViewButton' variant="contained" size='small' color='secondary' onClick={this.handleSwitchView}>Pin Login</Button>
            <div className='formBorderBuffer'>
              <TextField
                required
                id="username"
                label="Email"
                variant="standard"
                color="secondary"
                value={this.state.user.username}
                onChange={this.handleTextFieldChange} 
              />
              <div className='spacer' />
              <TextField
                required
                id="password"
                label="Password"
                variant="standard"
                color="secondary" 
                type='password'
                value={this.state.user.password}
                onChange={this.handleTextFieldChange} 
              />              
            </div>
            <Button className='loginButton' variant="contained" size='small' color='secondary' onClick={this.handleLogin}>Login</Button>
            <div className='secondaryButtons'>
              <Button className='loginSecondayButton' variant="contained" size='small' color='secondary' onClick={this.handleReset}>Reset</Button>
              <Button className='loginSecondayButton' variant="contained" size='small' color='secondary' onClick={this.handleRegister}>Register</Button>
            </div>

          </div>
          }
          {this.state.viewSwitch &&
            <div className='globalForm'>
              <Button className='switchViewButton' variant="contained" size='small' color='secondary' onClick={this.handleSwitchView}>User Login</Button>
              <div className='formBorderBuffer'>
                <TextField
                  required
                  id="pin"
                  label="Pin Number"
                  variant="standard"
                  color="secondary"
                  type="number"
                  value={this.state.user.pin}
                  onChange={this.handleTextFieldChange} 
                />    
              </div>
              <Button className='loginButton' variant="contained" size='small' color='secondary' onClick={this.handleLoginPin}>Login</Button>
              <div className='secondaryButtons'>
                <Button className='loginSecondayButton' variant="contained" size='small' color='secondary' onClick={this.handleReset}>Reset</Button>
                <Button className='loginSecondayButton' variant="contained" size='small' color='secondary' onClick={this.handleRegister}>Register</Button>
              </div>
            </div>
          }

        </div>
      </div>
    </div>
    )
}

}