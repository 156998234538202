import * as React from 'react';
import './matchScouting.css';
import '../../index.css';
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import { getDatabase, ref, onValue, update} from "firebase/database";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SideMenu from '../../components/sideMenu/sideMenu';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import {Navigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


dayjs.extend(utc);
const db = getDatabase();

export default class MatchScouting extends React.Component {

  constructor(props) {
    super(props);

    const queryParameters = new URLSearchParams(window.location.search)
    const teamNumber = queryParameters.get("teamNumber");
    const matchNumber = queryParameters.get("matchNumber");
    const isPractice = queryParameters.get("isPractice");
    const allianceColor = queryParameters.get("allianceColor") ? queryParameters.get("allianceColor") : 'Purple';
    const event = queryParameters.get("event") ? queryParameters.get("event") : sessionStorage.getItem('EVENTNAME');
    const isPlayoff = queryParameters.get("isPlayoff") ? queryParameters.get("isPlayoff") : false;
    const isForcedMatch = allianceColor == 'Purple' ? false : true;

    this.state={
      teamNumber: teamNumber,
      event: event,
      matchNumber: matchNumber,
      alliance: allianceColor,
      showAuto: false,
      showEndgame: false,
      showTelop: false,
      autoComplete: false,
      teleopComplete: false,
      endgameComplete: false,
      finalNotesComplete: false,
      autoLeavesStartingLine: false,
      autoNotePickupArray: [0,0,0,0,0,0,0,0],
      autoScoredInSpeaker: 0,
      autoScoredInAmp: 0,
      endgameClimbed: false,
      endgameHarmony: false,
      endgameScoredTrap: false,
      endgameRobotParked: false,
      endgameClimbTime: '',
      teleopAmpScored: 0,
      shotsMissed: [],
      shotsMade: [],
      pickupList: [],
      undoList: [],
      imageX: 0,
      imageY: 0,
      robotMVP: false,
      robotPlayedDefense: false,
      robotUnderDefense: false,
      robotDefenseRating: '',
      robotUnderDefenseRating: '',
      matchFinalNotes: '',
      matchScoutingReturn: false,
      isPractice: isPractice,
      isPlayoff: isPlayoff,
      isForcedMatch: isForcedMatch,
      robotHadIssues: false,
      robotHadIssuesRating: '',
        }

    this.handleAutoOpenModal = this.handleAutoOpenModal.bind(this);
    this.handleEndgameOpenModal = this.handleEndgameOpenModal.bind(this);
    this.handleTeleopOpenModal = this.handleTeleopOpenModal.bind(this);
    this.handleFinalNotesOpenModal = this.handleFinalNotesOpenModal.bind(this);
    this.handleModalAuto = this.handleModalAuto.bind(this);
    this.handleModalTeleop = this.handleModalTeleop.bind(this);
    this.handleModalEndgame = this.handleModalEndgame.bind(this);
    this.handleModalFinalNotes = this.handleModalFinalNotes.bind(this);
    this.autoNoteArray = this.autoNoteArray.bind(this);
    this.handleNoteAutoPress = this.handleNoteAutoPress.bind(this);
    this.handleIncrease = this.handleIncrease.bind(this);
    this.handleDecrease = this.handleDecrease.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onImgLoad = this.onImgLoad.bind(this);
    this.doClickAction = this.doClickAction.bind(this);
    this.doDoubleClickAction = this.doDoubleClickAction.bind(this);
    this.doTripleClickAction = this.doTripleClickAction.bind(this);
    this.undoButton = this.undoButton.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.resetClicks = this.resetClicks.bind(this);
    this.getShotMissedList = this.getShotMissedList.bind(this);
    this.getShotMadeList = this.getShotMadeList.bind(this);
    this.handleEventSelect = this.handleEventSelect.bind(this);
    this.getPickupList = this.getPickupList.bind(this);
  }

  resetClicks(event, delay = 675) {
    const timer = setTimeout(() => {
      // simple click
      if (this.state.clicks === 1) this.doClickAction(event);
      else if (this.state.clicks === 2) this.doDoubleClickAction(event);
      else if (this.state.clicks === 3) this.doTripleClickAction(event);
      this.setState({clicks: 0});          
    }, delay);
}
handleClick(e) {
  this.setState({clicks: this.state.clicks + 1}, () => {
    this.resetClicks(e);
  });
}

  undoButton(e) {
    let tempUndoArray = this.state.undoList;
   switch(this.state.undoList[this.state.undoList.length -1]) {
    case 1:
      let tempMissedArray = this.state.shotsMissed;
      tempMissedArray.pop();
      tempUndoArray.pop();
      this.setState({undoList: tempUndoArray, shotsMissed: tempMissedArray});
      break;
    case 2:
      let tempMadeArray = this.state.shotsMade;
      tempMadeArray.pop();
      tempUndoArray.pop();
      this.setState({undoList: tempUndoArray, shotsMade: tempMadeArray});
      break;
    case 3:
      let tempPickupArray = this.state.pickupList;
      tempPickupArray.pop();
      tempUndoArray.pop();
      this.setState({undoList: tempUndoArray, pickupList: tempPickupArray});
      break;
   }
  }

  getShotMadeList(shotMadeList) {
    return (
        <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
            {shotMadeList.map(shot => (
              <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/Made_Shot_Marker.png" alt="Shot Made Image" />
            ))}
        </div>
    )
  }
  getShotMissedList(shotMissedList) {
    return (
      <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
          {shotMissedList.map(shot => (
            <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/Missed_Shot_Marker.png" alt="Shot Missed Image" />
          ))}
      </div>
    )
  }
  getPickupList(pickupList) {
    return (
      <div className="MatchScoutingPageShotBox" style={{width: this.state.imageX + 'px', height: this.state.imageY + 'px'}}>
          {pickupList.map(shot => (
            <img className="MatchScoutingPageShotImage" style={{top: `calc(${shot.x}% - 5.5px)` , left: `calc(${shot.y}% - 5.5px)`}} src="../resources/PickupMarker.png" alt="Pickup Image" />
          ))}
      </div>
    )
  }
  onImgLoad({target:img}) {
    this.setState({imageY:img.offsetHeight,imageX:img.offsetWidth});
  }

  doClickAction(e) {
    this.setState({ undoList: [...this.state.undoList, 1], shotsMissed: [...this.state.shotsMissed, {y: Number(100 * (e.nativeEvent.offsetX/this.state.imageX)).toFixed(2), x: Number(100 * (e.nativeEvent.offsetY/this.state.imageY)).toFixed(2), timeLog: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}]});
  }
  doDoubleClickAction(e) {
    this.setState({ undoList: [...this.state.undoList, 2], shotsMade: [...this.state.shotsMade, {y: Number(100 * (e.nativeEvent.offsetX/this.state.imageX)).toFixed(2), x: Number(100 * (e.nativeEvent.offsetY/this.state.imageY)).toFixed(2), timeLog: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}]});
  }
  doTripleClickAction(e) {
    this.setState({ undoList: [...this.state.undoList, 3], pickupList: [...this.state.pickupList, {y: Number(100 * (e.nativeEvent.offsetX/this.state.imageX)).toFixed(2), x: Number(100 * (e.nativeEvent.offsetY/this.state.imageY)).toFixed(2), timeLog: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}]});
  }

  handleSubmit() {
    const matchData = {
      'autoLeavesStartingLine': this.state.autoLeavesStartingLine,
      'autoNotePickupArray': this.state.autoNotePickupArray,
      'autoScoredInSpeaker': this.state.autoScoredInSpeaker,
      'autoScoredInAmp': this.state.autoScoredInAmp,
      'endgameClimbed': this.state.endgameClimbed,
      'endgameHarmony': this.state.endgameHarmony,
      'endgameScoredTrap': this.state.endgameScoredTrap,
      'endgameRobotParked': this.state.endgameRobotParked,
      'endgameClimbTime': this.state.endgameClimbTime,
      'teleopAmpScored': this.state.teleopAmpScored,
      'shotsMissed': this.state.shotsMissed,
      'shotsMade': this.state.shotsMade,
      'robotMVP': this.state.robotMVP,
      'robotPlayedDefense': this.state.robotPlayedDefense,
      'robotUnderDefense': this.state.robotUnderDefense,
      'robotDefenseRating': this.state.robotDefenseRating,
      'robotUnderDefenseRating': this.state.robotUnderDefenseRating,
      'matchFinalNotes': this.state.matchFinalNotes,
      'pickupList': this.state.pickupList,
      'teamScouting': sessionStorage.getItem('TEAMNUMBER'),
      'personScouting': sessionStorage.getItem('NAME'),
      'robotHadIssues': this.state.robotHadIssues,
      'robotHadIssuesRating': this.state.robotHadIssuesRating,
    }
    axios.post(getAPI() + '/scouting/scoutingUpdateMatchScoutingData', { isPlayoff: encodeURIComponent(this.state.isPlayoff) ,isPractice: encodeURIComponent(this.state.isPractice), teamNumber: encodeURIComponent(this.state.teamNumber), event: encodeURIComponent(this.state.event), matchNumber: encodeURIComponent(this.state.matchNumber), matchData: encodeURIComponent(JSON.stringify(matchData)) })
    .then((response) => {
      this.setState({matchScoutingReturn: true});
    });

    console.log(this.state);
  }

  handleIncrease(id) {
    this.setState({[id]: this.state[id] + 1})
  }

  handleDecrease(id) {
    if(this.state[id] > 0) this.setState({[id]: this.state[id] - 1})

  }

  handleAutoOpenModal() {
    this.setState({showAuto: true, autoComplete: true})
  }

  handleFinalNotesOpenModal() {
    this.setState({showFinalNotes: true, finalNotesComplete: true})
  }

  handleModalFinalNotes() {
    this.setState({showFinalNotes: false})
  }

  handleEndgameOpenModal() {
    this.setState({showEndgame: true, endgameComplete: true})
  }
  handleTeleopOpenModal() {
    this.setState({showTelop: true, teleopComplete: true})
  }
  handleModalAuto() {
    this.setState({showAuto: false})
  }
  handleModalTeleop() {
    this.setState({showTelop: false})
  }
  handleModalEndgame() {
    this.setState({showEndgame: false})
  }

  handleNoteAutoPress(noteNumber) {
    var tempArray = this.state.autoNotePickupArray;
    tempArray[noteNumber] = tempArray[noteNumber] == 0 ? 1 : 0;
    this.setState({autoNotePickupArray: tempArray})
  }

componentDidMount() {
  if(this.state.isForcedMatch) {
    let teamIndex = '';
    let redAlliance, blueAlliance = [];

    onValue(ref(db, `scouting/2024/events/${sessionStorage.getItem('EVENTNAME')}/eventMatches/${this.state.matchNumber}`), (snapshot) => {
      if(snapshot.val() != null) {
        redAlliance = snapshot.val().red;
        blueAlliance = snapshot.val().blue;
      }
      
      if(this.state.alliance == 'red') {
        redAlliance.forEach((team, index) => {
          if(team.teamNumber == this.state.teamNumber) {
            teamIndex = index;
          }
        })
      }
      else {
        blueAlliance.forEach((team, index) => {
          if(team.teamNumber == this.state.teamNumber) {
            teamIndex = index;
          }
        })
      }
  
      console.log(teamIndex);
  
      update(ref(db, `scouting/2024/events/${this.state.event}/eventMatches/${this.state.matchNumber}/${this.state.alliance}/${teamIndex}`), {
        taken: true
      })
    });
  }
}

autoNoteArray() {

return(
  <div className='matchScoutGridList'>
    <div className='matchScoutGridText'>
      Additional Notes Picked Up
    </div>
    <div className='matchScoutGridRow'>
      <div className='matchScoutGridItemHide'>
        Source
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(0)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[0] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(1)}  className='matchScoutGridItem' style={(this.state.autoNotePickupArray[1] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(2)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[2] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div className='matchScoutGridItemHide'>
        Amp
      </div>
    </div>
    <div className='matchScoutGridRow'>
      <div onClick={(event) => this.handleNoteAutoPress(3)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[3] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(4)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[4] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(5)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[5] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(6)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[6] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
      <div onClick={(event) => this.handleNoteAutoPress(7)} className='matchScoutGridItem' style={(this.state.autoNotePickupArray[7] == 0 ? {'background-color': '#ff0000'} : {'background-color': '#00ff00'}) }>
        <img className="matchScoutNoteImg" src="../resources/note.png" alt="Note" />
      </div>
    </div>
  </div>
)
}

handleEventSelect(event) {
  event.preventDefault();
  const { name, value } = event.target;
  this.setState({[name]: value});
  
}


render(){
    return(
    <div className='fullScreen'>
      <div className="globalBackground">
        <div className='matchScoutingPage'>
          <SideMenu/>
          { this.state.matchScoutingReturn ? <Navigate to={`/ScoutingMain`} /> : null}
          <Modal className='modalScreen' open={(this.state.showAuto)} onClose={this.handleModalAuto}>
            <div className='matchScoutModalScreenAlign'>
              <FormControlLabel control={<Checkbox checked={this.state.autoLeavesStartingLine}  onClick={() => this.setState({autoLeavesStartingLine: !this.state.autoLeavesStartingLine})} />} label="Robot Moves Out Of Starting Zone" />
              {this.autoNoteArray()}
              
              <div className='matchScoutAutoScoreList'>
                <div className='matchScoutGridText'>
                  Scored During Auto
                </div>
                <div className='matchScoutAutoScoreItems'>
                  <div className='matchScoutAutoScoreItem'>
                    Speaker
                    <div className='matchScoutingScoreAlign'>
                      <IconButton onClick={() => this.handleDecrease('autoScoredInSpeaker')} aria-label="delete" size="large">
                        <RemoveCircleIcon />
                      </IconButton>
                      {this.state.autoScoredInSpeaker}
                      <IconButton onClick={() => this.handleIncrease('autoScoredInSpeaker')} aria-label="delete" size="large">
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </div>
                  <div className='matchScoutAutoScoreItem'>
                    Amp
                    <div className='matchScoutingScoreAlign'>
                      <IconButton onClick={() => this.handleDecrease('autoScoredInAmp')} aria-label="delete" size="large">
                        <RemoveCircleIcon />
                      </IconButton>
                      {this.state.autoScoredInAmp}
                      <IconButton onClick={() => this.handleIncrease('autoScoredInAmp')} aria-label="delete" size="large">
                        <AddCircleIcon />
                      </IconButton>
                    </div>
                  </div>
                  </div>
                </div>
                <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleModalAuto}>Close</Button>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.showTelop)} onClose={this.handleModalTeleop}>
            <div className='matchScoutModalScreenAlign'>
              <div className='matchScoutingTeleopAlign'>
                <div className='matchScoutingShootingBoxes' onClick={this.handleClick}>
                  <img onLoad={this.onImgLoad} className="matchScoutFieldImg" src="../resources/2024_field.png" alt="Field Map"/>
                  {this.getShotMadeList(this.state.shotsMade)}
                  {this.getShotMissedList(this.state.shotsMissed)}
                  {this.getPickupList(this.state.pickupList)}
                </div>
                <div className='matchScoutingTeleopBottomRow'>
                <Button className='matchScoutingButtonTeleop' variant="contained" size='large' color={'signOut'} onClick={this.undoButton}>Undo</Button>
                  <div className='matchScoutTeleopScoreItem'>
                      Amp
                      <div className='matchScoutingScoreAlign'>
                        <IconButton onClick={() => this.handleDecrease('teleopAmpScored')} aria-label="delete" size="large">
                          <RemoveCircleIcon />
                        </IconButton>
                        {this.state.teleopAmpScored}
                        <IconButton onClick={() => this.handleIncrease('teleopAmpScored')} aria-label="delete" size="large">
                          <AddCircleIcon />
                        </IconButton>
                      </div>
                    </div>
                    <Button className='matchScoutingButtonTeleop' variant="contained" size='large' color={'signOut'} onClick={this.handleModalTeleop}>Close</Button>
                  </div>
                </div>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.showEndgame)} onClose={this.handleModalEndgame}>
            <div className='matchScoutModalScreenAlign'>
            <div className='matchScoutingEndgameAlign'>
                <FormControlLabel control={<Checkbox checked={this.state.endgameClimbed}  onClick={() => this.setState({endgameClimbed: !this.state.endgameClimbed, endgameRobotParked: false })} />} label="Robot Climbed" />
                <FormControlLabel control={<Checkbox checked={this.state.endgameHarmony}  onClick={() => this.setState({endgameHarmony: !this.state.endgameHarmony, endgameRobotParked: false})} />} label="Had Harmony" />
                  <div className='scoutingPageField'>
                    <TextField
                    fullWidth
                    value={this.state.endgameClimbTime}
                    label="Time Taken To Climb In Seconds"
                    onChange={(e) => {
                        this.setState({endgameClimbTime: e.target.value});
                    }}
                />
                </div>
                <FormControlLabel control={<Checkbox checked={this.state.endgameRobotParked}  onClick={() => this.setState({endgameRobotParked: !this.state.endgameRobotParked , endgameClimbed: false, endgameHarmony: false})} />} label="Robot Parked" />
                <FormControlLabel control={<Checkbox checked={this.state.endgameScoredTrap}  onClick={() => this.setState({endgameScoredTrap: !this.state.endgameScoredTrap})} />} label="Robot Scored Trap" />
              </div>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleModalEndgame}>Close</Button>
            </div>
          </Modal>
          <Modal className='modalScreen' open={(this.state.showFinalNotes)} onClose={this.handleModalFinalNotes}>
            <div className='matchScoutModalScreenAlign'>
            <div className='matchScoutingFinalNotesAlign'>
                <FormControlLabel control={<Checkbox checked={this.state.robotMVP}  onClick={() => this.setState({robotMVP: !this.state.robotMVP})} />} label="Robot Was Alliance MVP" />
                <FormControlLabel control={<Checkbox checked={this.state.robotPlayedDefense}  onClick={() => this.setState({robotPlayedDefense: !this.state.robotPlayedDefense})} />} label="Robot Played Defense" />
              {this.state.robotPlayedDefense &&
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Robot Defense Rating</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.robotDefenseRating}
                    name="robotDefenseRating"
                    label="Robot Defense Rating"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0 - Bad</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5 </MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10 - Good</MenuItem>

                  </Select>
                </FormControl>
                }
              <FormControlLabel control={<Checkbox checked={this.state.robotUnderDefense}  onClick={() => this.setState({robotUnderDefense: !this.state.robotUnderDefense})} />} label="Robot Was Under Defense" />
              {this.state.robotUnderDefense &&
              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Robot Performance Under Defense</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.robotUnderDefenseRating}
                    name="robotUnderDefenseRating"
                    label="Robot Under Defense Rating"
                    onChange={(event) => this.handleEventSelect(event)}
                  >
                    <MenuItem value={0}>0 - Bad</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10 - Good</MenuItem>

                  </Select>
                </FormControl>
                }
                <FormControlLabel control={<Checkbox checked={this.state.robotHadIssues}  onClick={() => this.setState({robotHadIssues: !this.state.robotHadIssues})} />} label="Robot Was Broken/Had Issues" />
                {this.state.robotHadIssues &&
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">How Bad Was The Issue/Break</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.robotHadIssuesRating}
                      name="robotHadIssuesRating"
                      label="How Bad Was The Issue/Break"
                      onChange={(event) => this.handleEventSelect(event)}
                    >
                      <MenuItem value={0}>0 - Useless In Match</MenuItem>
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10 - Wasn't Affected</MenuItem>

                    </Select>
                  </FormControl>
                  }
                <div className='scoutingPageField'>
                  <TextField
                  fullWidth
                  value={this.state.matchFinalNotes}
                  label="Notes"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                      this.setState({matchFinalNotes: e.target.value});
                  }}
              />
              </div>
              </div>
              <Button className='matchScoutingButton' variant="contained" size='large' color={'signOut'} onClick={this.handleModalFinalNotes}>Close</Button>
            </div>
          </Modal>
          <div className='pitScoutingAlignHeader'>
            <div className='pitScoutingTeamNumberIncrease' style={(this.state.alliance == 'red' ? {color: 'red'} : this.state.alliance == 'blue' ? {color: 'blue'} : {color: 'purple'})}>
              {this.state.teamNumber}
            </div>
            <div className='pitScoutingTeamNumber'>
              Match {this.state.matchNumber}
            </div>
          </div>
          <div className='matchScoutButtonList'>
            <Button style={this.state.autoComplete ? {color: 'black'} : {color: 'white'} }className='matchScoutingButton' variant="contained" size='large' color={this.state.autoComplete ? 'signIn' : 'signOut'} onClick={this.handleAutoOpenModal}>Auto</Button>
            <Button style={this.state.teleopComplete ? {color: 'black'} : {color: 'white'} } className='matchScoutingButton' variant="contained" size='large' color={this.state.teleopComplete ? 'signIn' : 'signOut'} onClick={this.handleTeleopOpenModal}>Teleop</Button>
            <Button style={this.state.endgameComplete ? {color: 'black'} : {color: 'white'} } className='matchScoutingButton' variant="contained" size='large' color={this.state.endgameComplete ? 'signIn' : 'signOut'} onClick={this.handleEndgameOpenModal}>Endgame</Button>
            <Button style={this.state.finalNotesComplete ? {color: 'black'} : {color: 'white'} } className='matchScoutingButton' variant="contained" size='large' color={this.state.finalNotesComplete ? 'signIn' : 'signOut'} onClick={this.handleFinalNotesOpenModal}>Final Notes</Button>
            <Button style={((this.state.autoComplete && this.state.finalNotesComplete) && (this.state.endgameComplete && this.state.teleopComplete)) ? {color: 'black'} : {color: 'white'} } className='matchScoutingButton' variant="contained" size='large' color={((this.state.autoComplete && this.state.finalNotesComplete) && (this.state.endgameComplete && this.state.teleopComplete)) ? 'signIn' : 'signOut'} disabled={!((this.state.autoComplete && this.state.finalNotesComplete) && (this.state.endgameComplete && this.state.teleopComplete))} onClick={this.handleSubmit}>Submit</Button>
          </div>
        </div>
      </div>
    </div>
    )
  }
}