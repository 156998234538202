import * as React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import './globalTeamData.css';
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getAPI } from "../../common";
import {firebase} from "../../firebase";
import {getDatabase, ref, onValue} from "firebase/database";
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import SideMenu from '../../components/sideMenu/sideMenu';

const db = getDatabase();

export default class GlobalTeamData extends React.Component {
 
  constructor(props) {
    super(props);
    this.state={
        users:[],
        viewingRoboticsHours: true,
        typeOfHoursShown: "Robotics",
        teamNumber: sessionStorage.getItem('TEAMNUMBER'),
        pos1:"1",
        pos2:"2",
        pos3:"3",
        totalTime:""
    }

    this.swapTimeView = this.swapTimeView.bind(this);
    this.userList = this.userList.bind(this);
    this.updateTeamList = this.updateTeamList.bind(this);
    this.totalTeamTime = this.totalTeamTime.bind(this);
  };

  componentDidMount(){
    this.updateTeamList();
  }

  updateTeamList(){
    const firebaseUserLastSign = ref(db, "/users/" + sessionStorage.getItem('FIREBASE_UID')); // Get Recent Sign Out
    onValue(firebaseUserLastSign, (snapshot) => {
    let userArray=[]
    axios.post(getAPI() + `/user/usersTeamTime`, {type: encodeURIComponent(this.state.typeOfHoursShown),teamNumber: encodeURIComponent(this.state.teamNumber)})
      .then(res => {
        const data = res;
          userArray=data.data.returnArray
          this.setState({users:userArray,pos1:userArray[0].name,pos2:userArray[1].name,pos3:userArray[2].name},() => this.totalTeamTime())
      })
    })
  }

  swapTimeView(){
    if(this.state.viewingRoboticsHours){
      this.setState({typeOfHoursShown:"Volunteer",viewingRoboticsHours:false},() => this.updateTeamList());
    }else{
      this.setState({typeOfHoursShown:"Robotics",viewingRoboticsHours:true},() => this.updateTeamList());
  }
}

  userList() {
    const userList = this.state.users;
    return (
      <div className='userBox'>
        <div className='userBox'>
          {userList.map(user => ( 
            <div className='buffer'>
              <div className='userDisplay'>
                <div className='userName'>
                {user.name}
                </div>
                <div className='userTime'>
                {user.time}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
    )
  }

  totalTeamTime(){
    let timeDifference=0;

    for(let i=0;i<this.state.users.length;i++){
      timeDifference+=this.state.users[i].timeInSeconds;
    }
    
    const seconds =((timeDifference % 60 == NaN ? '0' : timeDifference % 60) < 10 ? '0' + (timeDifference % 60 == NaN ? '00' : timeDifference % 60) :  (timeDifference % 60 == NaN ? '0' : timeDifference % 60));
    const minutes = ((Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) < 10 ? '0' + (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))) : (Math.floor(Number((timeDifference % 3600) / 60)) == NaN ? '0' : Math.floor(Number((timeDifference %3600) / 60))));
    const hours = ((Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) < 10 ? '0' + (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))) : (Math.floor(Number(timeDifference / 3600)) == NaN ? '0' : Math.floor(Number(timeDifference / 3600))));
  
    this.setState({totalTime:`${hours}:${minutes}:${seconds}`})
  }

  topThreeUsers(){
    return(
      <div className="positionBoxes">
        <div className='user1stBuffer'>
          1st
        <div className='userDisplay1st'>
          <div className='userName'>
            {this.state.pos1}
          </div>
        </div>
        </div>
        <div className='rowAlign'>
        <div className='user2ndBuffer'>
          2nd
        <div className='userDisplay2nd'>
          <div className='userName'>
            {this.state.pos2}
          </div>
        </div>
        </div>
        <div className='user3rdBuffer'>
          3rd
        <div className='userDisplay3rd'>
          <div className='userName'>
            {this.state.pos3}
          </div>
        </div>
        </div>
        </div>
      </div>
    )
  }

render(){
    return(
    <div className='fullScreen'>
        <div className='globalBackground'>
        <SideMenu/>
          <div className='switchBuffer'>
            <div className='switchForm'>
              {this.state.typeOfHoursShown}
              <Switch color='secondary' onChange={this.swapTimeView}/>
              {this.state.typeOfHoursShown}
            </div>
          </div>
          <div className='globalTeamDataPage'>
            {this.topThreeUsers()}
            <div className='userForm'>
            <div className='currentHoursShown'>
            {this.state.typeOfHoursShown}
            </div>
            <div className='buffer'></div>
            <div className='totalTeamHours'>
            {"Total Team Hours "+this.state.totalTime}
            </div>
              <div className='userTimeList'>
                {this.userList()}
              </div>
            </div>
          </div>
        </div>
      </div>   
    )
  }
}